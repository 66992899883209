// Core
import React, { FC, ReactElement } from "react";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import {
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  Typography
} from "@material-ui/core";
import { Clear, PictureAsPdf, Visibility } from "@material-ui/icons";

interface Props {
  chipLabel?: string;
  clickDelete: () => void;
  file: File;
}

const LocalFile: FC<Props> = (props): ReactElement => {
  const sharedClasses = sharedStyles();

  return (
    <Box clone position="relative" height={1}>
      <Card elevation={0} data-testid="local-file">
        {props.chipLabel && (
          <Box position="absolute" top={10} right={10}>
            <Chip
              color="primary"
              label={props.chipLabel}
              data-testid="local-file-chip"
            />
          </Box>
        )}
        <CardContent>
          <Box alignItems="center" display="flex">
            <Box clone marginRight={1}>
              <PictureAsPdf color="secondary" fontSize="large" />
            </Box>
            <Typography
              component="p"
              display="inline"
              variant="subtitle1"
              data-testid="local-file-name"
            >
              {props.file.name}
            </Typography>
            <Box display="flex" marginLeft="auto">
              <a
                href={URL.createObjectURL(props.file)}
                rel="noreferrer"
                target="_blank"
                download
              >
                <IconButton data-testid="view-button">
                  <Visibility className={sharedClasses.fadedText} />
                </IconButton>
              </a>
              <IconButton
                onClick={props.clickDelete}
                data-testid="delete-button"
              >
                <Clear className={sharedClasses.fadedText} />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LocalFile;
