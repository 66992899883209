// Interfaces
import { Project } from "interfaces";

export const getAssignedItemsCount = (project: Project): number => {
  if (!Object.keys(project).length) {
    return 0;
  }

  return project.subjectExperts.reduce(
    (previousValue, currentValue) => previousValue + currentValue.assignedItems,
    0
  );
};
