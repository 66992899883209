const pdfTypeRegEx = new RegExp(/[_\\-\\s]ms[_\\-\\s]|[_\\-\\s]qp[_\\-\\s]/i);

const validCIFilenameRegEx = new RegExp(
  `^\\d{4}_[msw]\\d{2}_(ms|qp)_\\d{2}.pdf$`
);

const validOCRFilenameRegEx = new RegExp(
  `^(H|J|R|Y|\\d)\\d{3}[_\\-\\s]\\d{2}[_\\-\\s](MS|QP)[_\\-\\s][A-Za-z]+\\d{2}.pdf$`
);

export { pdfTypeRegEx, validCIFilenameRegEx, validOCRFilenameRegEx };
