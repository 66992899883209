// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import CompletedProject from "pages/projects/projects/components/completedProject/CompletedProject";
import DeleteProjectDialogue from "pages/projects/projects/components/deleteProjectDialogue/DeleteProjectDialogue";
import InProgressProject from "pages/projects/projects/components/inProgressProject/InProgressProject";
import NotStartedProject from "pages/projects/projects/components/notStartedProject/NotStartedProject";

// Enums
import { ProjectStatus } from "enums";

// Interfaces
import { Project } from "interfaces";

// Utils
import { useApi, useProject } from "utils/context";

// Vendor
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";

interface LocationState {
  activeStatusFilter?: Project["status"];
}

const Projects: FC<
  RouteComponentProps<undefined, StaticContext, LocationState>
> = (props): ReactElement => {
  const { deleteProject } = useApi();
  const {
    deleteProjectSuccess,
    fetchProjectSuccess,
    project,
    projects
  } = useProject();
  const [activeStatusFilter, setActiveStatusFilter] = useState(
    "notStarted" as Project["status"]
  );
  const [filteredProjects, setFilteredProjects] = useState([] as Project[]);
  const [
    isDeleteProjectDialogueOpen,
    setIsDeleteProjectDialogueOpen
  ] = useState(false);
  const [projectIdToBeDeleted, setProjectIdToBeDeleted] = useState("");
  const statuses = ["notStarted", "inProgress", "completed"];

  useEffect(() => {
    if (!props.location.state || !props.location.state.activeStatusFilter) {
      return;
    }

    setActiveStatusFilter(props.location.state.activeStatusFilter);
  }, [props]);

  useEffect(() => {
    if (!Object.keys(project).length) {
      return;
    }

    fetchProjectSuccess({} as Project);
  }, [fetchProjectSuccess, project]);

  useEffect(() => {
    setFilteredProjects(
      projects.filter((item) => item.status === activeStatusFilter)
    );
  }, [activeStatusFilter, projects]);

  const clickConfirmDelete = (): void => {
    deleteProject(projectIdToBeDeleted)
      .then(() => deleteProjectSuccess(projectIdToBeDeleted))
      .finally(() => {
        setProjectIdToBeDeleted("");
      });
  };

  const clickDelete = (projectId: string): void => {
    setIsDeleteProjectDialogueOpen(true);
    setProjectIdToBeDeleted(projectId);
  };

  const clickStatusFilter = (status: Project["status"]): void => {
    if (activeStatusFilter === status) {
      return;
    }

    setActiveStatusFilter(status);
  };

  const renderStatusFilters = (): ReactElement => {
    return (
      <Box marginBottom={8}>
        <Tabs
          indicatorColor="primary"
          style={{ borderBottom: "1px solid #e2e2e2" }}
          textColor="primary"
          value={activeStatusFilter}
          data-testid="status-filters"
        >
          {statuses.map((status) => (
            <Tab
              key={status}
              label={
                <Box display="flex">
                  <Box marginRight={2}>
                    {ProjectStatus[status as Project["status"]]}
                  </Box>
                  <Box>
                    ({projects.filter((item) => item.status === status).length})
                  </Box>
                </Box>
              }
              onClick={() => clickStatusFilter(status as Project["status"])}
              value={status}
              data-testid={`${status}-status-filter-tab`}
            />
          ))}
        </Tabs>
      </Box>
    );
  };

  return (
    <div data-testid="projects-page">
      <DeleteProjectDialogue
        clickConfirmDelete={clickConfirmDelete}
        isOpen={isDeleteProjectDialogueOpen}
        setIsDeleteProjectDialogueOpen={setIsDeleteProjectDialogueOpen}
        setProjectIdToBeDeleted={setProjectIdToBeDeleted}
      />
      <Box marginBottom={4}>
        <Typography variant="h3" data-testid="page-heading">
          Your projects
        </Typography>
      </Box>
      {renderStatusFilters()}
      <div data-testid={`${activeStatusFilter}-projects`}>
        {filteredProjects.length === 0 && (
          <Typography>
            You don&rsquo;t have any projects in this state.
          </Typography>
        )}
        {filteredProjects.map((item, index) => (
          <Box
            key={item.id}
            marginBottom={index + 1 === filteredProjects.length ? 0 : 6}
          >
            {activeStatusFilter === "notStarted" && (
              <NotStartedProject clickDelete={clickDelete} project={item} />
            )}
            {activeStatusFilter === "inProgress" && (
              <InProgressProject project={item} />
            )}
            {activeStatusFilter === "completed" && (
              <CompletedProject project={item} />
            )}
          </Box>
        ))}
      </div>
    </div>
  );
};

export default Projects;
