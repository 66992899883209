// Core
import React, { FC, ReactElement } from "react";

// Components
import Editor from "components/editor/Editor";

// Interfaces
import { ChoiceInteractionResponses } from "interfaces";

// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  withStyles
} from "@material-ui/core";
import MathJax from "react-mathjax-preview";

const CustomTableCell = withStyles(() => ({
  body: {
    borderBottom: "0 none",
    "&:not(:last-child)": {
      borderRight: "1px solid rgba(224, 224, 224, 1)"
    },
    textAlign: "center",
    "&[rowspan]": {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      position: "relative",
      "&::after": {
        backgroundColor: "rgba(224, 224, 224, 1)",
        content: '""',
        height: "1px",
        position: "absolute",
        bottom: "-1px",
        left: "100%",
        width: "1000px"
      }
    }
  }
}))(TableCell);

const CustomTableRow = withStyles(() => ({
  root: {
    backgroundColor: colours.backgroundGrey,
    border: "0 none"
  }
}))(TableRow);

interface Props {
  content: ChoiceInteractionResponses["table"];
  isEditing: boolean;
  onChangeTd: (value: string, index: number, rowIndex: number) => void;
  onChangeTh: (value: string, index: number) => void;
}

const Table: FC<Props> = (props): ReactElement => {
  return (
    <>
      {!props.content && <></>}
      {props.content && (
        <Box marginBottom={3} data-testid="table">
          <TableContainer component={Paper}>
            <MuiTable style={{ overflow: "hidden" }}>
              <TableBody>
                {props.content.thead && (
                  <CustomTableRow>
                    {props.content.thead.map((th, thIndex) => (
                      <CustomTableCell
                        colSpan={
                          th.colspan ? parseInt(th.colspan, 10) : undefined
                        }
                        key={thIndex}
                        rowSpan={
                          th.rowspan ? parseInt(th.rowspan, 10) : undefined
                        }
                        style={{
                          borderBottom: "1px solid rgba(224, 224, 224, 1)"
                        }}
                      >
                        {props.isEditing && (
                          <Editor
                            onEditorChange={(value: string) =>
                              props.onChangeTh(value, thIndex)
                            }
                            testId={`th-${thIndex}-editor`}
                            value={th.text || ""}
                          />
                        )}
                        {!props.isEditing && (
                          <MathJax
                            math={th.text}
                            sanitizeOptions={{
                              ADD_TAGS: ["b", "i", "sub", "sup", "u"]
                            }}
                            wrapperTag="span"
                          />
                        )}
                      </CustomTableCell>
                    ))}
                  </CustomTableRow>
                )}
                {props.content.tbody.map((tbodyRow, tbodyRowIndex) => (
                  <CustomTableRow key={tbodyRowIndex}>
                    {tbodyRow.map((td, tdIndex) => (
                      <CustomTableCell key={tdIndex}>
                        {props.isEditing && (
                          <Editor
                            onEditorChange={(value: string) =>
                              props.onChangeTd(value, tdIndex, tbodyRowIndex)
                            }
                            testId={`td-${tbodyRowIndex}-${tdIndex}-editor`}
                            value={td || ""}
                          />
                        )}
                        {!props.isEditing && (
                          <MathJax
                            math={td}
                            sanitizeOptions={{
                              ADD_TAGS: ["b", "i", "sub", "sup", "u"]
                            }}
                            wrapperTag="span"
                          />
                        )}
                      </CustomTableCell>
                    ))}
                  </CustomTableRow>
                ))}
              </TableBody>
            </MuiTable>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default Table;
