// Core
import React, { FC, ReactElement } from "react";

// Component
import Editor from "components/editor/Editor";

// Vendor
import { Box, Typography } from "@material-ui/core";
import MathJax from "react-mathjax-preview";

interface Props {
  content: string;
  isApproving: boolean;
  isEditing: boolean;
  onChange: (value: string) => void;
}

const Prompt: FC<Props> = (props): ReactElement => {
  return (
    <Box marginBottom={props.isApproving ? 0 : 3} data-testid="prompt">
      {props.isEditing && (
        <Editor
          onEditorChange={props.onChange}
          testId="prompt-editor"
          value={props.content}
        />
      )}
      {!props.isEditing && (
        <Typography data-testid="prompt-content">
          <MathJax
            math={props.content}
            sanitizeOptions={{
              ADD_TAGS: ["b", "i", "sub", "sup", "u"]
            }}
            wrapperTag="span"
          />
        </Typography>
      )}
    </Box>
  );
};

export default Prompt;
