// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Box, Dialog, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";

interface Props {
  isOpen: boolean;
  rejectionExplanation?: string;
  rejectionReasons: string[];
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RejectionReasonsDialogue: FC<Props> = (props): ReactElement => {
  const clickClose = (): void => {
    props.setIsOpen(false);
  };

  return (
    <Dialog
      onClose={clickClose}
      aria-labelledby="rejection-reasons-dialogue-heading"
      open={props.isOpen}
      data-testid="rejection-reasons-dialogue"
    >
      <Box padding={4}>
        <Box alignItems="center" display="flex" marginBottom={3}>
          <Typography
            component="h2"
            variant="h4"
            data-testid="rejection-reasons-dialogue-heading"
          >
            Rejection reasons
          </Typography>
          <Box clone marginLeft="auto">
            <IconButton
              aria-label="close"
              onClick={clickClose}
              data-testid="close-button"
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Box marginBottom={3}>
          <Typography>
            A subject expert has rejected this item because of the following
            reasons:
          </Typography>
        </Box>
        {props.rejectionReasons.map((reason) => (
          <Box key={reason} marginBottom={1}>
            <Typography
              key={reason}
              style={{ fontWeight: 700 }}
              data-testid="rejection-reason"
            >
              {reason}
            </Typography>
          </Box>
        ))}
        {props.rejectionExplanation && (
          <>
            <Typography display="inline" style={{ fontWeight: 700 }}>
              Comments:{" "}
            </Typography>
            <Typography display="inline" data-testid="rejection-explanation">
              {props.rejectionExplanation}
            </Typography>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default RejectionReasonsDialogue;
