// Core
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useReducer
} from "react";

// Utils
import http, { buildQuery, ResponseWithData } from "utils/fetchWrapper";

type Action = { type: string; payload: unknown };
type ApiContextProps = { children: ReactNode };
type Dispatch = (action: Action) => void;
type State = {
  apiUrl: string;
  error: { message: string; timestamp: string };
  token: string;
  user: Record<string, string> | null;
};
type ApiCallWithQuery = <T = unknown>(query?: {
  [key: string]: string;
}) => Promise<ResponseWithData<T>>;

const ApiStateContext = createContext<State | undefined>(undefined);
const ApiDispatchContext = createContext<Dispatch | undefined>(undefined);

interface UseApi {
  bankQuestion: (key: string) => Promise<unknown>;
  createProject: (body?: unknown) => Promise<Response>;
  createQuestionImageSignedUploadUrl: <T = unknown>(
    key: string,
    payload: {
      currentContent: string;
      name: string;
    }
  ) => Promise<ResponseWithData<T>>;
  createSignedUploadUrl: <T = unknown>(
    id: string,
    payload: {
      name: string;
    }
  ) => Promise<ResponseWithData<T>>;
  deleteProject: (id: string) => Promise<unknown>;
  error: { message: string; timestamp: string };
  getJobs: ApiCallWithQuery;
  getJobsByProject: <T = unknown>(
    projectId: string,
    query?: { [key: string]: string }
  ) => Promise<ResponseWithData<T>>;
  getProject: <T = unknown>(id: string) => Promise<ResponseWithData<T>>;
  getProjects: ApiCallWithQuery;
  getQuestion: <T = unknown>(key: string) => Promise<ResponseWithData<T>>;
  getQuestions: <T = unknown>(
    projectId: string,
    query?: { [key: string]: string }
  ) => Promise<ResponseWithData<T>>;
  getRights: <T = unknown>() => Promise<ResponseWithData<T>>;
  getSkills: <T = unknown>() => Promise<ResponseWithData<T>>;
  getSyllabuses: <T = unknown>() => Promise<ResponseWithData<T>>;
  getTask: <T = unknown>(id: string) => Promise<ResponseWithData<T>>;
  getTasks: <T = unknown>() => Promise<ResponseWithData<T>>;
  getTasksByProject: <T = unknown>(id: string) => Promise<ResponseWithData<T>>;
  getTopics: <T = unknown>(query?: {
    [key: string]: string;
  }) => Promise<ResponseWithData<T>>;
  getUsers: <T = unknown>(query?: {
    [key: string]: string;
  }) => Promise<ResponseWithData<T>>;
  login: (body: { name: string; role: string }) => Promise<ResponseWithData>;
  logout: () => Promise<unknown>;
  rejectQuestion: (key: string, body: unknown) => Promise<unknown>;
  setApiUrl: (apiUrl: string) => void;
  setToken: (token: string) => void;
  setUser: (user: Record<string, string> | null) => void;
  token: string;
  startProject: (id: string) => Promise<unknown>;
  uploadFile: (url: string, file: FormData | File) => Promise<unknown>;
  uploadItems: () => Promise<unknown>;
  updateProject: (id: string, body?: unknown) => Promise<unknown>;
  updateQuestion: (key: string, body?: unknown) => Promise<unknown>;
  downloadQtiArchives: (
    id: string
  ) => Promise<ResponseWithData<Array<{ index: string; url: string }>>>;
  updateQuestionStatus: (
    status:
      | "approved"
      | "discarded"
      | "initial"
      | "metadata-added"
      | "initialized",
    key: string,
    body?: unknown
  ) => Promise<unknown>;
  user: Record<string, string> | null;
}

const apiReducer = (state: State, action: Action): State => {
  if (action.type === "API_URL_CONFIGURED") {
    return {
      ...state,
      apiUrl: action.payload as string
    };
  }

  if (action.type === "ERROR_SET") {
    return {
      ...state,
      error: {
        message: action.payload as string,
        timestamp: Date.now().toString()
      }
    };
  }

  if (action.type === "TOKEN_CONFIGURED") {
    return {
      ...state,
      token: action.payload as string
    };
  }

  if (action.type === "USER_FETCHED") {
    return {
      ...state,
      user: action.payload as Record<string, string>
    };
  }

  return state;
};

const ApiContext = ({ children }: ApiContextProps): ReactElement => {
  const [state, dispatch] = useReducer(apiReducer, {
    apiUrl: "",
    error: { message: "", timestamp: "" },
    token: "",
    user: null
  });

  return (
    <ApiStateContext.Provider value={state}>
      <ApiDispatchContext.Provider value={dispatch}>
        {children}
      </ApiDispatchContext.Provider>
    </ApiStateContext.Provider>
  );
};

const useApi = (): UseApi => {
  const dispatch = useApiDispatch();
  const { apiUrl, error, token, user } = useApiState();

  const bankQuestion = useCallback<UseApi["bankQuestion"]>(
    function bankQuestion(key) {
      const request = http.put(`${apiUrl}/questions/${key}/banked`, null, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error banking the question.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const createProject = useCallback<UseApi["createProject"]>(
    function createProject(body) {
      const request = http.post(`${apiUrl}/projects`, body, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error creating the project.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const createQuestionImageSignedUploadUrl = useCallback<
    UseApi["createQuestionImageSignedUploadUrl"]
  >(
    function createQuestionImageSignedUploadUrl<T = unknown>(
      key: string,
      payload: unknown
    ) {
      const request = http.post(
        `${apiUrl}/questions/${key}/figures/file-urls`,
        payload,
        {
          headers: { Authorization: token }
        }
      ) as Promise<ResponseWithData<T>>;

      request.catch(() => {
        dispatch({
          payload:
            "There was an error creating the question image signed upload URL.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const createSignedUploadUrl = useCallback<UseApi["createSignedUploadUrl"]>(
    function createSignedUploadUrl<T = unknown>(id: string, payload: unknown) {
      const request = http.post(`${apiUrl}/projects/${id}/uploads`, payload, {
        headers: { Authorization: token }
      }) as Promise<ResponseWithData<T>>;

      request.catch(() => {
        dispatch({
          payload: "There was an error creating the signed upload URL.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const deleteProject = useCallback<UseApi["deleteProject"]>(
    function deleteProject(id: string) {
      const request = http.delete(
        `${apiUrl}/projects/${id}`,
        {},
        {
          headers: { Authorization: token }
        }
      );

      request.catch(() => {
        dispatch({
          payload: "There was an error deleting the project.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getJobs = useCallback<UseApi["getJobs"]>(
    function getJobs<T = unknown>(query?: { [key: string]: string }) {
      const request = http.get<T>(`${apiUrl}/jobs${buildQuery(query)}`, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error getting the jobs.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getJobsByProject = useCallback<UseApi["getJobsByProject"]>(
    function getJobsByProject<T = unknown>(
      projectId: string,
      query?: { [key: string]: string }
    ) {
      const request = http.get<T>(
        `${apiUrl}/projects/${projectId}/jobs${buildQuery(query)}`,
        {
          headers: { Authorization: token }
        }
      );

      request.catch(() => {
        dispatch({
          payload: "There was an error getting the jobs by project.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getProject = useCallback<UseApi["getProject"]>(
    function getProject<T = unknown>(id: string) {
      const request = http.get<T>(`${apiUrl}/projects/${id}`, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error getting the project.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getProjects = useCallback<UseApi["getProjects"]>(
    function getProjects<T = unknown>(query?: { [key: string]: string }) {
      const request = http.get<T>(`${apiUrl}/projects${buildQuery(query)}`, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error getting the projects.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getQuestion = useCallback<UseApi["getQuestion"]>(
    function getQuestion<T = unknown>(key: string) {
      const request = http.get<T>(`${apiUrl}/questions/${key}`, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error getting the question.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getQuestions = useCallback<UseApi["getQuestions"]>(
    function getQuestions<T = unknown>(
      projectId: string,
      query?: { [key: string]: string }
    ) {
      const request = http.get<T>(
        `${apiUrl}/projects/${projectId}/questions${buildQuery(query)}`,
        {
          headers: { Authorization: token }
        }
      );

      request.catch(() => {
        dispatch({
          payload: "There was an error getting the questions.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getRights = useCallback<UseApi["getRights"]>(
    function getRights<T = unknown>() {
      const request = http.get<T>(`${apiUrl}/session`, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error getting the session.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getSkills = useCallback<UseApi["getSkills"]>(
    function getSkills<T = unknown>() {
      const request = http.get<T>(`${apiUrl}/skills`, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error getting the skills.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getSyllabuses = useCallback<UseApi["getSyllabuses"]>(
    function getSyllabuses<T = unknown>() {
      const request = http.get<T>(`${apiUrl}/syllabuses`, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error getting the syllabuses.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getTask = useCallback<UseApi["getTask"]>(
    function getTask<T = unknown>(id: string) {
      const request = http.get<T>(`${apiUrl}/tasks/${id}`, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error getting the task.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getTasks = useCallback<UseApi["getTasks"]>(
    function getTasks<T = unknown>() {
      const request = http.get<T>(`${apiUrl}/tasks`, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error getting the tasks.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getTasksByProject = useCallback<UseApi["getTasksByProject"]>(
    function getTasksByProject<T = unknown>(id: string) {
      const request = http.get<T>(`${apiUrl}/projects/${id}/tasks`, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error getting the tasks by project.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getTopics = useCallback<UseApi["getTopics"]>(
    function getTopics<T = unknown>(query?: { [key: string]: string }) {
      const request = http.get<T>(`${apiUrl}/keywords${buildQuery(query)}`, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error getting the topics.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const getUsers = useCallback<UseApi["getUsers"]>(
    function getUsers<T = unknown>(query?: { [key: string]: string }) {
      const request = http.get<T>(`${apiUrl}/users${buildQuery(query)}`, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error getting the users.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const login = useCallback<UseApi["login"]>(
    function login(body) {
      return http.post(`${apiUrl}/session`, body);
    },
    [apiUrl]
  );

  const logout = useCallback<UseApi["logout"]>(
    function logout() {
      return http.delete(`${apiUrl}/session`);
    },
    [apiUrl]
  );

  const rejectQuestion = useCallback<UseApi["rejectQuestion"]>(
    function rejectQuestion(key, body) {
      const request = http.put(`${apiUrl}/questions/${key}/rejected`, body, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error rejecting the question.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const setApiUrl = useCallback<UseApi["setApiUrl"]>(
    function setApiUrl(url) {
      dispatch({ payload: url, type: "API_URL_CONFIGURED" });
    },
    [dispatch]
  );

  const setToken = useCallback<UseApi["setToken"]>(
    function setToken(value) {
      dispatch({ type: "TOKEN_CONFIGURED", payload: value });
    },
    [dispatch]
  );

  const setUser = useCallback<UseApi["setUser"]>(
    function setUser(value) {
      dispatch({ payload: value, type: "USER_FETCHED" });
    },
    [dispatch]
  );

  const startProject = useCallback<UseApi["startProject"]>(
    function startProject(id) {
      const request = http.put(`${apiUrl}/projects/${id}/started`, null, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error starting the project.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const updateProject = useCallback<UseApi["updateProject"]>(
    (id, body) => {
      const request = http.put(`${apiUrl}/projects/${id}`, body, {
        headers: { Authorization: token }
      });

      request.catch(() => {
        dispatch({
          payload: "There was an error updating the project.",
          type: "ERROR_SET"
        });
      });

      return request;
    },
    [apiUrl, dispatch, token]
  );

  const updateQuestion = useCallback<UseApi["updateQuestion"]>(
    function updateQuestion(key, body) {
      return http
        .put(`${apiUrl}/questions/${key}`, body, {
          headers: { Authorization: token }
        })
        .catch(() => {
          dispatch({
            payload: "There was an error updating the question.",
            type: "ERROR_SET"
          });
        });
    },
    [apiUrl, dispatch, token]
  );

  const updateQuestionStatus = useCallback<UseApi["updateQuestionStatus"]>(
    function updateQuestionStatus(status, key, body) {
      return http
        .put(`${apiUrl}/questions/${key}/${status}`, body, {
          headers: { Authorization: token }
        })
        .catch(() => {
          dispatch({
            payload: "There was an error updating the question status.",
            type: "ERROR_SET"
          });
        });
    },
    [apiUrl, dispatch, token]
  );

  const uploadFile = useCallback<UseApi["uploadFile"]>(
    function uploadFile(url, file) {
      return http.put(url, null, { file }).catch(() => {
        dispatch({
          payload: "There was an error uploading the file.",
          type: "ERROR_SET"
        });
      });
    },
    [dispatch]
  );

  const uploadItems = useCallback<UseApi["uploadItems"]>(
    function uploadItems() {
      return http
        .post(
          `${apiUrl}/items`,
          { key: "0610_s20_11.zip" },
          { headers: { Authorization: token } }
        )
        .catch(() => {
          dispatch({
            payload: "There was an error uploading items.",
            type: "ERROR_SET"
          });
        });
    },
    [apiUrl, dispatch, token]
  );

  const downloadQtiArchives = useCallback<UseApi["downloadQtiArchives"]>(
    function downloadQtiArchives(id: string) {
      return http.get(`${apiUrl}/projects/${id}/archives`, {
        headers: { Authorization: token }
      });
    },
    [apiUrl, token]
  );

  return {
    bankQuestion,
    createProject,
    createQuestionImageSignedUploadUrl,
    createSignedUploadUrl,
    deleteProject,
    error,
    getJobs,
    getJobsByProject,
    getProject,
    getProjects,
    getQuestion,
    getQuestions,
    getRights,
    getSkills,
    getSyllabuses,
    getTask,
    getTasks,
    getTasksByProject,
    getTopics,
    getUsers,
    login,
    logout,
    rejectQuestion,
    setApiUrl,
    setToken,
    setUser,
    startProject,
    token,
    updateProject,
    updateQuestion,
    updateQuestionStatus,
    uploadFile,
    uploadItems,
    user,
    downloadQtiArchives
  };
};

const useApiDispatch = (): Dispatch => {
  const context = useContext(ApiDispatchContext);

  if (context === undefined) {
    throw new Error("useApiDispatch must be used within an ApiContext");
  }

  return context;
};

const useApiState = (): State => {
  const context = useContext(ApiStateContext);

  if (context === undefined) {
    throw new Error("useApiState must be used within an ApiContext");
  }

  return context;
};

export { ApiContext, useApi };
