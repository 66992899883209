// Core
import React, { FC, ReactElement, useEffect } from "react";

// Stylesheets
import "./Dropzone.scss";

// Vendor
import { Box, Chip, makeStyles, Typography } from "@material-ui/core";
import { FileRejection, useDropzone } from "react-dropzone";

interface Props {
  accept?: string;
  browseFilesClickedTimestamp?: string;
  chipLabel?: string;
  isUploading: boolean;
  maxFiles?: number;
  missingFile?: boolean;
  receivedFilesCallback: (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => void;
  testId?: string;
}

const Dropzone: FC<Props> = (props): ReactElement => {
  const { browseFilesClickedTimestamp, receivedFilesCallback } = props;
  const useStyles = makeStyles({
    missingFile: {
      opacity: 0.3
    }
  });
  const classes = useStyles();

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    open
  } = useDropzone({
    accept: props.accept ? props.accept : undefined,
    maxFiles: props.maxFiles ? props.maxFiles : 0
  });

  useEffect(() => {
    if (!browseFilesClickedTimestamp) {
      return;
    }

    open();
  }, [browseFilesClickedTimestamp, open]);

  useEffect(() => {
    receivedFilesCallback(acceptedFiles, fileRejections);
  }, [acceptedFiles, fileRejections, receivedFilesCallback]);

  const acceptedFileItems = acceptedFiles.length > 0 && (
    <Box flex={1} marginLeft={2} data-testid="accepted-files">
      {acceptedFiles.map((file) => (
        <Box key={file.name} marginBottom={1} data-testid="accepted-file">
          <Typography data-testid="accepted-file-name">{file.name}</Typography>
        </Box>
      ))}
    </Box>
  );

  const fileRejectionItems = fileRejections.length > 0 && (
    <Box marginLeft={2} data-testid="rejected-files">
      {fileRejections.map(({ file, errors }) => {
        return (
          <Box key={file.name} marginBottom={1} data-testid="rejected-file">
            <Typography data-testid="rejected-file-name">
              {file.name}
            </Typography>
            <Typography>
              {errors.map((e) => (
                <React.Fragment key={e.code}>{e.message}</React.Fragment>
              ))}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );

  return (
    <div
      className={`dropzone ${props.missingFile ? classes.missingFile : ""} ${
        acceptedFiles.length ? "has-files" : ""
      }`}
      data-testid={props.testId ? props.testId : "dropzone"}
      {...getRootProps()}
    >
      {props.chipLabel && (
        <Box position="absolute" top={10} right={10}>
          <Chip
            color="primary"
            label={props.chipLabel}
            data-testid="dropzone-chip"
          />
        </Box>
      )}
      <input {...getInputProps()} />
      {props.children}
      <Box
        alignItems="center"
        display="flex"
        flexWrap="wrap"
        width={acceptedFiles.length ? 1 : "auto"}
      >
        {acceptedFileItems}
        {fileRejectionItems}
      </Box>
    </div>
  );
};

export default Dropzone;
