// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Editor as TinyMCEEditor } from "@tinymce/tinymce-react";
import { Box } from "@material-ui/core";

interface Props {
  onEditorChange: (value: string) => void;
  testId?: string;
  value: string;
}

const Editor: FC<Props> = (props): ReactElement => {
  return (
    <Box width={1} data-testid={props.testId ? props.testId : "editor"}>
      <TinyMCEEditor
        apiKey="9pc8tzpclojkfcp1b6o53kxemnn8d8tlf8hi2im2jjzxyqrc"
        id={props.testId ? `${props.testId}-input` : "editor-input"}
        inline={true}
        init={{
          content_style: "body { font-size:16px }",
          extended_valid_elements: "b,i",
          external_plugins: {
            tiny_mce_wiris:
              "https://www.wiris.net/demo/plugins/tiny_mce/plugin.js"
          },
          // Commenting this out as there's currently no way to reference a file in the node_modules directory. Ideally, we would reference node_modules/@wiris/mathtype-tinymce5/plugin.min.js (npm package). The only way I can get this working is by copying the plugin.min.js file from our node_modules directory and pasting it in our public directory (renamed to mathtype-tinymce5.min.js), as show below, but this feels nasty. IH 26/05/21
          // external_plugins: {
          //   tiny_mce_wiris: `${process.env.PUBLIC_URL}/mathtype-tinymce5.min.js`
          // },
          forced_root_block: "",
          formats: {
            bold: { inline: "b" },
            italic: { inline: "i" },
            underline: { inline: "u" }
          },
          inline_boundaries: false,
          menubar: false,
          plugins: ["charmap"],
          toolbar:
            "bold | italic | underline | superscript | subscript | charmap | tiny_mce_wiris_formulaEditor"
        }}
        onEditorChange={(value) => props.onEditorChange(value)}
        value={props.value}
      />
    </Box>
  );
};

export default Editor;
