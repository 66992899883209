// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ApproversCard from "pages/projects/project/components/approversCard/ApproversCard";
import SubjectExpertsCard from "pages/projects/project/components/subjectExpertsCard/SubjectExpertsCard";

// Enums
import { ProjectStatus, ProjectType } from "enums";

// Interfaces
import { Task } from "interfaces";

// Utils
import { useApi, useProject } from "utils/context";
import { sharedStyles } from "utils/theme";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import { Check, ChevronLeft, Description } from "@material-ui/icons";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

const Project: FC = (): ReactElement => {
  const { getTasksByProject } = useApi();
  const { project } = useProject();
  const [subjectExpertasks, setSubjectExpertTasks] = useState([] as Task[]);
  const history = useHistory();
  const sharedClasses = sharedStyles();

  useEffect(() => {
    if (!Object.keys(project).length) {
      return;
    }

    getTasksByProject<Task[]>(project.id).then((response) => {
      setSubjectExpertTasks(
        response.data?.filter((task) => task.type === "subject-expert") || []
      );
    });
  }, [getTasksByProject, project]);

  const clickBack = (): void => {
    history.push("/admin/dashboard");
  };

  const clickViewBanked = (): void => {
    history.push(`/projects/${project.id}/completed/banked`);
  };

  const clickViewRejected = (): void => {
    history.push(`/projects/${project.id}/completed/rejected`);
  };

  return (
    <div data-testid="project-page">
      {Object.keys(project).length > 0 && (
        <>
          <Box
            clone
            className={sharedClasses.bleed}
            marginTop="-47px"
            marginBottom={6}
            paddingY={5}
          >
            <Paper elevation={0}>
              <Box clone marginBottom={3}>
                <Button
                  color="primary"
                  onClick={clickBack}
                  data-testid="back-button"
                  startIcon={<ChevronLeft />}
                  variant="text"
                >
                  Back to dashboard
                </Button>
              </Box>
              <Box alignItems="center" display="flex" marginBottom={4}>
                <Box marginRight={6}>
                  <Box marginBottom={1}>
                    <Typography
                      component="h2"
                      variant="h3"
                      data-testid="project-name"
                    >
                      {project.name}
                    </Typography>
                  </Box>
                  <Typography
                    component="h3"
                    variant="h4"
                    data-testid="project-type"
                  >
                    {ProjectType[project.type]}
                  </Typography>
                </Box>
                <Chip
                  color="primary"
                  icon={
                    project.status === "completed" ? (
                      <Check fontSize="small" />
                    ) : undefined
                  }
                  label={
                    project.status === "completed"
                      ? `Completed on ${project.completionDate}`
                      : ProjectStatus[project.status]
                  }
                  variant="outlined"
                />
              </Box>

              <Grid container>
                <Grid item xs={12} md={5}>
                  <Typography data-testid="instructions">
                    {project.instructions}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Box>
          <Box marginBottom={6}>
            <Grid container spacing={10}>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Box display="flex">
                      <Box marginRight={8}>
                        <Box marginBottom={1}>
                          <Typography
                            className={sharedClasses.fadedText}
                            component="h4"
                            variant="subtitle1"
                          >
                            Syllabus code
                          </Typography>
                        </Box>
                        <Typography
                          component="p"
                          variant="h5"
                          data-testid="syllabus-code"
                        >
                          {project.syllabusCode}
                        </Typography>
                      </Box>
                      <Box>
                        <Box marginBottom={1}>
                          <Typography
                            className={sharedClasses.fadedText}
                            component="h4"
                            variant="subtitle1"
                          >
                            Extracted items
                          </Typography>
                        </Box>
                        <Typography
                          component="p"
                          variant="h5"
                          data-testid="items"
                        >
                          {project.items}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              {project.status === "completed" && (
                <>
                  <Grid item xs={12} md={4}>
                    <Card>
                      <CardContent>
                        <Box alignItems="center" display="flex">
                          <Box>
                            <Box marginBottom={1}>
                              <Typography
                                className={sharedClasses.fadedText}
                                component="h4"
                                variant="subtitle1"
                              >
                                Banked items
                              </Typography>
                            </Box>
                            <Typography
                              component="p"
                              variant="h5"
                              data-testid="banked-items"
                            >
                              {project.bankedItems}
                            </Typography>
                          </Box>
                          <Box marginLeft="auto">
                            <Button
                              color="primary"
                              onClick={clickViewBanked}
                              data-testid="view-banked-items-button"
                              startIcon={<Description />}
                              variant="text"
                            >
                              View
                            </Button>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card>
                      <CardContent>
                        <Box alignItems="center" display="flex">
                          <Box>
                            <Box marginBottom={1}>
                              <Typography
                                className={sharedClasses.fadedText}
                                component="h4"
                                variant="subtitle1"
                              >
                                Rejected items
                              </Typography>
                            </Box>
                            <Typography
                              component="p"
                              variant="h5"
                              data-testid="rejected-items"
                            >
                              {project.rejectedItems}
                            </Typography>
                          </Box>
                          <Box marginLeft="auto">
                            <Button
                              color="primary"
                              onClick={clickViewRejected}
                              data-testid="view-rejected-items-button"
                              startIcon={<Description />}
                              variant="text"
                            >
                              View
                            </Button>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
              {project.status !== "completed" && (
                <Grid item xs={12} md={8}>
                  <Card>
                    <CardContent>
                      <Box display="flex">
                        <Box marginRight={8}>
                          <Box marginBottom={1}>
                            <Typography
                              className={sharedClasses.fadedText}
                              component="h4"
                              variant="subtitle1"
                            >
                              Banked items
                            </Typography>
                          </Box>
                          <Typography
                            component="p"
                            variant="h5"
                            data-testid="banked-items"
                          >
                            {project.bankedItems}
                          </Typography>
                        </Box>
                        <Box marginRight={8}>
                          <Box marginBottom={1}>
                            <Typography
                              className={sharedClasses.fadedText}
                              component="h4"
                              variant="subtitle1"
                            >
                              Rejected items
                            </Typography>
                          </Box>
                          <Typography
                            component="p"
                            variant="h5"
                            data-testid="rejected-items"
                          >
                            {project.rejectedItems}
                          </Typography>
                        </Box>
                        <Divider flexItem orientation="vertical" />
                        <Box marginLeft={8}>
                          <Box marginBottom={1}>
                            <Typography
                              className={sharedClasses.fadedText}
                              component="h4"
                              variant="subtitle1"
                            >
                              Due date
                            </Typography>
                          </Box>
                          <Typography
                            component="p"
                            variant="h5"
                            data-testid="target-end-date"
                          >
                            {project.targetEndDate
                              ? dayjs(
                                  project.targetEndDate.split("T")[0]
                                ).format("DD/MM/YYYY")
                              : "None"}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Box>
          <Box marginBottom={6}>
            <ApproversCard project={project} />
          </Box>
          <SubjectExpertsCard project={project} tasks={subjectExpertasks} />
        </>
      )}
    </div>
  );
};

export default Project;
