// Core
import React, { FC, ReactElement } from "react";

// Enums
import { ProjectType } from "enums";

// Interfaces
import { Project } from "interfaces";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

interface Props {
  project: Project;
}

const CompletedProject: FC<Props> = (props): ReactElement => {
  const history = useHistory();
  const sharedClasses = sharedStyles();

  const clickViewProjectDetails = (): void => {
    history.push(`/projects/${props.project.id}`);
  };

  return (
    <Box clone marginBottom={4} data-testid="completed-project">
      <Card>
        <CardContent>
          <Box marginBottom={4}>
            <Box marginBottom={1}>
              <Typography
                component="h2"
                variant="h4"
                data-testid="project-name"
              >
                {props.project.name}
              </Typography>
            </Box>
            <Typography data-testid="project-type">
              {ProjectType[props.project.type]}
            </Typography>
          </Box>
          <Box display="flex">
            <Box marginRight={8}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Total items
                </Typography>
              </Box>
              <Typography component="p" variant="h5" data-testid="total-items">
                {props.project.bankedItems + props.project.rejectedItems}
              </Typography>
            </Box>
            <Divider flexItem orientation="vertical" />
            <Box marginLeft={8} marginRight={8}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Approver
                </Typography>
              </Box>
              <Typography component="p" variant="h5" data-testid="approvers">
                {props.project.approvers.map(
                  (approver, index) =>
                    `${approver.email}${
                      index === props.project.approvers.length - 1 ? "" : ", "
                    }`
                ) || []}
              </Typography>
            </Box>
            <Box marginRight={8}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Banked items
                </Typography>
              </Box>
              <Typography component="p" variant="h5" data-testid="banked-items">
                {props.project.bankedItems}
              </Typography>
            </Box>
            <Box marginRight={8}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Rejected items
                </Typography>
              </Box>
              <Typography
                component="p"
                variant="h5"
                data-testid="rejected-items"
              >
                {props.project.rejectedItems}
              </Typography>
            </Box>
            <Divider flexItem orientation="vertical" />
            <Box marginLeft={8} marginRight={8}>
              <Box alignItems="center" display="flex" marginBottom={1}>
                <Box marginRight={1}>
                  <Typography
                    className={sharedClasses.fadedText}
                    component="h3"
                    variant="subtitle1"
                  >
                    Completion date
                  </Typography>
                </Box>
                <Check />
              </Box>
              <Typography
                component="p"
                variant="h5"
                data-testid="completion-date"
              >
                {props.project.completionDate}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            disableElevation
            onClick={clickViewProjectDetails}
            variant="contained"
            data-testid="view-details-button"
          >
            View project details
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default CompletedProject;
