// Core
import React, {
  ChangeEvent,
  FC,
  ReactElement,
  useEffect,
  useState
} from "react";

// Interfaces
import { Syllabus } from "interfaces";

// Utils
import { useApi, useProject } from "utils/context";

// Vendor
import {
  Button,
  Divider,
  Grid,
  TextField
} from "@cambridgeassessment/cambridge-ui";
import { Box, Typography, withStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";

const CustomTextField = withStyles(() => ({
  root: {
    marginBottom: 0,
    "& > .MuiInputBase-root": {
      height: "auto",
      marginBottom: 0
    },
    "& input": {
      padding: "10px 16px !important"
    }
  }
}))(TextField);

const SyllabusCode: FC = (): ReactElement => {
  const { getSyllabuses, updateProject } = useApi();
  const { project, updateProjectSuccess } = useProject();
  const [activeSyllabus, setActiveSyllabus] = useState({} as Syllabus);
  const [syllabuses, setSyllabuses] = useState([] as Syllabus[]);
  const history = useHistory();

  useEffect(() => {
    getSyllabuses<Syllabus[]>().then((response) => {
      setSyllabuses(response.data as Syllabus[]);
    });
  }, [getSyllabuses]);

  const changeSyllabuses = (
    event: ChangeEvent<unknown>,
    option: Syllabus | null
  ): void => {
    if (!option) {
      setActiveSyllabus({} as Syllabus);

      return;
    }

    setActiveSyllabus(option);
  };

  const clickContinue = (): void => {
    updateProject(project.id, {
      ...project,
      syllabusCode: activeSyllabus.code
    }).then(() => {
      updateProjectSuccess({
        ...project,
        syllabusCode: activeSyllabus.code
      });

      history.push(`/projects/${project.id}/edit/upload`);
    });
  };

  return (
    <div data-testid="syllabus-code-page">
      <Box marginBottom={4}>
        <Box display="flex" marginBottom={4}>
          <Box>
            <Box marginBottom={1}>
              <Typography
                component="h2"
                variant="h4"
                data-testid="page-heading"
              >
                Enter syllabus code
              </Typography>
            </Box>
            <Typography data-testid="page-introduction">
              You will be able to upload past papers for only this syllabus
            </Typography>
          </Box>
          <Box marginLeft="auto">
            <Button
              color="primary"
              disableElevation
              disabled={!Object.keys(activeSyllabus).length}
              onClick={clickContinue}
              data-testid="continue-button"
            >
              Continue
            </Button>
          </Box>
        </Box>
        <Divider />
      </Box>
      <Grid container>
        <Grid item md={4} sm={12}>
          <Autocomplete
            filterSelectedOptions
            getOptionLabel={(option) => `${option.code} - ${option.name}`}
            onChange={changeSyllabuses}
            options={syllabuses}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  "data-testid": "syllabuses-input"
                }}
                placeholder="Search for a syllabus"
              />
            )}
            data-testid={"syllabuses-autocomplete"}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SyllabusCode;
