// Core
import React, { FC, ReactElement, useEffect } from "react";

// Utils
import { useApi } from "utils/context";

// Vendor
import { useHistory } from "react-router-dom";

const LoginRedirect: FC = (): ReactElement => {
  const { user } = useApi();
  const history = useHistory();

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.role === "admin") {
      history.push("/admin/dashboard");
      return;
    }

    if (user.role === "examiner") {
      history.push("/examiner/dashboard");
      return;
    }

    history.push("/unauthorised");
  }, [history, user]);

  return <></>;
};

export default LoginRedirect;
