// Core
import React, { ReactElement, useCallback, useEffect, useState } from "react";

// Components
import LoginRedirect from "components/loginRedirect/LoginRedirect";
import AdminLayout from "layouts/adminLayout/AdminLayout";
import EditProjectLayout from "layouts/editProjectLayout/EditProjectLayout";
import Layout from "layouts/layout/Layout";
import ProjectLayout from "layouts/projectLayout/ProjectLayout";
import ProjectsLayout from "layouts/projectsLayout/ProjectsLayout";

import {
  AdminDashboard,
  ApproverTask,
  Approvers,
  Authorise,
  Completed,
  Confirm,
  Edit,
  ExaminerDashboard,
  Home,
  Project,
  Projects,
  SubjectExpertTask,
  SubjectExperts,
  SyllabusCode,
  Unauthorised,
  Upload,
  Uploads
} from "pages";

// Utils
import { useApi } from "utils/context";
import { createThemeOptions } from "utils/theme";

// Vendor
import { themeOptions } from "@cambridgeassessment/cambridge-ui";
import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import { useApplicationEnv } from "application.env/react";
import { AuthProvider, useAuth } from "oidc-react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const DownloadArchives = (): ReactElement => {
  const history = useHistory();
  const [links, setLinks] = useState<Array<{ index: string; url: string }>>([]);
  const { downloadQtiArchives } = useApi();

  useEffect(() => {
    const parts = window.location.href.split("/");

    const uuid = parts.find((part) =>
      /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/.test(
        part
      )
    );

    downloadQtiArchives(uuid as string).then((res) => {
      setLinks(res.data as Array<{ index: string; url: string }>);
    });
  }, [downloadQtiArchives, history]);

  return (
    <>
      {links.map((x) => (
        <div key={x.index}>
          <a href={x.url} download rel="noreferrer" target="_blank">
            {x.index}
          </a>
        </div>
      ))}
    </>
  );
};

const NotFound = (): ReactElement => {
  return <>Not found</>;
};

const Routes = (): ReactElement => {
  const auth = useAuth();
  const { getRights, setToken, setUser, token, user } = useApi();
  const [loading, setLoading] = useState(true);
  const [errorGettingRights, setErrorGettingRights] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);

    if (
      auth?.userData &&
      (!token || token !== `Bearer ${auth?.userData?.access_token}`)
    ) {
      setLoading(false);
      setToken(`Bearer ${auth.userData.access_token}`);
    }
  }, [auth, token, setToken]);

  useEffect(() => {
    if (errorGettingRights) {
      return;
    }

    setLoading(true);

    if (token && !user && auth?.userData) {
      getRights<Record<string, string>>()
        .then((res) => {
          if (!res.data) {
            auth?.signIn();
          }

          setUser(res.data as Record<string, string>);
        })
        .catch(() => setErrorGettingRights(true))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [auth, errorGettingRights, getRights, history, setUser, token, user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Switch>
      {!user && (
        <Switch>
          <Route component={Home} />
        </Switch>
      )}
      {user && token && (
        <Switch>
          <Route component={LoginRedirect} exact path="/" />
          <Route
            path="/admin"
            render={({ match }): ReactElement => (
              <AdminLayout>
                <Switch>
                  <Route
                    component={AdminDashboard}
                    exact
                    path={`${match.url}/dashboard`}
                  />
                  <Route
                    component={Projects}
                    exact
                    path={`${match.url}/projects`}
                  />
                </Switch>
              </AdminLayout>
            )}
          />
          <Route
            component={ApproverTask}
            exact
            path="/approver/tasks/:taskId/questions/:questionKey"
          />
          <Route component={Authorise} exact path="/authorise" />
          <Route
            component={ExaminerDashboard}
            exact
            path="/examiner/dashboard"
          />
          <Route
            path="/projects"
            render={({ match: projectsMatch }): ReactElement => (
              <ProjectsLayout>
                <Switch>
                  <Route
                    component={Edit}
                    exact
                    path={`${projectsMatch.url}/new`}
                  />
                  <Route
                    path={`${projectsMatch.url}/:id`}
                    render={({ match: projectMatch }): ReactElement => (
                      <ProjectLayout projectId={projectMatch.params.id}>
                        <Switch>
                          <Route
                            component={Project}
                            exact
                            path={`${projectMatch.url}/`}
                          />
                          <Route
                            component={Completed}
                            exact
                            path={`${projectMatch.url}/completed/:status`}
                          />
                          <Route
                            path={`${projectMatch.url}/edit`}
                            render={({
                              match: editProjectMatch
                            }): ReactElement => (
                              <EditProjectLayout>
                                <Switch>
                                  <Route
                                    component={Approvers}
                                    exact
                                    path={`${editProjectMatch.url}/approvers`}
                                  />
                                  <Route
                                    component={Confirm}
                                    exact
                                    path={`${editProjectMatch.url}/confirm`}
                                  />
                                  <Route
                                    component={Edit}
                                    exact
                                    path={`${editProjectMatch.url}/value`}
                                  />
                                  <Route
                                    component={SubjectExperts}
                                    exact
                                    path={`${editProjectMatch.url}/subject-experts`}
                                  />
                                  <Route
                                    component={SyllabusCode}
                                    exact
                                    path={`${editProjectMatch.url}/syllabus-code`}
                                  />
                                  <Route
                                    component={Upload}
                                    exact
                                    path={`${editProjectMatch.url}/upload`}
                                  />
                                  <Route
                                    component={SyllabusCode}
                                    exact
                                    path={`${editProjectMatch.url}/syllabus-code`}
                                  />
                                  <Route
                                    component={Uploads}
                                    exact
                                    path={`${editProjectMatch.url}/uploads`}
                                  />
                                  <Route
                                    component={DownloadArchives}
                                    exact
                                    path={`${editProjectMatch.url}/:action/download`}
                                  />
                                </Switch>
                              </EditProjectLayout>
                            )}
                          />
                        </Switch>
                      </ProjectLayout>
                    )}
                  />
                </Switch>
              </ProjectsLayout>
            )}
          />
          <Route
            component={SubjectExpertTask}
            exact
            path="/subject-expert/tasks/:taskId/questions/:questionKey"
          />
          <Route component={Unauthorised} exact path="/unauthorised" />
          <Route component={NotFound} exact path="*" />
        </Switch>
      )}
    </Switch>
  );
};

const App = (): ReactElement => {
  const config = useApplicationEnv();
  const { error, setApiUrl, setToken } = useApi();

  const Logout = () => {
    const auth = useAuth();

    auth?.signOutRedirect("/");

    return <></>;
  };

  const onSignIn = useCallback(
    function onSignIn(userData) {
      if (userData && userData.state) {
        setToken(`Bearer ${userData.access_token}`);
        window.history.replaceState(
          {},
          "",
          window.location.origin + window.location.pathname
        );
      }
    },
    [setToken]
  );

  useEffect(() => {
    if (!config) {
      return;
    }

    setApiUrl(config.API_DOMAIN || "API_DOMAIN_NOT_CONFIGURED");
  }, [config, setApiUrl]);

  useEffect(() => {
    if (!error.message) {
      return;
    }

    toast.error(error.message);
  }, [error]);

  if (!config) {
    return <>Loading...</>;
  }

  return (
    <>
      <ToastContainer
        autoClose={5000}
        closeOnClick
        draggable={false}
        hideProgressBar
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="top-right"
        rtl={false}
      />
      <ThemeProvider theme={createMuiTheme(createThemeOptions(themeOptions))}>
        <CssBaseline />
        <Router>
          <AuthProvider
            authority={config?.AUTH_DOMAIN || ""}
            clientId={config?.AUTH_CLIENT_ID}
            responseType="code"
            autoSignIn={false}
            onSignIn={onSignIn}
            scope="openid profile email roles orgs platformAccess"
            redirectUri={`${window.location.origin}/`}
          >
            <Layout>
              <Routes />
              <Route path="/logout" component={Logout} />
            </Layout>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </>
  );
};

export default App;
