// Core
import React, { FC, ReactElement, useState } from "react";

// Components
import Initial from "pages/projects/upload/components/initial/Initial";
import Summary from "pages/projects/upload/components/summary/Summary";

const Upload: FC = (): ReactElement => {
  const [activeStep, setActiveStep] = useState(
    "initial" as "initial" | "summary"
  );
  const [invalidFiles, setInvalidFiles] = useState({
    existing: [],
    invalid: [],
    rejected: []
  } as { existing: File[]; invalid: File[]; rejected: File[] });
  const [validFiles, setValidFiles] = useState([] as File[]);

  return (
    <div data-testid="upload-page">
      {activeStep === "initial" && (
        <Initial
          setActiveStep={setActiveStep}
          setInvalidFiles={setInvalidFiles}
          setValidFiles={setValidFiles}
        />
      )}
      {activeStep === "summary" && (
        <Summary
          invalidFiles={invalidFiles}
          setActiveStep={setActiveStep}
          setInvalidFiles={setInvalidFiles}
          setValidFiles={setValidFiles}
          validFiles={validFiles}
        />
      )}
    </div>
  );
};

export default Upload;
