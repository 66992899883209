// Core
import React, { FC, ReactElement } from "react";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";

interface Props {
  clickComplete?: () => void;
  isCompleteDisabled?: boolean;
  items: {
    clickEdit: () => void;
    data: number | string | string[];
    heading: string;
  }[];
}

const ProjectSummary: FC<Props> = (props): ReactElement => {
  const sharedClasses = sharedStyles();

  return (
    <Card data-testid="project-summary">
      <CardContent>
        {props.items.map((item, index) => {
          const isLast = index === props.items.length - 1;

          return (
            <Box key={item.heading} marginBottom={isLast ? 0 : 3}>
              <Box alignItems="center" display="flex">
                <Box>
                  <Box marginBottom={1}>
                    <Typography
                      className={sharedClasses.fadedText}
                      variant="subtitle1"
                      data-testid="project-summary-heading"
                    >
                      {item.heading}
                    </Typography>
                  </Box>
                  <Box marginBottom={3}>
                    <Typography
                      component="p"
                      variant="h5"
                      data-testid="project-summary-data"
                    >
                      {item.data}
                    </Typography>
                  </Box>
                </Box>
                <Box marginLeft="auto">
                  <Button
                    color="primary"
                    disableElevation
                    onClick={item.clickEdit}
                    startIcon={<Edit />}
                    variant="text"
                    data-testid="edit-button"
                  >
                    Edit
                  </Button>
                </Box>
              </Box>
              {!isLast && <Divider />}
            </Box>
          );
        })}
      </CardContent>
      {props.clickComplete && (
        <CardActions>
          <Button
            color="primary"
            disableElevation
            disabled={props.isCompleteDisabled}
            onClick={props.clickComplete}
            data-testid="complete-button"
          >
            Continue
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default ProjectSummary;
