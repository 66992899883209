// Core
import React, { FC, ReactElement } from "react";

// Enums
import { ProjectType } from "enums";

// Interfaces
import { Project } from "interfaces";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

interface Props {
  clickDelete: (projectId: string) => void;
  project: Project;
}

const NotStartedProject: FC<Props> = (props): ReactElement => {
  const history = useHistory();
  const sharedClasses = sharedStyles();

  const clickDelete = (): void => {
    props.clickDelete(props.project.id);
  };

  const clickGoToProject = (): void => {
    history.push(`/projects/${props.project.id}/edit`);
  };

  return (
    <Box clone marginBottom={4} data-testid="not-started-project">
      <Card>
        <CardContent>
          <Box marginBottom={4}>
            <Box marginBottom={1}>
              <Typography
                component="h2"
                variant="h4"
                data-testid="project-name"
              >
                {props.project.name}
              </Typography>
            </Box>
            <Typography data-testid="project-type">
              {ProjectType[props.project.type]}
            </Typography>
          </Box>
          <Box display="flex">
            <Box marginRight={8}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Uploaded test papers
                </Typography>
              </Box>
              <Typography
                component="p"
                variant="h5"
                data-testid="uploaded-papers"
              >
                {props.project.uploadedJobs}
              </Typography>
            </Box>
            <Box marginRight={8}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Extracted items
                </Typography>
              </Box>
              <Typography component="p" variant="h5" data-testid="items">
                {props.project.items}
              </Typography>
            </Box>
            <Box marginRight={8}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Subject experts
                </Typography>
              </Box>
              <Typography
                component="p"
                variant="h5"
                data-testid="subject-experts"
              >
                {props.project.subjectExperts.length === 0
                  ? "Not assigned yet"
                  : props.project.subjectExperts.length}
              </Typography>
            </Box>
            <Box>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Approvers
                </Typography>
              </Box>
              <Typography component="p" variant="h5" data-testid="approvers">
                {props.project.approvers.length === 0
                  ? "Not assigned yet"
                  : props.project.approvers.length}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          <Box clone marginRight={2}>
            <Button
              color="destructive"
              onClick={clickDelete}
              variant="text"
              data-testid="delete-button"
            >
              Delete this project
            </Button>
          </Box>
          <Button
            color="primary"
            disableElevation
            onClick={clickGoToProject}
            variant="contained"
            data-testid="go-to-project-button"
          >
            Go to project
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default NotStartedProject;
