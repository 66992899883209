// Core
import React, { ChangeEvent, FC, ReactElement, useState } from "react";

// Components
import ApproversTable from "pages/projects/approvers/components/approversTable/ApproversTable";
import UserOption from "components/userOption/UserOption";

// Interfaces
import { User } from "interfaces";

// Utils
import { useApi, useProject } from "utils/context";
import { useDebouncedEffect } from "utils/useDebouncedEffect";

// Vendor
import { Button, Divider, TextField } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Grid,
  InputAdornment,
  Typography,
  withStyles
} from "@material-ui/core";
import { Add, Search } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";

const CustomTextField = withStyles(() => ({
  root: {
    marginBottom: 0,
    "& > .MuiInputBase-root": {
      marginBottom: 0
    },
    "& input": {
      padding: "10px 16px !important"
    }
  }
}))(TextField);

const Approvers: FC = (): ReactElement => {
  const { getUsers, updateProject } = useApi();
  const { project, updateProjectSuccess } = useProject();
  const [activeApproverOption, setActiveApproverOption] = useState({} as User);
  const [approverOptions, setApproverOptions] = useState([] as User[]);
  const [formFields, setFormFields] = useState({
    approver: ""
  } as { approver: string });
  const history = useHistory();

  useDebouncedEffect(
    () => {
      if (formFields.approver.length < 3) {
        return;
      }

      getUsers<User[]>({ search: formFields.approver }).then((response) => {
        const emails = project.subjectExperts.map((subjectExpert) =>
          subjectExpert.email.toLowerCase()
        );

        setApproverOptions(
          response.data?.filter(
            (user) => !emails.includes(user.email.toLowerCase())
          ) || []
        );
      });
    },
    1000,
    [formFields.approver, getUsers, project.approvers, project.subjectExperts]
  );

  const changeApprover = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFormFields({
      ...formFields,
      approver: event.target.value
    });
  };

  const changeApproverOptions = (
    event: ChangeEvent<unknown>,
    option: User | null
  ): void => {
    if (!option) {
      return;
    }

    setActiveApproverOption(option);
  };

  const clickAdd = (): void => {
    const approver = {
      email: activeApproverOption.email,
      sub: activeApproverOption.sub
    };

    updateProject(project.id, {
      ...project,
      approvers: [...project.approvers, approver]
    }).then(() => {
      updateProjectSuccess({
        ...project,
        approvers: [...project.approvers, approver]
      });

      setActiveApproverOption({} as User);
    });
  };

  const clickContinue = (): void => {
    history.push(`/projects/${project.id}/edit/confirm`);
  };

  const clickDelete = (email: string): void => {
    const approvers = project.approvers.filter(
      (approver) => approver.email !== email
    );

    updateProject(project.id, {
      ...project,
      approvers
    }).then(() => {
      updateProjectSuccess({
        ...project,
        approvers
      });
    });
  };

  const renderUserOption = (option: User): ReactElement => {
    return <UserOption option={option} />;
  };

  return (
    <div data-testid="approvers-page">
      {Object.keys(project).length > 0 && (
        <>
          <Box marginBottom={4}>
            <Box display="flex" marginBottom={4}>
              <Box>
                <Box marginBottom={1}>
                  <Typography
                    component="h2"
                    variant="h4"
                    data-testid="page-heading"
                  >
                    Add an approver
                  </Typography>
                </Box>
                <Typography data-testid="page-introduction">
                  Approvers perform a final check before the items are banked.
                  They will be notified when the project starts
                </Typography>
              </Box>
              <Box marginLeft="auto">
                <Button
                  color="primary"
                  disableElevation
                  disabled={!project.approvers.length}
                  onClick={clickContinue}
                  data-testid="continue-button"
                >
                  Continue
                </Button>
              </Box>
            </Box>
            <Divider />
          </Box>
          <Box marginBottom={4}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item md={7} xs={12}>
                <Autocomplete
                  disabled={project.approvers.length > 0}
                  filterOptions={(approver) => approver}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.email || ""}
                  onChange={changeApproverOptions}
                  options={[activeApproverOption, ...approverOptions]}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        "data-testid": "approvers-input"
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                      onChange={changeApprover}
                      placeholder="Search name or email address"
                    />
                  )}
                  renderOption={(option) => renderUserOption(option)}
                  value={activeApproverOption}
                  data-testid="approvers-autocomplete"
                />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  disableElevation
                  disabled={!Object.keys(activeApproverOption).length}
                  onClick={clickAdd}
                  startIcon={<Add />}
                  data-testid="add-button"
                >
                  Add selected approver
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <ApproversTable approvers={project.approvers} clickDelete={clickDelete} />
    </div>
  );
};

export default Approvers;
