// Core
import React, { FC, ReactElement, useState } from "react";

// Components
import Tooltip from "components/tooltip/Tooltip";

// Interfaces
import { Job as JobInterface } from "interfaces";

// Utils
import { getSyllabusAndVariation } from "utils/string";
import { sharedStyles } from "utils/theme";

// Vendor
import { Divider } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Collapse,
  Typography
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

interface Props {
  chipLabel?: string;
  detail?: boolean;
  job: JobInterface;
  last: boolean;
}

const Job: FC<Props> = (props): ReactElement => {
  const [isExpanded, setIsExpanded] = useState(false);
  const sharedClasses = sharedStyles();

  const clickToggleDetail = (): void => {
    if (!props.detail) {
      return;
    }

    setIsExpanded(!isExpanded);
  };

  return (
    <Box
      clone
      marginBottom={props.last ? 0 : 4}
      onClick={clickToggleDetail}
      position="relative"
      style={{ cursor: props.detail ? "pointer" : "default" }}
    >
      <Card data-testid={`${props.job.status}-job`}>
        {props.chipLabel && (
          <Box position="absolute" top={10} right={10}>
            <Chip
              color="primary"
              label={props.chipLabel}
              data-testid="job-chip"
            />
          </Box>
        )}
        <CardContent>
          <Box
            display="flex"
            marginBottom={props.job.status === "finished" ? 1 : 0}
          >
            <Typography component="h2" variant="h5" data-testid="job-heading">
              {props.job.status === "finished" && props.job.metadata
                ? `${props.job.metadata["qualification"]} ${props.job.metadata["subject"]}`
                : `Paper ${getSyllabusAndVariation(props.job.questionPaper)}`}
            </Typography>
            {props.detail && (
              <Box marginLeft="auto">
                {!isExpanded && <KeyboardArrowDown />}
                {isExpanded && <KeyboardArrowUp />}
              </Box>
            )}
          </Box>
          {props.job.status === "finished" && props.job.metadata && (
            <Typography
              component="h3"
              style={{ opacity: 0.59 }}
              variant="subtitle1"
              data-testid="job-subheading"
            >
              Paper {getSyllabusAndVariation(props.job.questionPaper)} - From{" "}
              {props.job.metadata["series"]}
            </Typography>
          )}
          {props.detail && (
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Box display="flex" marginTop={4} data-testid="detail">
                <Box marginRight={7}>
                  <Box marginBottom={1}>
                    <Typography component="h3" variant="h4" data-testid="items">
                      {props.job.rejectedItems + props.job.acceptedItems}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography
                      className={sharedClasses.fadedText}
                      variant="subtitle1"
                    >
                      Harvested items
                    </Typography>
                    <Tooltip title="This is the total number of items in this paper that our tool attempted to harvest." />
                  </Box>
                </Box>
                <Divider flexItem orientation="vertical" />
                <Box marginLeft={7}>
                  <Box marginBottom={1}>
                    <Typography
                      component="h3"
                      variant="h4"
                      data-testid="accepted"
                    >
                      {props.job.acceptedItems}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography
                      className={sharedClasses.fadedText}
                      variant="subtitle1"
                    >
                      Accepted
                    </Typography>
                    <Tooltip title="This is the total number of successfully harvested items." />
                  </Box>
                </Box>
                <Box marginLeft={7}>
                  <Box marginBottom={1}>
                    <Typography
                      component="h3"
                      variant="h4"
                      data-testid="rejected"
                    >
                      {props.job.rejectedItems}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography
                      className={sharedClasses.fadedText}
                      variant="subtitle1"
                    >
                      Rejected
                    </Typography>
                    <Tooltip title="There was an issue with harvesting these items and they were rejected. Subject Experts will not be asked to check them." />
                  </Box>
                </Box>
              </Box>
            </Collapse>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Job;
