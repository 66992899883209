// Core
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";

// Components
import Dropzone from "components/dropzone/Dropzone";
import Editor from "components/editor/Editor";

// Interfaces
import { Stimulus as StimulusInterface } from "interfaces";

// Utils
import { generateBase64 } from "utils/generateBase64";

// Vendor
import { Box, Typography } from "@material-ui/core";
import MathJax from "react-mathjax-preview";
import { v4 as uuidv4 } from "uuid";

interface Props {
  isEditing: boolean;
  items: StimulusInterface["items"];
  onChange: (value: string, index: number) => void;
  onChangeImage: (value: string, file: File, index: number) => void;
}

const StimulusItems: FC<Props> = (props): ReactElement => {
  const [areInputsMounted, setAreInputsMounted] = useState(true);
  const [editedImageIndex, setEditedImageIndex] = useState(0);
  const { onChangeImage } = props;

  useEffect(
    function remountInput() {
      if (!areInputsMounted) {
        setAreInputsMounted(true);
      }
    },
    [areInputsMounted]
  );

  const clickOrDropFile = (index: number): void => {
    setEditedImageIndex(index);
  };

  const processAcceptedFile = useCallback(
    async function processAcceptedFile(acceptedFiles: File[], index: number) {
      if (!acceptedFiles.length) {
        return;
      }

      setAreInputsMounted(false);

      const base64 = await generateBase64(acceptedFiles[0]);

      onChangeImage(base64, new File([acceptedFiles[0]], uuidv4()), index);
    },
    [onChangeImage]
  );

  const processAcceptedFileMemoised = useMemo(() => {
    return (acceptedFiles: File[]): void => {
      processAcceptedFile(acceptedFiles, editedImageIndex);
    };
  }, [editedImageIndex, processAcceptedFile]);

  return (
    <Box marginBottom={3} data-testid="stimulus-items">
      {props.items.map((item, index) => (
        <Box
          key={index}
          marginBottom={index < props.items.length - 1 ? 2 : 0}
          data-testid="stimulus-item"
        >
          {props.isEditing && (
            <>
              {item.type === "htmlText" && (
                <Editor
                  onEditorChange={(value: string) => {
                    props.onChange(value, index);
                  }}
                  testId={`stimulus-item-${index}-editor`}
                  value={props.items[index].content}
                />
              )}
              {item.type === "image" && (
                <Box
                  marginBottom={1}
                  marginX="auto"
                  maxWidth="75%"
                  onClick={() => clickOrDropFile(index)}
                  onDrop={() => clickOrDropFile(index)}
                  data-testid="stimulus-item-image-dropzone-container"
                >
                  <Box textAlign="center">
                    {areInputsMounted && (
                      <Dropzone
                        accept="image/jpeg, image/png"
                        receivedFilesCallback={processAcceptedFileMemoised}
                        isUploading={false}
                        maxFiles={1}
                      >
                        <Box clone maxWidth={1}>
                          <img src={props.items[index].content} />
                        </Box>
                      </Dropzone>
                    )}
                  </Box>
                </Box>
              )}
            </>
          )}
          {!props.isEditing && (
            <>
              {item.type === "htmlText" && (
                <Typography data-testid="stimulus-item-content">
                  <MathJax
                    math={item.content}
                    sanitizeOptions={{
                      ADD_TAGS: ["b", "i", "sub", "sup", "u"]
                    }}
                    wrapperTag="span"
                  />
                </Typography>
              )}
              {item.type === "image" && (
                <Box textAlign="center">
                  <Box clone maxWidth="70%">
                    <img src={item.content} data-testid="stimulus-item-image" />
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default StimulusItems;
