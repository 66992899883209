// Core
import React, { FC, ReactElement, useState } from "react";

// Utils
import { useApi } from "utils/context";

// Vendor
import {
  logos,
  ProductHeader,
  ProductHeaderLogo,
  ProductTitleText,
  UserMenu
} from "@cambridgeassessment/cambridge-ui";
import { Box, Container, Menu, MenuItem } from "@material-ui/core";
import { useAuth } from "oidc-react";
import { useHistory, useLocation } from "react-router-dom";

interface Props {
  children: ReactElement | ReactElement[];
}

const Layout: FC<Props> = (props): ReactElement => {
  const { setToken, setUser, user } = useApi();
  const auth = useAuth();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [userMenuAnchorElement, setUserMenuAnchorElement] = useState(
    null as HTMLElement | null
  );
  const history = useHistory();
  const location = useLocation();

  const clickLogout = (): void => {
    setUser(null);
    setToken("");
    auth?.signOut();
    setIsUserMenuOpen(false);
    history.push("/");
  };

  const clickUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setIsUserMenuOpen(!isUserMenuOpen);
    setUserMenuAnchorElement(event.currentTarget);
  };

  const isTaskPage = location.pathname.includes("/tasks/");

  return (
    <>
      {!isTaskPage && (
        <ProductHeader>
          <ProductHeaderLogo>
            <logos.CambridgeAssessmentLogo />
          </ProductHeaderLogo>
          <Box
            alignItems="center"
            display="flex"
            onClick={() => history.push("/")}
            data-testid="application-title"
          >
            <ProductTitleText productName="Content Management Tool" />
          </Box>
          {user && (
            <Box marginLeft="auto" data-testid="user-menu-container">
              <UserMenu
                aria-controls="user-menu-dropdown"
                aria-haspopup="true"
                id="1"
                name={user.name}
                onClick={clickUserMenu}
                open={isUserMenuOpen}
                role={user.role}
              >
                <Menu
                  anchorEl={userMenuAnchorElement}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  data-testid="user-menu-dropdown"
                  getContentAnchorEl={null}
                  id="user-menu-dropdown"
                  keepMounted
                  onClose={() => {
                    setIsUserMenuOpen(false);
                  }}
                  open={isUserMenuOpen}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                >
                  <MenuItem
                    onClick={clickLogout}
                    data-testid="logout-menu-item"
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </UserMenu>
            </Box>
          )}
        </ProductHeader>
      )}

      <Container maxWidth={false}>
        <Box marginTop={isTaskPage ? 0 : 6} marginBottom={6}>
          {props.children}
        </Box>
      </Container>
    </>
  );
};

export default Layout;
