// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ApproverTask from "pages/examinerDashboard/components/approverTask/ApproverTask";
import SubjectExpertTask from "pages/examinerDashboard/components/subjectExpertTask/SubjectExpertTask";

// Interfaces
import { Task } from "interfaces";

// Utils
import { useApi } from "utils/context";

// Vendor
import { Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const ExaminerDashboard: FC = (): ReactElement => {
  const { getTasks, user } = useApi();
  const [tasks, setTasks] = useState([] as Task[]);
  const history = useHistory();

  useEffect(() => {
    getTasks<Task[]>().then((response) => {
      setTasks(getActiveTasks(response.data || []));
    }, console.error);
  }, [getTasks]);

  useEffect(() => {
    if (!user || user.role !== "examiner") {
      history.push("/");
    }
  }, [history, user]);

  const clickStartApproverTask = (
    taskId: string,
    questionKey?: string
  ): void => {
    history.push(`/approver/tasks/${taskId}/questions/${questionKey}`);
  };

  const clickStartSubjectExpertTask = (
    taskId: string,
    questionKey?: string
  ): void => {
    history.push(`/subject-expert/tasks/${taskId}/questions/${questionKey}`);
  };

  const getActiveTasks = (items: Task[]): Task[] => {
    const activeApproverTasks = items.filter(
      (item) =>
        item.type === "approver" &&
        item.completedQuestions.length < item.project.items
    );
    const activeSubjectExpertTasks = items.filter(
      (item) =>
        item.type === "subject-expert" && item.pendingQuestions.length > 0
    );

    return activeApproverTasks.concat(activeSubjectExpertTasks);
  };

  return (
    <div data-testid="examiner-dashboard-page">
      <Box marginBottom={4}>
        <Typography variant="h3" data-testid="page-heading">
          Your tasks
        </Typography>
      </Box>
      <Box data-testid="tasks">
        {tasks.length === 0 && (
          <Typography>
            You don&rsquo;t have any tasks assigned to you.
          </Typography>
        )}
        {tasks.map((task) => (
          <React.Fragment key={task.id}>
            {task.type === "approver" && (
              <ApproverTask
                clickStart={clickStartApproverTask}
                key={task.id}
                task={task}
              />
            )}
            {task.type === "subject-expert" && (
              <SubjectExpertTask
                clickStart={clickStartSubjectExpertTask}
                key={task.id}
                task={task}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </div>
  );
};

export default ExaminerDashboard;
