// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Box, Typography } from "@material-ui/core";
import { Save } from "@material-ui/icons";

interface Props {
  clickBank: () => void;
  clickReject: () => void;
  isBankDisabled: boolean;
}

const ApprovedHeader: FC<Props> = (props): ReactElement => {
  return (
    <Box
      alignItems="center"
      display="flex"
      width={1}
      data-testid="approved-header"
    >
      <Box>
        <Box marginBottom={1}>
          <Typography component="h3" variant="h5">
            Add info and bank the item
          </Typography>
        </Box>
        <Box marginBottom={3}>
          <Typography>
            Because this item was rejected by the Subject Expert, no item info
            was added.
          </Typography>
        </Box>
        <Typography>
          Please select the relevant topic, skill, difficulty and add more
          information (if needed) before banking it.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" marginLeft="auto">
        <Box display="flex" flexDirection="row" marginBottom={2}>
          <Box marginRight={2}>
            <Button
              color="destructive"
              disableElevation
              onClick={props.clickReject}
              variant="outlined"
              data-testid="reject-button"
            >
              Reject this item
            </Button>
          </Box>
          <Button
            color="primary"
            disableElevation
            disabled={props.isBankDisabled}
            onClick={props.clickBank}
            startIcon={<Save />}
            data-testid="bank-button"
          >
            Bank this item
          </Button>
        </Box>
        <Box clone textAlign="right">
          <Typography style={{ fontWeight: 600 }}>
            Please note that these actions are irreversible
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ApprovedHeader;
