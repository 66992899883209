// Core
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from "react";

// Components
import Job from "components/job/Job";
import Summary from "pages/projects/uploads/components/Summary/Summary";

// Interfaces
import { Job as JobInterface, Project } from "interfaces";

// Utils
import { useApi, useProject } from "utils/context";
import { useInterval } from "utils/useInterval";

// Vendor
import { Button, colours, Divider } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const Uploads: FC = (): ReactElement => {
  const { getJobsByProject, getProject } = useApi();
  const { fetchProjectSuccess, project } = useProject();
  const [failedJobs, setFailedJobs] = useState([] as JobInterface[]);
  const [finishedJobs, setFinishedJobs] = useState([] as JobInterface[]);
  const [sentForHarvestingJobs, setSentForHarvestingJobs] = useState(
    [] as JobInterface[]
  );
  const [shouldCancelInterval, setShouldCancelInterval] = useState(false);
  const history = useHistory();
  const useStyles = makeStyles({
    failedJobs: {
      color: colours.pdfRed
    },
    harvestingProgressSpinner: {
      marginRight: 10
    }
  });
  const classes = useStyles();

  const getJobsAndProject = useCallback(
    (projectId: string) => {
      if (!projectId) {
        return;
      }

      Promise.all([
        getJobsByProject<JobInterface[]>(projectId, { status: "failed" }),
        getJobsByProject<JobInterface[]>(projectId, { status: "finished" }),
        getJobsByProject<JobInterface[]>(projectId, {
          status: "sentForHarvesting"
        }),
        getProject<Project>(projectId)
      ])
        .then((response) => {
          setFailedJobs(response[0].data || []);
          setFinishedJobs(response[1].data || []);
          setSentForHarvestingJobs(response[2].data || []);
          fetchProjectSuccess(response[3].data || ({} as Project));
        })
        .catch(() => setShouldCancelInterval(true));
    },
    [fetchProjectSuccess, getJobsByProject, getProject]
  );

  useEffect(() => {
    getJobsAndProject(project.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(
    getJobsAndProject,
    shouldCancelInterval ? null : 5000,
    project.id
  );

  const clickContinue = (): void => {
    history.push(`/projects/${project.id}/edit/subject-experts`);
  };

  return (
    <div data-testid="uploads-page">
      <Box display="flex" marginBottom={4}>
        <Box>
          <Box marginBottom={1}>
            <Typography component="h2" variant="h4" data-testid="page-heading">
              Item harvesting
            </Typography>
          </Box>
          <Typography data-testid="page-introduction">
            Extracting assessment content from the question papers and mark
            schemes below
          </Typography>
        </Box>
        <Box marginLeft="auto">
          <Button
            color="primary"
            disableElevation
            disabled={
              (failedJobs.length === 0 &&
                finishedJobs.length === 0 &&
                sentForHarvestingJobs.length === 0) ||
              finishedJobs.length === 0 ||
              sentForHarvestingJobs.length > 0
            }
            onClick={clickContinue}
            data-testid="continue-button"
          >
            Continue
          </Button>
        </Box>
      </Box>
      <Box marginBottom={6}>
        <Divider />
        {project.items > 0 && (
          <>
            <Summary jobs={finishedJobs} items={project.items} />
            <Divider />
          </>
        )}
        {failedJobs.length > 0 && (
          <>
            <Box alignItems="center" display="flex" marginY={4}>
              <Box clone marginRight={1}>
                <Clear htmlColor={colours.pdfRed} />
              </Box>
              <Typography
                className={classes.failedJobs}
                data-testid="failed-jobs"
              >
                {failedJobs.length}{" "}
                {failedJobs.length === 1 ? "paper" : "papers"} failed:{" "}
                {failedJobs.map(
                  (failedJob, index) =>
                    `${failedJob.index} (${failedJob.error?.message})${
                      index === failedJobs.length - 1 ? "" : ", "
                    }`
                )}
              </Typography>
            </Box>
            <Divider />
          </>
        )}
        {sentForHarvestingJobs.length > 0 && (
          <>
            <Box alignItems="center" display="flex" marginY={4}>
              <Box clone marginRight={1}>
                <CircularProgress
                  className={classes.harvestingProgressSpinner}
                  color="inherit"
                  size={18}
                />
              </Box>
              <Typography data-testid="sent-for-harvesting-progress">
                {sentForHarvestingJobs.length}{" "}
                {sentForHarvestingJobs.length === 1 ? "paper is" : "papers are"}{" "}
                in progress...
              </Typography>
            </Box>
            <Divider />
          </>
        )}
      </Box>
      <Box marginBottom={4}>
        {finishedJobs.map((finishedJob, index) => (
          <Job
            detail={true}
            job={finishedJob}
            key={finishedJob.index}
            last={index === finishedJobs.length - 1}
          />
        ))}
      </Box>
      {sentForHarvestingJobs.map((sentForHarvestingJob, index) => (
        <Job
          chipLabel="Harvesting..."
          job={sentForHarvestingJob}
          key={sentForHarvestingJob.index}
          last={index === sentForHarvestingJobs.length - 1}
        />
      ))}
    </div>
  );
};

export default Uploads;
