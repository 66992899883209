// Core
import React, { FC, ReactElement } from "react";

// Components
import LinearProgress from "components/linearProgress/LinearProgress";

// Interfaces
import { Project } from "interfaces";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";

interface Props {
  project: Project;
}

const ApproversCard: FC<Props> = (props): ReactElement => {
  let completedPercentage = Math.round(
    ((props.project.bankedItems + props.project.rejectedItems) /
      props.project.receivedItems) *
      100
  );
  const sharedClasses = sharedStyles();

  if (isNaN(completedPercentage)) {
    completedPercentage = 0;
  }

  return (
    <Card data-testid="approvers-card">
      <CardContent>
        <Grid container alignItems="center" spacing={4}>
          <Grid item md={3}>
            <Box marginBottom={1}>
              <Typography
                className={sharedClasses.fadedText}
                component="h4"
                variant="subtitle1"
              >
                Approvers
              </Typography>
            </Box>
            <Typography
              component="p"
              variant="h5"
              data-testid="approvers"
              style={{ wordBreak: "break-word" }}
            >
              {props.project.approvers.map(
                (approver, index) =>
                  `${approver.email}${
                    index === props.project.approvers.length - 1 ? "" : ", "
                  }`
              )}
            </Typography>
          </Grid>
          <Grid item md={2}>
            <Box marginBottom={1}>
              <Typography
                className={sharedClasses.fadedText}
                component="h4"
                variant="subtitle1"
              >
                Completed items
              </Typography>
            </Box>
            <Typography
              component="p"
              variant="h5"
              data-testid="completed-items"
            >
              {props.project.bankedItems + props.project.rejectedItems} /{" "}
              {props.project.items}
            </Typography>
          </Grid>
          <Grid item md={7}>
            <Box alignItems="center" display="flex">
              <Box marginRight={4} style={{ width: "calc(100% - 60px)" }}>
                <LinearProgress
                  value={completedPercentage}
                  variant="determinate"
                />
              </Box>
              <Box display="flex" minWidth={60}>
                <Box marginRight={1}>
                  <Typography component="p" variant="h5">
                    {completedPercentage}
                  </Typography>
                </Box>
                <Typography display="inline">%</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ApproversCard;
