// Core
import React, { FC, ReactElement } from "react";

// Components
import LinearProgress from "components/linearProgress/LinearProgress";

// Interface
import { Task as TaskInterface } from "interfaces";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import { Button, colours, Divider } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography
} from "@material-ui/core";
import { PlayArrow, WatchLater } from "@material-ui/icons";
import dayjs from "dayjs";

interface Props {
  clickStart: (taskId: string, questionKey?: string) => void;
  task: TaskInterface;
}

const SubjectExpertTask: FC<Props> = (props): ReactElement => {
  const completedQuestionsCount = props.task.completedQuestions.length;
  const totalQuestionsCount =
    props.task.completedQuestions.length + props.task.pendingQuestions.length;
  let completedPercentage = Math.round(
    (completedQuestionsCount / totalQuestionsCount) * 100
  );
  const sharedClasses = sharedStyles();

  if (isNaN(completedPercentage)) {
    completedPercentage = 0;
  }

  return (
    <Box marginBottom={4}>
      <Card data-testid="subject-expert-task">
        <CardContent>
          <Box marginBottom={5}>
            <Box marginBottom={1}>
              <Typography
                component="h2"
                variant="h4"
                data-testid="project-name"
              >
                {props.task.project.name}
              </Typography>
            </Box>
            <Typography
              className={sharedClasses.fadedText}
              component="h2"
              variant="h5"
            >
              Subject expert
            </Typography>
          </Box>
          <Box marginBottom={5}>
            <Grid container>
              <Grid item xs={12} lg={4}>
                <Typography data-testid="instructions">
                  {props.task.project.instructions}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" marginBottom={4}>
            <Box marginRight={8}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Completed items
                </Typography>
              </Box>
              <Typography
                component="p"
                variant="h5"
                data-testid="completed-items"
              >
                {completedQuestionsCount} / {totalQuestionsCount}
              </Typography>
            </Box>
            <Divider flexItem orientation="vertical" />
            <Box marginLeft={8}>
              <Box alignItems="center" display="flex" marginBottom={1}>
                <Box marginRight={1}>
                  <Typography
                    className={sharedClasses.fadedText}
                    component="h3"
                    variant="subtitle1"
                  >
                    Due date
                  </Typography>
                </Box>
                <WatchLater
                  fontSize="small"
                  htmlColor={colours.monochromeMid}
                />
              </Box>
              <Typography
                component="p"
                variant="h5"
                data-testid="target-end-date"
              >
                {props.task.project.targetEndDate
                  ? dayjs(
                      props.task.project.targetEndDate.split("T")[0]
                    ).format("DD/MM/YYYY")
                  : "None"}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box alignItems="center" display="flex" marginTop={4}>
            <Box width="100%" marginRight={4}>
              <LinearProgress
                value={completedPercentage}
                variant="determinate"
              />
            </Box>
            <Box display="flex" minWidth={35}>
              <Box marginRight={1}>
                <Typography component="p" variant="h4">
                  {completedPercentage}
                </Typography>
              </Box>
              <Typography display="inline">%</Typography>
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            disableElevation
            onClick={() =>
              props.clickStart(props.task.id, props.task.pendingQuestions[0])
            }
            startIcon={<PlayArrow />}
            data-testid="start-button"
          >
            Start this task
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default SubjectExpertTask;
