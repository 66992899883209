// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Vendor
import { Button, Divider, TextField } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography
} from "@material-ui/core";

interface CheckboxInterface {
  testId: string;
  value: string;
}

interface FormFields {
  additionalComment: string;
  rejectionReasons: string[];
}

interface Props {
  clickConfirmReject: (body: {
    rejectionExplanation?: string;
    rejectionReasons: string[];
  }) => void;
  isOpen: boolean;
  setIsRejectDialogueOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const checkboxes = [
  {
    testId: "missing-or-incorrect-text-checkbox",
    value: "Missing or incorrect text (e.g. words, letters)"
  },
  {
    testId: "missing-or-incorrect-symbols-checkbox",
    value: "Missing or incorrect symbols (e.g. arrows, Greek letters)"
  },
  {
    testId: "missing-or-incorrect-styling-checkbox",
    value: "Missing or incorrect styling (e.g. bold, italics, super/subscripts)"
  },
  {
    testId: "missing-incorrect-or-poor-quality-images-checkbox",
    value: "Missing, incorrect or poor quality images"
  },
  {
    testId: "other-checkbox",
    value: "Other"
  }
];

const RejectDialogue: FC<Props> = (props): ReactElement => {
  const [formFields, setFormFields] = useState({
    additionalComment: "",
    rejectionReasons: []
  } as FormFields);
  const [
    isAdditionalCommentMinLength,
    setIsAdditionalCommentMinLength
  ] = useState(true);

  useEffect(() => {
    setIsAdditionalCommentMinLength(
      formFields.additionalComment
        ? formFields.additionalComment.length > 2
        : true
    );
  }, [formFields.additionalComment]);

  const changeAdditionalComment = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormFields({
      ...formFields,
      additionalComment: event.target.value
    });
  };

  const changeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setFormFields({
      ...formFields,
      rejectionReasons: event.target.checked
        ? [...formFields.rejectionReasons, value]
        : formFields.rejectionReasons.filter((reason) => reason !== value)
    });
  };

  const clickClose = (): void => {
    setFormFields({
      ...formFields,
      additionalComment: "",
      rejectionReasons: []
    });

    props.setIsRejectDialogueOpen(false);
  };

  const clickReject = (): void => {
    const body = {
      rejectionReasons: formFields.rejectionReasons
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    if (formFields.additionalComment) {
      body["rejectionExplanation"] = formFields.additionalComment;
    }

    props.clickConfirmReject(body);
    clickClose();
  };

  const renderCheckbox = (checkbox: CheckboxInterface): ReactElement => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={formFields.rejectionReasons.includes(checkbox.value)}
            color="primary"
            inputProps={
              {
                "aria-checked": formFields.rejectionReasons.includes(
                  checkbox.value
                ),
                "data-testid": checkbox.testId
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any
            }
            onChange={(event) => changeCheckbox(event)}
            name={checkbox.testId}
            value={checkbox.value}
          />
        }
        key={checkbox.testId}
        label={checkbox.value}
      />
    );
  };

  return (
    <Dialog
      onClose={clickClose}
      aria-labelledby="reject-dialogue-heading"
      open={props.isOpen}
      data-testid="reject-dialogue"
    >
      <Box padding={4}>
        <Box marginBottom={3}>
          <Typography
            component="h2"
            variant="h4"
            data-testid="reject-dialogue-heading"
          >
            Select the reasons for rejecting this item
          </Typography>
        </Box>
        <Box marginBottom={3}>
          <FormControl component="fieldset">
            <FormGroup>
              {checkboxes.map((checkbox) => renderCheckbox(checkbox))}
            </FormGroup>
          </FormControl>
        </Box>
        <Box marginBottom={3}>
          <Box marginBottom={1}>
            <Box display="inline">
              <Typography display="inline" style={{ fontWeight: 600 }}>
                Additional comment
              </Typography>
            </Box>{" "}
            <Typography display="inline">
              (
              {formFields.rejectionReasons.includes("Other")
                ? "Required"
                : "Optional"}
              )
            </Typography>
          </Box>
          <TextField
            helperText={
              !isAdditionalCommentMinLength
                ? "Must be at least three characters long"
                : undefined
            }
            inputProps={{
              "data-testid": "additional-comment-input"
            }}
            multiline={true}
            onChange={changeAdditionalComment}
            validationType={!isAdditionalCommentMinLength ? "error" : undefined}
            value={formFields.additionalComment}
          />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="flex-end" marginTop={3}>
          <Box clone marginRight={2}>
            <Button
              color="default"
              onClick={clickClose}
              variant="text"
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Box>
          <Button
            color={
              `${
                !isAdditionalCommentMinLength ||
                !formFields.rejectionReasons.length ||
                (formFields.rejectionReasons.includes("Other") &&
                  !formFields.additionalComment)
                  ? "default"
                  : "destructive"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              }` as any
            }
            disableElevation
            disabled={
              !isAdditionalCommentMinLength ||
              !formFields.rejectionReasons.length ||
              (formFields.rejectionReasons.includes("Other") &&
                !formFields.additionalComment)
            }
            onClick={clickReject}
            data-testid="reject-button"
            variant="contained"
          >
            Reject this item
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RejectDialogue;
