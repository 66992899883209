// Core
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useReducer
} from "react";

type Action = { type: string; payload: unknown };
type Dispatch = (action: Action) => void;
type SkillContextProps = { children: ReactNode };
type State = {
  hasError: boolean;
  isFetching: boolean;
  skills: string[];
};

const initialState = {
  hasError: false,
  isFetching: false,
  skills: [] as string[]
};
const SkillDispatchContext = createContext<Dispatch | undefined>(undefined);
const SkillStateContext = createContext<State | undefined>(undefined);

interface UseSkill {
  fetchSkillsRequest: () => void;
  fetchSkillsSuccess: (skills: string[]) => void;
  skills: string[];
}

const skillReducer = (state: State, action: Action): State => {
  if (action.type === "FETCH_SKILLS_FAILURE") {
    return {
      ...state,
      hasError: true,
      isFetching: false
    };
  }

  if (action.type === "FETCH_SKILLS_REQUEST") {
    return {
      ...state,
      hasError: false,
      isFetching: true
    };
  }

  if (action.type === "FETCH_SKILLS_SUCCESS") {
    return {
      ...state,
      skills: action.payload as string[]
    };
  }

  return state;
};

const SkillContext = ({ children }: SkillContextProps): ReactElement => {
  const [state, dispatch] = useReducer(skillReducer, initialState);

  return (
    <SkillStateContext.Provider value={state}>
      <SkillDispatchContext.Provider value={dispatch}>
        {children}
      </SkillDispatchContext.Provider>
    </SkillStateContext.Provider>
  );
};

const useSkillDispatch = (): Dispatch => {
  const context = useContext(SkillDispatchContext);

  if (context === undefined) {
    throw new Error("useSkillDispatch must be used within a SkillContext");
  }

  return context;
};

const useSkillState = (): State => {
  const context = useContext(SkillStateContext);

  if (context === undefined) {
    throw new Error("useSkillState must be used within a SkillContext");
  }

  return context;
};

const useSkill = (): UseSkill => {
  const dispatch = useSkillDispatch();
  const { skills } = useSkillState();

  const fetchSkillsRequest = useCallback<UseSkill["fetchSkillsRequest"]>(() => {
    dispatch({ type: "FETCH_SKILLS_REQUEST", payload: "" });
  }, [dispatch]);

  const fetchSkillsSuccess = useCallback<UseSkill["fetchSkillsSuccess"]>(
    (value) => {
      dispatch({ type: "FETCH_SKILLS_SUCCESS", payload: value });
    },
    [dispatch]
  );

  return {
    fetchSkillsRequest,
    fetchSkillsSuccess,
    skills
  };
};

export { SkillContext, useSkill };
