// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ChoiceInteraction from "components/qti/choiceInteraction/ChoiceInteraction";
import PdfPreview from "components/pdfPreview/PdfPreview";
import QuestionInfo from "components/questionInfo/QuestionInfo";
import RejectionReasons from "pages/projects/completed/components/rejectionReasons/RejectionReasons";

// Interfaces
import { Question } from "interfaces";

// Utils
import { useApi, useProject } from "utils/context";
import { spaceCamelCaseString } from "utils/string";
import { sharedStyles } from "utils/theme";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Typography
} from "@material-ui/core";
import { Check, ChevronLeft, Close, Visibility } from "@material-ui/icons";
import LazyLoad from "react-lazyload";
import { useHistory, useParams } from "react-router-dom";

const Completed: FC = (): ReactElement => {
  const { getQuestions } = useApi();
  const { project } = useProject();
  const [questions, setQuestions] = useState([] as Question[]);
  const [visiblePdfs, setVisiblePdfs] = useState([] as string[]);
  const history = useHistory();
  const { status } = useParams<Record<string, string>>();
  const sharedClasses = sharedStyles();

  useEffect(() => {
    if (!Object.keys(project).length) {
      return;
    }

    getQuestions<Question[]>(project.id, {
      status: status === "banked" ? status : "rejectedByApprover",
      select:
        "additionalInformation,content,difficulty,figures,job,key,metadata,page,project,ragStatus,rejectionExplanation,rejectionReasons,status,topics,skills",
      images: "true",
      pdfs: "true",
      ragStatus: "A,G"
    }).then((response) => {
      setQuestions(response.data || []);
    }, console.error);
  }, [getQuestions, project, status]);

  const clickBack = (): void => {
    history.push(`/projects/${project.id}`);
  };

  const clickToggleViewOriginalItem = (questionKey: string): void => {
    setVisiblePdfs(
      visiblePdfs.includes(questionKey)
        ? visiblePdfs.filter((key) => key !== questionKey)
        : [...visiblePdfs, questionKey]
    );
  };

  const renderQuestion = (question: Question): ReactElement => {
    if (!question || !question.content) {
      return <></>;
    }

    if (question.content.qtiInteractionType === "choiceInteraction") {
      return <ChoiceInteraction content={question.content} isEditing={false} />;
    } else {
      return <></>;
    }
  };

  return (
    <div data-testid={`${status}-page`}>
      <Box
        clone
        className={sharedClasses.bleed}
        marginTop="-47px"
        marginBottom={6}
        paddingY={5}
      >
        <Paper elevation={0}>
          <Box clone marginBottom={3}>
            <Button
              color="primary"
              onClick={clickBack}
              data-testid="back-button"
              startIcon={<ChevronLeft />}
              variant="text"
            >
              Back to project details
            </Button>
          </Box>
          <Box alignItems="center" display="flex" marginBottom={4}>
            <Box marginRight={6}>
              <Box marginBottom={1}>
                <Typography
                  component="h2"
                  variant="h3"
                  data-testid="project-name"
                >
                  {project.name}
                </Typography>
              </Box>
              <Typography
                component="h3"
                variant="h4"
                data-testid={`${status}-items`}
              >
                {spaceCamelCaseString(status)} items ({questions.length})
              </Typography>
            </Box>
            <Box clone marginRight={2}>
              <Chip
                color="primary"
                icon={<Check fontSize="small" />}
                label={`Completed on ${project.completionDate}`}
                variant="outlined"
                data-testid="completion-date"
              />
            </Box>
            <Chip
              color={status === "banked" ? "primary" : "secondary"}
              label={`${spaceCamelCaseString(status)} items`}
              variant="outlined"
            />
          </Box>
        </Paper>
      </Box>
      <Grid container spacing={10}>
        {questions.map((question, index) => (
          <React.Fragment key={question.key}>
            <Grid item xs={6}>
              <LazyLoad once>{renderQuestion(question)}</LazyLoad>
            </Grid>
            <Grid item xs={6}>
              <LazyLoad once>
                {visiblePdfs.includes(question.key) && (
                  <>
                    <Box alignItems="center" display="flex" marginBottom={2}>
                      <Box>
                        <Typography component="h2" variant="h5">
                          Original item
                        </Typography>
                      </Box>
                      <Box clone marginLeft="auto">
                        <IconButton
                          aria-label="close"
                          onClick={() =>
                            clickToggleViewOriginalItem(question.key)
                          }
                          data-testid="close-original-item-button"
                        >
                          <Close />
                        </IconButton>
                      </Box>
                    </Box>
                    <PdfPreview url={question.pdfUrl} />
                  </>
                )}
                {!visiblePdfs.includes(question.key) && (
                  <>
                    <Box clone marginBottom={5}>
                      <Button
                        color="primary"
                        onClick={() =>
                          clickToggleViewOriginalItem(question.key)
                        }
                        data-testid="view-original-item-button"
                        startIcon={<Visibility />}
                        variant="text"
                      >
                        View original item
                      </Button>
                    </Box>
                    {status === "banked" && (
                      <QuestionInfo isEditing={false} question={question} />
                    )}
                    {status === "rejected" && (
                      <RejectionReasons
                        rejectionExplanation={question.rejectionExplanation}
                        rejectionReasons={
                          (question.rejectionReasons as string[]) || []
                        }
                      />
                    )}
                  </>
                )}
              </LazyLoad>
            </Grid>
            {index !== questions.length - 1 && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </div>
  );
};

export default Completed;
