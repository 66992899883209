// Core
import React, { FC, ReactElement } from "react";

// Interfaces
import { Project } from "interfaces";

// Utils
import { createThemeOptions } from "utils/theme";

// Vendor
import { Button, themeOptions } from "@cambridgeassessment/cambridge-ui";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";

interface Props {
  approvers: Project["approvers"];
  clickDelete: (email: string) => void;
}

const ApproversTable: FC<Props> = (props): ReactElement => {
  const baseThemeOptions = createThemeOptions(themeOptions);

  const getMuiTheme = () =>
    createMuiTheme({
      ...baseThemeOptions,
      overrides: {
        ...baseThemeOptions.overrides,
        MuiTableCell: {
          ...baseThemeOptions.overrides?.MuiTableCell,
          body: {
            width: "50%"
          }
        }
      }
    });

  const columns = [
    {
      name: "email",
      label: "Approver",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "email",
      label: "Actions",
      options: {
        empty: true,
        filter: false,
        sort: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value: string): ReactElement => {
          return (
            <Button
              color="primary"
              onClick={() => props.clickDelete(value)}
              variant="text"
              data-testid="delete-button"
            >
              Remove approver
            </Button>
          );
        }
      }
    }
  ];

  const options: MUIDataTableOptions = {
    customToolbar: null,
    download: false,
    filter: false,
    pagination: false,
    print: false,
    responsive: "vertical",
    search: false,
    selectableRows: "none",
    selectToolbarPlacement: "none",
    viewColumns: false
    // TODO: I have to do this because the @types for mui-datables are out of date, and the valid customToolbar: null declaration above is reported as an error. When the types are updated, we can remove this casting. IH 24/02/21
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;

  return (
    <div data-testid="approvers-table">
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          columns={columns}
          data={props.approvers}
          options={options}
          title={""}
        />
      </MuiThemeProvider>
    </div>
  );
};

export default ApproversTable;
