// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Box, Chip, Typography } from "@material-ui/core";
import { Save } from "@material-ui/icons";

interface Props {
  clickAccept: () => void;
  clickBank: () => void;
  clickReject: () => void;
  clickSaveInfo: () => void;
  isAccepted: boolean;
  isEditingInfo: boolean;
  setIsEditingInfo: React.Dispatch<React.SetStateAction<boolean>>;
}

const MetadataAddedHeader: FC<Props> = (props): ReactElement => {
  return (
    <Box
      alignItems="center"
      display="flex"
      width={1}
      data-testid="metadata-added-header"
    >
      {props.isAccepted && (
        <Box>
          <Box clone marginBottom={3}>
            <Chip color="primary" label="Accepted item" variant="outlined" />
          </Box>
          <Box marginBottom={1}>
            <Typography component="h3" variant="h5">
              (2/2) Check the item information
            </Typography>
          </Box>
          <Typography>
            Check the data added by the subject expert, then bank this item.
            <br />
            You can make changes before banking it.
          </Typography>
        </Box>
      )}
      {!props.isAccepted && (
        <Box>
          <Box clone marginBottom={3}>
            <Chip color="primary" label="Accepted item" variant="outlined" />
          </Box>
          <Box marginBottom={1}>
            <Typography component="h3" variant="h5">
              (1/2) Check the item
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography>
              This item was accepted by the subject expert.
            </Typography>
          </Box>
          <Typography>
            Check if the extracted item matches the original item, and either
            reject or accept the item.
          </Typography>
        </Box>
      )}
      {props.isEditingInfo && (
        <Box display="flex" marginLeft="auto">
          <Box marginRight={2}>
            <Button
              color="primary"
              disableElevation
              onClick={() => props.setIsEditingInfo(false)}
              variant="outlined"
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Box>
          <Button
            color="primary"
            disableElevation
            onClick={props.clickSaveInfo}
            data-testid="save-button"
          >
            Save changes
          </Button>
        </Box>
      )}
      {!props.isEditingInfo && (
        <Box display="flex" flexDirection="column" marginLeft="auto">
          <Box display="flex" flexDirection="row" marginBottom={2}>
            <Box marginRight={2}>
              <Button
                color="destructive"
                disableElevation
                onClick={props.clickReject}
                variant="outlined"
                data-testid="reject-button"
              >
                Reject this item
              </Button>
            </Box>
            {props.isAccepted && (
              <Button
                color="primary"
                disableElevation
                onClick={props.clickBank}
                startIcon={<Save />}
                data-testid="bank-button"
              >
                Bank this item
              </Button>
            )}
            {!props.isAccepted && (
              <Button
                color="primary"
                disableElevation
                onClick={props.clickAccept}
                data-testid="accept-button"
              >
                Accept this item
              </Button>
            )}
          </Box>
          <Box clone textAlign="right">
            <Typography style={{ fontWeight: 600 }}>
              Please note that{" "}
              {props.isAccepted ? "these actions are" : "rejection is"}{" "}
              irreversible
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MetadataAddedHeader;
