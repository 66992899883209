// Core
import React, { FC, ReactElement } from "react";

// Components
import Editor from "components/editor/Editor";

// Interfaces
import { StimulusList as StimulusListInterface } from "interfaces";

// Vendor
import { Box, Typography } from "@material-ui/core";
import MathJax from "react-mathjax-preview";

interface Props {
  isEditing: boolean;
  list: StimulusListInterface[];
  onChange: (value: string, index: number) => void;
}

const StimulusList: FC<Props> = (props): ReactElement => {
  return (
    <>
      {!props.list && <></>}
      {props.list && (
        <Box marginBottom={3} data-testid="stimulus-list">
          {props.list.map((item, index) => (
            <Box display="flex" key={item.number} marginBottom={1}>
              <Box marginRight={2}>
                <Typography display="inline">{item.number + 1}</Typography>
              </Box>
              {props.isEditing && (
                <Editor
                  onEditorChange={(value: string) =>
                    props.onChange(value, index)
                  }
                  testId={`stimulus-list-${index}-editor`}
                  value={item.text}
                />
              )}
              {!props.isEditing && (
                <Typography>
                  <MathJax
                    math={item.text}
                    sanitizeOptions={{
                      ADD_TAGS: ["b", "i", "sub", "sup", "u"]
                    }}
                    wrapperTag="span"
                  />
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default StimulusList;
