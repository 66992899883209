// Core
import React, { FC, ReactElement } from "react";

// Components
import ProjectStepper from "components/projectStepper/ProjectStepper";

// Enums
import { ProjectType } from "enums";

// Utils
import { useProject } from "utils/context";
import { sharedStyles } from "utils/theme";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const EditProjectHeader: FC = (): ReactElement => {
  const { project } = useProject();
  const history = useHistory();
  const sharedClasses = sharedStyles();

  return (
    <Box marginTop="-47px" marginBottom={6} data-testid="edit-project-header">
      <Card
        className={`${sharedClasses.bleed} ${sharedClasses.heroHeaderCard}`}
        square
        variant="outlined"
      >
        <CardContent className={sharedClasses.heroHeaderCardContent}>
          <Box marginBottom={4}>
            <Button
              color="primary"
              startIcon={<ArrowBack />}
              onClick={() => history.push("/admin/dashboard")}
              variant="text"
              data-testid="back-button"
            >
              Back to dashboard
            </Button>
          </Box>
          <Box marginBottom={4}>
            <Box marginBottom={1}>
              <Typography
                component="h2"
                variant="h3"
                data-testid="project-name"
              >
                {project?.name}
              </Typography>
            </Box>
            <Typography variant="h4" data-testid="project-type">
              {ProjectType[project?.type]}
            </Typography>
          </Box>
          <ProjectStepper />
        </CardContent>
      </Card>
    </Box>
  );
};

export default EditProjectHeader;
