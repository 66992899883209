// Core
import React, { FC, ReactElement } from "react";

// Enums
import { ProjectType } from "enums";

// Interfaces
import { Project } from "interfaces";

// Utils
import { sharedStyles } from "utils/theme";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

interface Props {
  project: Project;
}

const InProgressProject: FC<Props> = (props): ReactElement => {
  const history = useHistory();
  const sharedClasses = sharedStyles();

  return (
    <Box clone marginBottom={4} data-testid="in-progress-project">
      <Card>
        <CardContent>
          <Box marginBottom={4}>
            <Box marginBottom={1}>
              <Typography
                component="h2"
                variant="h4"
                data-testid="project-name"
              >
                {props.project.name}
              </Typography>
            </Box>
            <Typography data-testid="project-type">
              {ProjectType[props.project.type]}
            </Typography>
          </Box>
          <Box display="flex">
            <Box marginRight={8}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Subject experts
                </Typography>
              </Box>
              <Typography
                component="p"
                variant="h5"
                data-testid="subject-experts"
              >
                {props.project.subjectExperts.length}
              </Typography>
            </Box>
            <Box marginRight={8}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Submitted items
                </Typography>
              </Box>
              <Typography
                component="p"
                variant="h5"
                data-testid="submitted-items"
              >
                {props.project.receivedItems} / {props.project.items}
              </Typography>
            </Box>
            <Divider flexItem orientation="vertical" />
            <Box marginLeft={8} marginRight={8}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Approver
                </Typography>
              </Box>
              <Typography component="p" variant="h5" data-testid="approvers">
                {props.project.approvers.map(
                  (approver, index) =>
                    `${approver.email}${
                      index === props.project.approvers.length - 1 ? "" : ", "
                    }`
                ) || []}
              </Typography>
            </Box>
            <Box>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Completed items
                </Typography>
              </Box>
              <Typography
                component="p"
                variant="h5"
                data-testid="completed-items"
              >
                {props.project.bankedItems + props.project.rejectedItems} /{" "}
                {props.project.items}
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            disableElevation
            onClick={() => history.push(`/projects/${props.project.id}`)}
            variant="contained"
            data-testid="track-progress-button"
          >
            Track progress
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default InProgressProject;
