export const getSyllabusAndVariation = (
  filename: string | undefined
): string => {
  if (!filename) {
    return "";
  }

  const split = filename.split("_");

  return `${split[0]} / ${split
    .slice(-1)[0]
    .slice(0, split.slice(-1)[0].indexOf("."))}`;
};

export const spaceCamelCaseString = (
  string: string,
  titleise?: boolean
): string => {
  if (!string) {
    return "";
  }

  const match = string.match(/([A-Z]?[^A-Z]*)/g);

  if (!match) {
    return string;
  }

  let stringArray = match.slice(0, -1);

  if (!titleise) {
    stringArray = stringArray.map(
      (item) => `${item.charAt(0).toLowerCase()}${item.slice(1)}`
    );
  }

  const stringWithSpaces = stringArray.join(" ");

  return `${stringWithSpaces.charAt(0).toUpperCase()}${stringWithSpaces.slice(
    1
  )}`;
};
