// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Box, Typography } from "@material-ui/core";

interface Props {
  clickSubmit: () => void;
  isSubmitDisabled: boolean;
}

const ApprovedHeader: FC<Props> = (props): ReactElement => {
  return (
    <Box display="flex" width={1} data-testid="approved-header">
      <Box>
        <Typography>
          (4/4) Please select the relevant topic, skill, difficulty and add more
          information if needed.
        </Typography>
      </Box>
      <Box marginLeft="auto">
        <Button
          color="primary"
          disableElevation
          disabled={props.isSubmitDisabled}
          onClick={props.clickSubmit}
          data-testid="submit-button"
        >
          Submit and continue
        </Button>
      </Box>
    </Box>
  );
};

export default ApprovedHeader;
