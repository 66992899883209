// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import { Box, Dialog, IconButton, Typography } from "@material-ui/core";
import { Close, Edit } from "@material-ui/icons";

interface Props {
  clickEdit: () => void;
  clickReject: () => void;
  isOpen: boolean;
  setIsRejectOrEditDialogueOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RejectOrEditDialogue: FC<Props> = (props): ReactElement => {
  const clickClose = (): void => {
    props.setIsRejectOrEditDialogueOpen(false);
  };

  const clickEdit = (): void => {
    clickClose();
    props.clickEdit();
  };

  const clickReject = (): void => {
    clickClose();
    props.clickReject();
  };

  return (
    <Dialog
      onClose={clickClose}
      aria-labelledby="reject-or-edit-dialogue-heading"
      open={props.isOpen}
      data-testid="reject-or-edit-dialogue"
    >
      <Box padding={4}>
        <Box marginBottom={3}>
          <Box alignItems="center" display="flex" marginBottom={1}>
            <Typography
              component="h2"
              variant="h4"
              data-testid="reject-or-edit-dialogue-heading"
            >
              Reject or edit this item
            </Typography>
            <Box clone marginLeft="auto">
              <IconButton
                aria-label="close"
                onClick={clickClose}
                data-testid="close-button"
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Typography>
            If the text and images do not match the original, edit them to
            match. If this is not possible, reject this item.
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="flex-end" marginTop={3}>
          <Box clone marginRight={2}>
            <Button
              color="primary"
              onClick={clickEdit}
              startIcon={<Edit />}
              variant="outlined"
              data-testid="edit-button"
            >
              Edit item
            </Button>
          </Box>
          <Button
            color="destructive"
            disableElevation
            onClick={clickReject}
            data-testid="reject-button"
            variant="contained"
          >
            Reject item
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RejectOrEditDialogue;
