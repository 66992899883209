// Core
import React, { FC, ReactElement } from "react";

interface Props {
  children: ReactElement;
  wrap: boolean;
  wrapper: (children: ReactElement) => ReactElement;
}

const ConditionalWrapper: FC<Props> = (props): ReactElement => {
  return <>{props.wrap ? props.wrapper(props.children) : props.children}</>;
};

export default ConditionalWrapper;
