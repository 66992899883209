// Core
import React, { ChangeEvent, FC, ReactElement, useState } from "react";

// Components
import SubjectExpertsTable from "pages/projects/subjectExperts/components/subjectExpertsTable/SubjectExpertsTable";
import UserOption from "components/userOption/UserOption";

// Interfaces
import { Project, User } from "interfaces";

// Utils
import { useApi, useProject } from "utils/context";
import { getAssignedItemsCount } from "utils/project";
import { sharedStyles } from "utils/theme";
import { useDebouncedEffect } from "utils/useDebouncedEffect";

// Vendor
import { Button, Divider, TextField } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Grid,
  InputAdornment,
  Typography,
  withStyles
} from "@material-ui/core";
import { Add, Group, RotateLeft, Search } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";

const CustomTextField = withStyles(() => ({
  root: {
    marginBottom: 0,
    "& > .MuiInputBase-root": {
      marginBottom: 0
    },
    "& input": {
      padding: "10px 16px !important"
    }
  }
}))(TextField);

const SubjectExperts: FC = (): ReactElement => {
  const { getUsers, updateProject } = useApi();
  const { project, updateProjectSuccess } = useProject();
  const [activeSubjectExpertOptions, setActiveSubjectExpertOptions] = useState(
    [] as User[]
  );
  const [formFields, setFormFields] = useState({
    subjectExpert: ""
  } as { subjectExpert: string });
  const [subjectExpertOptions, setSubjectExpertOptions] = useState(
    [] as User[]
  );
  const history = useHistory();
  const assignedItemsCount = getAssignedItemsCount(project);
  const sharedClasses = sharedStyles();

  useDebouncedEffect(
    () => {
      if (formFields.subjectExpert.length < 3) {
        return;
      }

      getUsers<User[]>({ search: formFields.subjectExpert }).then(
        (response) => {
          const approverEmails = project.approvers.map((approver) =>
            approver.email.toLowerCase()
          );
          const subjectExpertEmails = project.subjectExperts.map(
            (subjectExpert) => subjectExpert.email.toLowerCase()
          );

          setSubjectExpertOptions(
            response.data?.filter(
              (user) =>
                !approverEmails.includes(user.email.toLowerCase()) &&
                !subjectExpertEmails.includes(user.email.toLowerCase())
            ) || []
          );
        }
      );
    },
    1000,
    [
      formFields.subjectExpert,
      getUsers,
      project.approvers,
      project.subjectExperts
    ]
  );

  const callUpdateProject = (
    subjectExperts: Project["subjectExperts"]
  ): void => {
    updateProject(project.id, {
      ...project,
      subjectExperts
    }).then(() => {
      updateProjectSuccess({
        ...project,
        subjectExperts
      });
    });
  };

  const changeSubjectExpert = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormFields({
      ...formFields,
      subjectExpert: event.target.value
    });
  };

  const changeSubjectExpertOptions = (
    event: ChangeEvent<unknown>,
    options: User[]
  ): void => {
    setActiveSubjectExpertOptions(options);
  };

  const clickAdd = (): void => {
    const subjectExperts = activeSubjectExpertOptions.map((option) => ({
      assignedItems: 0,
      email: option.email,
      sub: option.sub
    }));

    updateProject(project.id, {
      ...project,
      subjectExperts: [...project.subjectExperts, ...subjectExperts]
    }).then(() => {
      updateProjectSuccess({
        ...project,
        subjectExperts: [...project.subjectExperts, ...subjectExperts]
      });

      setActiveSubjectExpertOptions([]);
      setFormFields({
        ...formFields,
        subjectExpert: ""
      });
    });
  };

  const clickContinue = (): void => {
    history.push(`/projects/${project.id}/edit/approvers`);
  };

  const clickDelete = (email: string): void => {
    const subjectExperts = project.subjectExperts.filter(
      (subjectExpert) => subjectExpert.email !== email
    );

    callUpdateProject(subjectExperts);
  };

  const clickDivideEqually = (): void => {
    const subjectExperts = project.subjectExperts.map(
      (subjectExpert, index) => {
        const bulkShare = Math.ceil(
          project.items / project.subjectExperts.length
        );
        const remainingShare =
          project.items - bulkShare * (project.subjectExperts.length - 1);

        return {
          assignedItems:
            index !== project.subjectExperts.length - 1
              ? bulkShare
              : remainingShare,
          email: subjectExpert.email,
          sub: subjectExpert.sub
        };
      }
    );

    callUpdateProject(subjectExperts);
  };

  const clickResetAllocation = (): void => {
    const subjectExperts = project.subjectExperts.map((subjectExpert) => ({
      email: subjectExpert.email,
      assignedItems: 0,
      sub: subjectExpert.sub
    }));

    callUpdateProject(subjectExperts);
  };

  const renderUserOption = (option: User): ReactElement => {
    return <UserOption option={option} />;
  };

  const updateAssignedItems = (email: string, assignedItems: number): void => {
    const subjectExperts = project.subjectExperts.map((subjectExpert) => ({
      assignedItems:
        subjectExpert.email === email
          ? assignedItems
          : subjectExpert.assignedItems,
      email: subjectExpert.email,
      sub: subjectExpert.sub
    }));

    callUpdateProject(subjectExperts);
  };

  return (
    <div data-testid="subject-experts-page">
      {Object.keys(project).length > 0 && (
        <>
          <Box marginBottom={4}>
            <Box display="flex" marginBottom={4}>
              <Box>
                <Box marginBottom={1}>
                  <Typography
                    component="h2"
                    variant="h4"
                    data-testid="page-heading"
                  >
                    Add subject experts
                  </Typography>
                </Box>
                <Typography data-testid="page-introduction">
                  Subject experts will check items and add keywords. They will
                  be notified when the project starts
                </Typography>
              </Box>
              <Box marginLeft="auto">
                <Button
                  color="primary"
                  disableElevation
                  disabled={
                    assignedItemsCount !== project.items ||
                    !project.subjectExperts.length
                  }
                  onClick={clickContinue}
                  data-testid="continue-button"
                >
                  Continue
                </Button>
              </Box>
            </Box>
            <Divider />
          </Box>
          <Box alignItems="center" display="flex" marginBottom={4}>
            <Box marginRight={11}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Subject experts
                </Typography>
              </Box>
              <Typography
                component="p"
                variant="h4"
                data-testid="subject-experts"
              >
                {project.subjectExperts.length}
              </Typography>
            </Box>
            <Box marginRight={11}>
              <Box marginBottom={1}>
                <Typography
                  className={sharedClasses.fadedText}
                  component="h3"
                  variant="subtitle1"
                >
                  Allocated items
                </Typography>
              </Box>
              <Typography
                component="p"
                variant="h4"
                data-testid="allocated-items"
              >
                {assignedItemsCount} / {project.items}
              </Typography>
            </Box>
            <Box clone marginRight={5}>
              <Button
                color="primary"
                disabled={!project.subjectExperts.length}
                onClick={clickDivideEqually}
                startIcon={<Group />}
                variant="text"
                data-testid="divide-equally-button"
              >
                Divide equally
              </Button>
            </Box>
            <Button
              color="primary"
              disabled={!project.subjectExperts.length}
              onClick={clickResetAllocation}
              startIcon={<RotateLeft />}
              variant="text"
              data-testid="reset-allocation-button"
            >
              Reset allocation
            </Button>
          </Box>
          <Box marginBottom={4}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item md={7} xs={12}>
                <Autocomplete
                  filterOptions={(subjectExpert) => subjectExpert}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.email}
                  multiple
                  onChange={changeSubjectExpertOptions}
                  options={[
                    ...activeSubjectExpertOptions,
                    ...subjectExpertOptions
                  ]}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        "data-testid": "subject-experts-input"
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                      onChange={changeSubjectExpert}
                      placeholder="Search name or email address"
                    />
                  )}
                  renderOption={(option) => renderUserOption(option)}
                  value={activeSubjectExpertOptions}
                  data-testid="subject-experts-autocomplete"
                />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  disableElevation
                  disabled={!activeSubjectExpertOptions.length}
                  onClick={clickAdd}
                  startIcon={<Add />}
                  data-testid="add-button"
                >
                  Add selected subject experts
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <SubjectExpertsTable
        clickDelete={clickDelete}
        subjectExperts={project.subjectExperts}
        unassignedItemsCount={project.items - assignedItemsCount}
        updateAssignedItems={updateAssignedItems}
      />
    </div>
  );
};

export default SubjectExperts;
