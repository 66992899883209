// Core
import React, { FC, ReactElement, useEffect } from "react";

// Interfaces
import { Project } from "interfaces";

// Utils
import { useApi, useProject } from "utils/context";

interface Props {
  children: ReactElement | ReactElement[];
  projectId: string;
}

const ProjectLayout: FC<Props> = (props: Props): ReactElement => {
  const { getProject, token } = useApi();
  const { fetchProjectSuccess } = useProject();

  useEffect(() => {
    if (!token) {
      return;
    }

    getProject<Project>(props.projectId).then((response) => {
      fetchProjectSuccess(response.data || ({} as Project));
    });
  }, [fetchProjectSuccess, getProject, props.projectId, token]);

  return <div data-testid="project-layout">{props.children}</div>;
};

export default ProjectLayout;
