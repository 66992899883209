export enum PartnerFileType {
  " ms " = " qp ",
  " MS " = " QP ",
  _ms_ = "_qp_",
  _MS_ = "_QP_",
  " qp " = " ms ",
  " QP " = " MS ",
  _qp_ = "_ms_",
  _QP_ = "_MS_"
}
