// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ConditionalWrapper from "components/conditionalWrapper/ConditionalWrapper";
import Prompt from "components/qti/prompt/Prompt";
import SimpleChoice from "components/qti/simpleChoice/SimpleChoice";
import StimulusItems from "components/qti/stimulusItems/StimulusItems";
import StimulusList from "components/qti/stimulusList/StimulusList";
import Table from "components/qti/table/Table";

// Interfaces
import {
  ChoiceInteraction as ChoiceInteractionInterface,
  ChoiceInteractionResponses as ChoiceInteractionResponsesInterface,
  Stimulus as StimulusInterface,
  StimulusItem as StimulusItemInterface
} from "interfaces";

// Utils
import { useApi } from "utils/context";

// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import { Box, Card, CardContent, fade, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import * as Scroll from "react-scroll";

interface Props {
  activeApproval?: "" | "prompt" | "responses" | "stimulus";
  content: ChoiceInteractionInterface;
  hasPrompt?: boolean;
  hasResponses?: boolean;
  isEditing: boolean;
  updateQuestion?: (body: Record<string, unknown>) => Promise<unknown>;
}

const ChoiceInteraction: FC<Props> = (props): ReactElement => {
  const { createQuestionImageSignedUploadUrl, uploadFile } = useApi();
  const [editedImages, setEditedImages] = useState(
    [] as { currentItem: StimulusItemInterface; newFile: File }[]
  );
  const [formFields, setFormFields] = useState({
    prompt: "",
    responses: [],
    stimulusItems: [],
    stimulusList: [] as StimulusInterface["list"],
    table: {} as ChoiceInteractionResponsesInterface["table"]
  } as { prompt: string; responses: ChoiceInteractionResponsesInterface["items"]; stimulusItems: StimulusInterface["items"]; stimulusList: StimulusInterface["list"]; table: ChoiceInteractionResponsesInterface["table"] });
  const [isEditing, setIsEditing] = useState(false);
  const { questionKey } = useParams<Record<string, string | undefined>>();

  useEffect(() => {
    if (!props.activeApproval) {
      return;
    }

    Scroll.scroller.scrollTo(props.activeApproval, {
      containerId: "question-container",
      duration: 1000,
      smooth: true
    });
  }, [props.activeApproval]);

  useEffect(() => {
    setFormFields({
      prompt: props.content.prompt.content,
      responses: props.content.responses.items,
      stimulusItems: props.content.stimulus.items,
      stimulusList: props.content.stimulus.list,
      table: props.content.responses.table
    });
  }, [props.content]);

  useEffect(() => {
    if (props.isEditing) {
      setIsEditing(true);
    }

    async function updateQuestion() {
      if (editedImages.length) {
        await Promise.all(
          editedImages.map((editedImage) =>
            createUrlAndUploadSingleFile(editedImage)
          )
        );
      }

      if (editedImages.length) {
        formFields.stimulusItems = formFields.stimulusItems.map((item) => {
          const editedImage = editedImages.find(
            (image) => image.currentItem.currentContent === item.currentContent
          );

          if (!editedImage) {
            return item;
          }

          return { ...item, currentContent: editedImage.newFile.name };
        });
      }

      props.updateQuestion &&
        props.updateQuestion({
          content: {
            ...props.content,
            prompt: { ...props.content.prompt, content: formFields.prompt },
            stimulus: {
              ...props.content.stimulus,
              items: formFields.stimulusItems
            },
            responses: {
              ...props.content.responses,
              items: formFields.responses
            }
          }
        });
    }

    if (!props.isEditing && isEditing) {
      updateQuestion().finally(() => {
        setIsEditing(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFields, isEditing, props.isEditing, props.updateQuestion]);

  const createUrlAndUploadSingleFile = async (editedImage: {
    currentItem: StimulusItemInterface;
    newFile: File;
  }) => {
    if (!questionKey) {
      return;
    }

    const res = await createQuestionImageSignedUploadUrl(questionKey, {
      name: editedImage.newFile.name,
      currentContent: editedImage.currentItem.currentContent as string
    });
    const url = res.headers.get("location") as string;

    await uploadFile(url, editedImage.newFile);
  };

  const editFormField = (name: "prompt", value: string): void => {
    setFormFields({
      ...formFields,
      [name]: value
    });
  };

  const editImage = (
    currentItem: StimulusItemInterface,
    newFile: File
  ): void => {
    setEditedImages([
      ...editedImages.filter(
        (image) =>
          image.currentItem.currentContent !== currentItem.currentContent
      ),
      { currentItem, newFile }
    ]);
  };

  const editNestedFormField = (
    name: "responses" | "stimulusItems",
    index: number,
    value: string
  ): void => {
    const copy = [...formFields[name]];

    copy[index].content = value;

    setFormFields({
      ...formFields,
      [name]: copy
    });
  };

  const editStimulusListFormField = (index: number, value: string): void => {
    if (!formFields.stimulusList) {
      return;
    }

    const copy = [...formFields.stimulusList];

    copy[index].text = value;

    setFormFields({
      ...formFields,
      stimulusList: copy
    });
  };

  const editTableFormField = (
    index: number,
    value: string,
    rowIndex?: number
  ): void => {
    if (!formFields.table) {
      return;
    }

    const copy = formFields.table;

    if (rowIndex !== undefined) {
      copy.tbody[rowIndex][index] = value;
    } else {
      copy.thead[index].text = value;
    }

    setFormFields({
      ...formFields,
      table: copy
    });
  };

  return (
    <div data-testid="choice-interaction">
      <ConditionalWrapper
        wrap={!props.activeApproval}
        wrapper={(children) => (
          <Card>
            <CardContent>{children}</CardContent>
          </Card>
        )}
      >
        <>
          {props.content.order.length > 0 && (
            <ConditionalWrapper
              wrap={!!props.activeApproval}
              wrapper={(children) => (
                <Scroll.Element name="stimulus">
                  <Box
                    marginBottom={4}
                    style={{
                      opacity: props.activeApproval === "stimulus" ? 1 : 0.37
                    }}
                  >
                    <Box clone marginBottom={1}>
                      <Typography variant="subtitle1">Stimulus</Typography>
                    </Box>
                    <Card
                      style={{
                        border:
                          props.activeApproval === "stimulus"
                            ? `3px solid ${colours.corporateBlueLight}`
                            : "0 none"
                      }}
                    >
                      <CardContent>{children}</CardContent>
                    </Card>
                  </Box>
                </Scroll.Element>
              )}
            >
              <>
                {props.content.order.map((item) => (
                  <React.Fragment key={item}>
                    {item === "list" &&
                      props.content.stimulus.list &&
                      props.content.stimulus.list.length && (
                        <StimulusList
                          isEditing={isEditing}
                          list={props.content.stimulus.list}
                          onChange={(value: string, index: number) => {
                            editStimulusListFormField(index, value);
                          }}
                        />
                      )}
                    {item === "stimulusItems" &&
                      props.content.stimulus.items.length && (
                        <StimulusItems
                          isEditing={isEditing}
                          items={formFields.stimulusItems}
                          onChange={(value: string, index: number) => {
                            editNestedFormField("stimulusItems", index, value);
                          }}
                          onChangeImage={(
                            value: string,
                            file: File,
                            index: number
                          ) => {
                            editImage(formFields.stimulusItems[index], file);

                            editNestedFormField("stimulusItems", index, value);
                          }}
                        />
                      )}
                    {item === "table" &&
                      props.content.responses.table &&
                      Object.keys(props.content.responses.table).length && (
                        <Table
                          content={props.content.responses.table}
                          isEditing={isEditing}
                          onChangeTd={(
                            value: string,
                            index: number,
                            rowIndex: number
                          ) => {
                            editTableFormField(index, value, rowIndex);
                          }}
                          onChangeTh={(value: string, index: number) => {
                            editTableFormField(index, value);
                          }}
                        />
                      )}
                  </React.Fragment>
                ))}
              </>
            </ConditionalWrapper>
          )}
          <ConditionalWrapper
            wrap={!!props.activeApproval}
            wrapper={(children) => (
              <Scroll.Element name="prompt">
                <Box
                  marginBottom={4}
                  style={{
                    opacity: props.activeApproval === "prompt" ? 1 : 0.37
                  }}
                >
                  <Box clone marginBottom={1}>
                    <Typography variant="subtitle1">Prompt</Typography>
                  </Box>
                  <Card
                    style={{
                      border:
                        props.activeApproval === "prompt" && props.hasPrompt
                          ? `3px solid ${colours.corporateBlueLight}`
                          : props.activeApproval && !props.hasPrompt
                          ? `3px solid ${fade(colours.pdfRed, 0.49)}`
                          : "0 none"
                    }}
                  >
                    <CardContent>{children}</CardContent>
                  </Card>
                </Box>
              </Scroll.Element>
            )}
          >
            <Prompt
              content={formFields.prompt}
              isApproving={!!props.activeApproval}
              isEditing={isEditing}
              onChange={(value: string) => editFormField("prompt", value)}
            />
          </ConditionalWrapper>
          <ConditionalWrapper
            wrap={!!props.activeApproval}
            wrapper={(children) => (
              <Scroll.Element name="responses">
                <Box
                  marginBottom={3}
                  style={{
                    opacity: props.activeApproval === "responses" ? 1 : 0.37
                  }}
                >
                  <Box clone marginBottom={1}>
                    <Typography variant="subtitle1">Responses</Typography>
                  </Box>
                  <Card
                    style={{
                      border:
                        props.activeApproval === "responses" &&
                        props.hasResponses
                          ? `3px solid ${colours.corporateBlueLight}`
                          : props.activeApproval && !props.hasResponses
                          ? `3px solid ${fade(colours.pdfRed, 0.49)}`
                          : "0 none"
                    }}
                  >
                    <CardContent>{children}</CardContent>
                  </Card>
                </Box>
              </Scroll.Element>
            )}
          >
            <>
              {formFields.responses.map((_, index) => (
                <React.Fragment key={index}>
                  <SimpleChoice
                    index={index}
                    isApproving={!!props.activeApproval}
                    isEditing={isEditing}
                    onChange={(value: string) => {
                      editNestedFormField("responses", index, value);
                    }}
                    response={formFields.responses[index]}
                  />
                </React.Fragment>
              ))}
            </>
          </ConditionalWrapper>
        </>
      </ConditionalWrapper>
    </div>
  );
};

export default ChoiceInteraction;
