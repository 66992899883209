// Core
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";

// Components
import Dropzone from "components/dropzone/Dropzone";
import LocalFile from "components/localFile/LocalFile";
import Tooltip from "components/tooltip/Tooltip";

// Enums
import { PartnerFileType } from "enums";

// Interface
import { Job } from "interfaces";

// Utils
import { useApi, useProject } from "utils/context";
import {
  pdfTypeRegEx,
  validCIFilenameRegEx,
  validOCRFilenameRegEx
} from "utils/utils";

// Vendor
import { Button, colours, Divider } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  LinearProgress,
  makeStyles,
  Typography
} from "@material-ui/core";
import {
  Check,
  Clear,
  Error,
  Folder,
  InsertDriveFile
} from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { FileRejection } from "react-dropzone";
import { useHistory } from "react-router-dom";

interface FilePairs {
  [key: string]: { ms?: File; qp?: File };
}

interface Props {
  invalidFiles: {
    existing: File[];
    invalid: File[];
    rejected: File[];
  };
  setActiveStep: React.Dispatch<React.SetStateAction<"initial" | "summary">>;
  setInvalidFiles: React.Dispatch<
    React.SetStateAction<{
      existing: File[];
      invalid: File[];
      rejected: File[];
    }>
  >;
  setValidFiles: React.Dispatch<React.SetStateAction<File[]>>;
  validFiles: File[];
}

const Summary: FC<Props> = (props): ReactElement => {
  const { setInvalidFiles, setValidFiles } = props;
  const { createSignedUploadUrl, getJobs, uploadFile } = useApi();
  const { project, updateProjectSuccess } = useProject();
  const [areInputsMounted, setAreInputsMounted] = useState(true);
  const [
    browseFilesClickedTimestamp,
    setBrowseFilesClickedTimestamp
  ] = useState("");
  const [expectedFileName, setExpectedFileName] = useState("");
  const [filePairs, setFilePairs] = useState({} as FilePairs);
  const [
    isConfirmUploadDialogueOpen,
    setIsConfirmUploadDialogueOpen
  ] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [unexpectedFile, setUnexpectedFile] = useState({} as File);
  const history = useHistory();
  const useStyles = makeStyles({
    ready: {
      color: colours.greenDark
    }
  });
  const classes = useStyles();

  useEffect(
    function remountInput() {
      if (!areInputsMounted) {
        setAreInputsMounted(true);
      }
    },
    [areInputsMounted]
  );

  useEffect(() => {
    const object = {} as FilePairs;

    props.validFiles.forEach((file) => {
      const group = file.name.replace(pdfTypeRegEx, "_").replace(".pdf", "");
      const pdfType = pdfTypeRegEx.exec(file.name);

      if (!object[group]) {
        object[group] = {};
      }

      object[group][
        (pdfType ? pdfType[0].slice(1, -1).toLowerCase() : "") as "ms" | "qp"
      ] = file;
    });

    setFilePairs(
      Object.keys(object)
        .sort()
        .reduce(
          (previousValue, currentValue) => ({
            ...previousValue,
            [currentValue]: object[currentValue]
          }),
          {}
        )
    );
  }, [props.invalidFiles, props.validFiles]);

  useEffect(() => {
    if (
      !isInitialLoad ||
      (!Object.keys(filePairs).length &&
        !props.invalidFiles.existing.length &&
        !props.invalidFiles.invalid.length &&
        !props.invalidFiles.rejected.length)
    ) {
      return;
    }

    setIsInitialLoad(false);
  }, [filePairs, isInitialLoad, props.invalidFiles, props.validFiles]);

  const clickBrowseFiles = (): void => {
    setBrowseFilesClickedTimestamp(Date.now().toString());
  };

  const clickCloseConfirmUploadDialogue = (): void => {
    setIsConfirmUploadDialogueOpen(false);
  };

  const clickConfirmUpload = (): void => {
    setIsConfirmUploadDialogueOpen(false);
    setIsUploading(true);

    Promise.all(
      props.validFiles.map((validFile) =>
        createUrlAndUploadSingleFile(validFile)
      )
    ).then(() => {
      setIsUploading(false);

      updateProjectSuccess({
        ...project,
        uploadedJobs: props.validFiles.length / 2
      });

      history.push(`/projects/${project.id}/edit/uploads`);
    });
  };

  const clickDelete = (fileName: string): void => {
    props.setValidFiles(
      props.validFiles.filter((file) => file.name !== fileName)
    );
  };

  const clickOrDropFile = (file: File): void => {
    setExpectedFileName(getExpectedFileName(file));
  };

  const clickUpload = (): void => {
    setIsConfirmUploadDialogueOpen(true);
  };

  const createUrlAndUploadSingleFile = async (file: File) => {
    const data = new FormData();
    const res = await createSignedUploadUrl(project.id, {
      name: file.name
    });
    const url = res.headers.get("location") as string;

    data.append(file.name, file);

    await uploadFile(url, data);
  };

  const getExpectedFileName = (partnerFile: File): string => {
    const pdfType = pdfTypeRegEx.exec(partnerFile.name);

    return pdfType
      ? partnerFile.name.replace(
          pdfType[0],
          PartnerFileType[pdfType[0] as keyof typeof PartnerFileType]
        )
      : partnerFile.name;
  };

  const getMissingFilesCount = (pairs: FilePairs, type: string): number => {
    return Object.keys(pairs).filter((key) => !pairs[key][type as "ms" | "qp"])
      .length;
  };

  const processReceivedFile = useCallback(
    function processReceivedFile(acceptedFiles: File[], name: string) {
      if (!acceptedFiles.length) {
        return;
      }

      if (acceptedFiles[0].name === name) {
        setUnexpectedFile({} as File);

        setValidFiles((previousValue) => previousValue.concat(acceptedFiles));
      } else {
        setAreInputsMounted(false);
        setUnexpectedFile(acceptedFiles[0]);
      }
    },
    [setValidFiles]
  );

  const processReceivedFileMemoised = useMemo(() => {
    return (acceptedFiles: File[]): void => {
      processReceivedFile(acceptedFiles, expectedFileName);
    };
  }, [expectedFileName, processReceivedFile]);

  const processReceivedFiles = useCallback(
    function processReceivedFiles(
      acceptedFiles: File[],
      fileRejections: FileRejection[]
    ) {
      if (acceptedFiles.length) {
        Promise.all(
          acceptedFiles.map((file) =>
            getJobs<Job>({
              index: file.name
                .replace("_ms", "")
                .replace("_qp", "")
                .replace(".pdf", "")
            })
          )
        ).then((response) => {
          const existing = [
            ...new Set(
              response
                .map((res) => res.data || [])
                .flat()
                .flatMap((res) => [res.markScheme, res.questionPaper])
            )
          ];

          setInvalidFiles({
            existing: acceptedFiles.filter((file) =>
              existing.includes(file.name)
            ),
            invalid: acceptedFiles.filter(
              (file) =>
                (!file.name.match(validCIFilenameRegEx) &&
                  !file.name.match(validOCRFilenameRegEx)) ||
                !file.name.includes(project.syllabusCode)
            ),
            rejected: fileRejections.map((rejection) => rejection.file)
          });

          setValidFiles((previousValue) =>
            previousValue.concat(
              acceptedFiles.filter(
                (file) =>
                  !existing.includes(file.name) &&
                  (file.name.match(validCIFilenameRegEx) ||
                    file.name.match(validOCRFilenameRegEx)) &&
                  file.name.includes(project.syllabusCode)
              )
            )
          );
        });
      } else {
        setInvalidFiles({
          existing: [],
          invalid: [],
          rejected: fileRejections.map((rejection) => rejection.file)
        });
      }
    },
    [getJobs, project.syllabusCode, setInvalidFiles, setValidFiles]
  );

  const processReceivedFilesMemoised = useMemo(() => {
    return (acceptedFiles: File[], rejectedFiles: FileRejection[]): void => {
      processReceivedFiles(acceptedFiles, rejectedFiles);
    };
  }, [processReceivedFiles]);

  const missingMarkSchemesCount = getMissingFilesCount(filePairs, "ms");
  const missingQuestionPapersCount = getMissingFilesCount(filePairs, "qp");

  return (
    <div data-testid="upload-summary">
      <Dialog
        onClose={clickCloseConfirmUploadDialogue}
        aria-labelledby="confirm-upload-dialogue-heading"
        open={isConfirmUploadDialogueOpen}
        data-testid="confirm-upload-dialogue"
      >
        <Box padding={4}>
          <Box marginBottom={2}>
            <Typography
              variant="h4"
              data-testid="confirm-upload-dialogue-heading"
            >
              Do you want to upload selected files?
            </Typography>
          </Box>
          <Grid container>
            <Grid item md={10}>
              <Typography paragraph>
                If you need to upload more papers to this project, please stay
                on this page until you complete your files.
              </Typography>
              <Typography paragraph>
                Once the upload starts, you cannot change or upload new files to
                this project. You can always create a new project.
              </Typography>
              <Typography>Please confirm this action.</Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box display="flex" padding={3}>
          <Box marginLeft="auto">
            <Box clone marginRight={2}>
              <Button
                color="primary"
                onClick={clickCloseConfirmUploadDialogue}
                variant="text"
                data-testid="cancel-upload-button"
              >
                Cancel
              </Button>
            </Box>
            <Button
              color="primary"
              disableElevation
              onClick={clickConfirmUpload}
              variant="contained"
              data-testid="confirm-upload-button"
            >
              Upload for harvesting
            </Button>
          </Box>
        </Box>
      </Dialog>
      {props.invalidFiles.existing.length > 0 && (
        <Box marginBottom={6}>
          <Alert severity="error" data-testid="existing-files-alert">
            <AlertTitle data-testid="existing-files-alert-title">
              Existing file{" "}
              {props.invalidFiles.existing.length > 1 ? "names" : "name"}
            </AlertTitle>
            <div data-testid="existing-files-alert-body">
              The following{" "}
              {props.invalidFiles.existing.length > 1 ? "files" : "file"}{" "}
              already{" "}
              {props.invalidFiles.existing.length > 1 ? "exist" : "exists"} in
              another project, and will not be uploaded:{" "}
              <strong>
                {props.invalidFiles.existing
                  .map((file) => file.name)
                  .join(", ")}
              </strong>
            </div>
          </Alert>
        </Box>
      )}
      {props.invalidFiles.invalid.length > 0 && (
        <Box marginBottom={6}>
          <Alert severity="error" data-testid="invalid-files-alert">
            <AlertTitle data-testid="invalid-files-alert-title">
              Invalid file{" "}
              {props.invalidFiles.invalid.length > 1 ? "names" : "name"}
            </AlertTitle>
            <div data-testid="invalid-files-alert-body">
              The following{" "}
              {props.invalidFiles.invalid.length > 1
                ? "files have"
                : "file has an"}{" "}
              invalid {props.invalidFiles.invalid.length > 1 ? "names" : "name"}
              , or{" "}
              {props.invalidFiles.invalid.length > 1 ? "belong" : "belongs"} to
              a different syllabus code, and will not be uploaded:{" "}
              <strong>
                {props.invalidFiles.invalid.map((file) => file.name).join(", ")}
              </strong>
            </div>
          </Alert>
        </Box>
      )}
      {props.invalidFiles.rejected.length > 0 && (
        <Box marginBottom={6}>
          <Alert severity="error" data-testid="rejected-files-alert">
            <AlertTitle data-testid="rejected-files-alert-title">
              Invalid file{" "}
              {props.invalidFiles.rejected.length > 1 ? "formats" : "format"}
            </AlertTitle>
            <div data-testid="rejected-files-alert-body">
              The following{" "}
              {props.invalidFiles.rejected.length > 1 ? "files are" : "file is"}{" "}
              not a PDF, and will not be uploaded:{" "}
              <strong>
                {props.invalidFiles.rejected
                  .map((file) => file.name)
                  .join(", ")}
              </strong>
            </div>
          </Alert>
        </Box>
      )}
      {Object.keys(unexpectedFile).length > 0 && (
        <Box marginBottom={6}>
          <Alert severity="error" data-testid="unexpected-file-alert">
            <AlertTitle>Unexpected file</AlertTitle>I was expecting{" "}
            {expectedFileName}, but you selected{" "}
            <strong>{unexpectedFile.name}</strong>.
          </Alert>
        </Box>
      )}
      {!isUploading && (
        <Box display="flex" marginBottom={4}>
          <Box>
            <Box display="flex" marginBottom={1}>
              <Typography
                component="h2"
                variant="h4"
                data-testid="page-heading"
              >
                Upload question papers and mark schemes for{" "}
                {project.syllabusCode}
              </Typography>
              <Tooltip title="To upload papers for another syllabus please go back to the previous step and change your code." />
            </Box>
            <Typography data-testid="page-introduction">
              Every question paper must have a corresponding mark scheme
            </Typography>
          </Box>
          <Box marginLeft="auto">
            <Box clone marginRight={2}>
              <Button
                color="primary"
                disableElevation
                onClick={clickBrowseFiles}
                startIcon={<Folder />}
                variant="outlined"
                data-testid="browse-files-button"
              >
                Browse for files
              </Button>
            </Box>
            <Button
              color="primary"
              disableElevation
              disabled={
                isUploading ||
                missingMarkSchemesCount > 0 ||
                missingQuestionPapersCount > 0 ||
                !props.validFiles.length
              }
              onClick={clickUpload}
              data-testid="upload-button"
            >
              Upload for harvesting
            </Button>
          </Box>
        </Box>
      )}
      {isUploading && (
        <Box marginBottom={4}>
          <Box marginBottom={4}>
            <Box marginBottom={2}>
              <Typography
                component="h2"
                variant="h4"
                data-testid="page-heading"
              >
                Uploading documents...
              </Typography>
            </Box>
            <LinearProgress />
          </Box>
          <Divider />
        </Box>
      )}
      <>
        {!isUploading && (
          <Box marginBottom={4}>
            <Divider />
            <Box marginY={4}>
              {isInitialLoad && (
                <Box alignItems="center" display="flex">
                  <Box clone marginRight={1}>
                    <CircularProgress color="inherit" size={18} />
                  </Box>
                  <Typography>Processing files...</Typography>
                </Box>
              )}
              {!isInitialLoad && Object.keys(filePairs).length === 0 && (
                <Box alignItems="center" display="flex">
                  <Box clone marginRight={1}>
                    <Clear htmlColor={colours.pdfRed} />
                  </Box>
                  <Box marginRight={2}>
                    <Typography color="error" data-testid="empty">
                      No files selected for upload
                    </Typography>
                  </Box>
                </Box>
              )}
              {Object.keys(filePairs).length > 0 &&
                missingMarkSchemesCount === 0 &&
                missingQuestionPapersCount === 0 && (
                  <Box alignItems="center" display="flex">
                    <Box clone marginRight={1}>
                      <Check htmlColor={colours.greenDark} />
                    </Box>
                    <Typography className={classes.ready} data-testid="ready">
                      {Object.keys(filePairs).length * 2} files are ready to
                      upload
                    </Typography>
                  </Box>
                )}
              {(missingMarkSchemesCount > 0 ||
                missingQuestionPapersCount > 0) && (
                <Box alignItems="center" display="flex">
                  <Box clone marginRight={1}>
                    <Error color="error" />
                  </Box>
                  <Typography color="error" data-testid="missing">
                    {missingMarkSchemesCount > 0 && (
                      <>
                        {missingMarkSchemesCount} mark scheme{" "}
                        {missingMarkSchemesCount === 1
                          ? "document"
                          : "documents"}
                      </>
                    )}
                    {missingQuestionPapersCount > 0 && (
                      <>
                        {missingMarkSchemesCount > 0 ? " and " : ""}
                        {missingQuestionPapersCount} question paper{" "}
                        {missingQuestionPapersCount === 1
                          ? "document"
                          : "documents"}
                      </>
                    )}{" "}
                    {(missingMarkSchemesCount > 0 ||
                      missingQuestionPapersCount > 0) && <>missing</>}
                  </Typography>
                </Box>
              )}
            </Box>
            <Divider />
          </Box>
        )}
        <Box display="none">
          <Dropzone
            accept="application/pdf"
            browseFilesClickedTimestamp={browseFilesClickedTimestamp}
            isUploading={false}
            receivedFilesCallback={processReceivedFilesMemoised}
            testId={"browse-files-dropzone"}
          ></Dropzone>
        </Box>
        {!isInitialLoad && (
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Typography component="h3" variant="h4">
                Question papers (
                {
                  Object.keys(filePairs).filter((key) => filePairs[key].qp)
                    .length
                }
                )
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <Typography component="h3" variant="h4">
                Mark schemes (
                {
                  Object.keys(filePairs).filter((key) => filePairs[key].ms)
                    .length
                }
                )
              </Typography>
            </Grid>
          </Grid>
        )}
        {Object.keys(filePairs).map((key) => {
          const markScheme = filePairs[key].ms;
          const questionPaper = filePairs[key].qp;

          return (
            <Box key={key} marginBottom={2}>
              <Grid container spacing={4}>
                {questionPaper && (
                  <Grid item xs={4}>
                    <LocalFile
                      clickDelete={() => clickDelete(questionPaper.name)}
                      file={questionPaper}
                    />
                  </Grid>
                )}
                {!questionPaper && markScheme && (
                  <Box
                    clone
                    display="flex"
                    onClick={() => clickOrDropFile(markScheme)}
                    onDrop={() => clickOrDropFile(markScheme)}
                    data-testid="question-paper-dropzone-container"
                  >
                    <Grid item xs={4}>
                      {areInputsMounted && (
                        <Dropzone
                          accept="application/pdf"
                          isUploading={false}
                          maxFiles={1}
                          missingFile={true}
                          receivedFilesCallback={processReceivedFileMemoised}
                          testId={"question-paper-dropzone"}
                        >
                          <Box alignItems="center" display="flex">
                            <Box clone marginRight={1}>
                              <InsertDriveFile fontSize="large" />
                            </Box>
                            <Typography
                              component="p"
                              display="inline"
                              variant="subtitle1"
                              data-testid="expected-file-name"
                            >
                              {getExpectedFileName(markScheme)}
                            </Typography>
                          </Box>
                        </Dropzone>
                      )}
                    </Grid>
                  </Box>
                )}
                <Box clone alignSelf="center">
                  <Grid item xs={1}>
                    <Divider />
                  </Grid>
                </Box>
                {markScheme && (
                  <Grid item xs={4}>
                    <LocalFile
                      clickDelete={() => {
                        clickDelete(markScheme.name);
                      }}
                      file={markScheme}
                    />
                  </Grid>
                )}
                {!markScheme && questionPaper && (
                  <Box
                    clone
                    display="flex"
                    onClick={() => clickOrDropFile(questionPaper)}
                    onDrop={() => clickOrDropFile(questionPaper)}
                    data-testid="mark-scheme-dropzone-container"
                  >
                    <Grid item xs={4}>
                      {areInputsMounted && (
                        <Dropzone
                          accept="application/pdf"
                          isUploading={false}
                          maxFiles={1}
                          missingFile={true}
                          receivedFilesCallback={processReceivedFileMemoised}
                          testId={"mark-scheme-dropzone"}
                        >
                          <Box alignItems="center" display="flex">
                            <Box clone marginRight={1}>
                              <InsertDriveFile fontSize="large" />
                            </Box>
                            <Typography
                              component="p"
                              display="inline"
                              variant="subtitle1"
                              data-testid="expected-file-name"
                            >
                              {getExpectedFileName(questionPaper)}
                            </Typography>
                          </Box>
                        </Dropzone>
                      )}
                    </Grid>
                  </Box>
                )}
              </Grid>
            </Box>
          );
        })}
      </>
    </div>
  );
};

export default Summary;
