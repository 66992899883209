type Method = "GET" | "POST" | "PUT" | "DELETE";

interface Options {
  headers?: { [key: string]: string };
  method?: Method;
  file?: FormData | File;
  token?: string;
}

interface ParsedData<ResponseBodyType> {
  data?: ResponseBodyType;
}

export interface ResponseWithData<ResponseBodyType = unknown>
  extends Response,
    ParsedData<ResponseBodyType> {}

export function buildQuery(query?: { [key: string]: string }): string {
  const q = Object.entries(query || {})
    .filter(([, v]) => v != null)
    .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
    .join("&");

  return q ? `?${q}` : q;
}

export async function DELETE<ResponseBodyType>(
  url: string,
  data?: unknown,
  options: Options = {}
): Promise<ResponseWithData<ResponseBodyType>> {
  return request<ResponseBodyType>(url, data, {
    ...options,
    method: "DELETE"
  });
}

export async function get<ResponseBodyType>(
  url: string,
  options: Options = {}
): Promise<ResponseWithData<ResponseBodyType>> {
  return request<ResponseBodyType>(url, undefined, {
    ...options,
    method: "GET"
  });
}

export async function post<ResponseBodyType>(
  url: string,
  data?: unknown,
  options: Options = {}
): Promise<ResponseWithData<ResponseBodyType>> {
  return request<ResponseBodyType>(url, data, {
    ...options,
    method: "POST"
  });
}

export async function put<ResponseBodyType>(
  url: string,
  data?: unknown,
  options: Options = {}
): Promise<ResponseWithData<ResponseBodyType>> {
  return request<ResponseBodyType>(url, data, {
    ...options,
    method: "PUT"
  });
}

async function request<ResponseBodyType>(
  url: string,
  data?: unknown,
  options?: Options
): Promise<ResponseWithData<ResponseBodyType>> {
  let body = undefined;
  const extraHeaders: { [key: string]: string } = {};

  if (options && options.token) {
    extraHeaders["Authorization"] = `Bearer ${options.token}`;
  }

  if (data !== undefined) {
    body = JSON.stringify(data);
    extraHeaders["Content-Type"] = "application/json";
  }

  if (options && options.file) {
    body = options.file;
  }

  const res = (await fetch(url, {
    body,
    ...options,
    mode: "cors",
    headers: {
      ...extraHeaders,
      ...(options ? options.headers : {})
    }
  })) as ResponseWithData<ResponseBodyType>;

  if ((res.headers.get("Content-Type") || "").includes("application/json")) {
    if (res.status !== 204) {
      try {
        res.data = (await res.json()) as ResponseBodyType;
      } catch (e) {
        console.error(e);
      }
    }
  }

  if (!res.ok) {
    throw res;
  }

  return res;
}

export default {
  delete: DELETE,
  get,
  post,
  put
};
