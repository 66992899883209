// Core
import React, {
  ChangeEvent,
  FC,
  ReactElement,
  useEffect,
  useState
} from "react";

// Components
import QuestionInfoAutocomplete from "./components/questionInfoAutocomplete/QuestionInfoAutocomplete";

// Interfaces
import { Question, Skill, Topic } from "interfaces";

// Utils
import { useApi, useSkill } from "utils/context";
import { sharedStyles } from "utils/theme";
import { useDebouncedEffect } from "utils/useDebouncedEffect";

// Vendor
import { colours, TextField } from "@cambridgeassessment/cambridge-ui";
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles
} from "@material-ui/core";
import { LabelOutlined } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import produce from "immer";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const isEqual = require("lodash.isequal");
import Highlighter from "react-highlight-words";

const CustomTextField = withStyles(() => ({
  root: {
    marginBottom: 0,
    "& > .MuiInputBase-root": {
      height: "auto",
      marginBottom: 0
    },
    "& input": {
      padding: "10px 16px !important"
    }
  }
}))(TextField);

interface Props {
  isEditing: boolean;
  question: Question;
  setMetadataChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  setQuestion?: React.Dispatch<React.SetStateAction<Question>>;
  syllabusCode?: string;
}

interface TopicOption extends Topic {
  sectionTitle: string;
}

const QuestionInfo: FC<Props> = (props): ReactElement => {
  const { getSkills, getTopics } = useApi();
  const { fetchSkillsSuccess, skills } = useSkill();
  const [activeSkills, setActiveSkills] = useState(props.question.skills);
  const [activeTopics, setActiveTopics] = useState(
    props.question.topics as TopicOption[]
  );
  const [formFields, setFormFields] = useState({
    additionalInformation: props.question.additionalInformation,
    difficulty: props.question.difficulty || "",
    topic: ""
  });
  const [topics, setTopics] = useState([] as TopicOption[]);
  const sharedClasses = sharedStyles();

  useDebouncedEffect(
    () => {
      if (formFields.topic.length < 1) {
        setTopics([]);

        return;
      }

      getTopics<Topic[]>({
        search: formFields.topic,
        syllabus: props.syllabusCode as string
      }).then((response) => {
        if (response.data === undefined) {
          setTopics([]);

          return;
        }

        const broaderTopicKeywordMatchesIndex = parseFloat(
          response.headers.get("x-broader-keyword") || "-1"
        );

        if (broaderTopicKeywordMatchesIndex === -1) {
          setTopics(response.data as TopicOption[]);
        } else {
          const matches = (response.data as TopicOption[])
            .slice(0, broaderTopicKeywordMatchesIndex)
            .map((topic) => ({ ...topic, sectionTitle: "" }));
          const broaderTopicKeywordMatches = (response.data as TopicOption[])
            .slice(broaderTopicKeywordMatchesIndex)
            .map((topic) => ({
              ...topic,
              sectionTitle: "Found in the broader topic:"
            }));

          setTopics(matches.concat(broaderTopicKeywordMatches));
        }
      });
    },
    1000,
    [formFields.topic]
  );

  useEffect(() => {
    if (skills.length) {
      return;
    }

    getSkills<Skill[]>().then((response) => {
      fetchSkillsSuccess(
        response.data?.map((skill) => skill.title) || ([] as string[])
      );
    }, console.error);
  }, [fetchSkillsSuccess, getSkills, skills]);

  const changeAdditionalInformation = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormFields({
      ...formFields,
      additionalInformation: event.target.value
    });

    changeOptions(
      produce(props.question, (draftState) => {
        draftState.additionalInformation = event.target.value;
      })
    );
  };

  const changeDifficulty = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as "low" | "medium" | "high";

    setFormFields({
      ...formFields,
      difficulty: value
    });

    changeOptions(
      produce(props.question, (draftState) => {
        draftState.difficulty = value;
      })
    );
  };

  const changeOptions = (question: Question): void => {
    props.setQuestion && props.setQuestion(question);

    props.setMetadataChanged && props.setMetadataChanged(true);
  };

  const changeSkills = (
    event: ChangeEvent<unknown>,
    options: string[]
  ): void => {
    setActiveSkills(options);

    changeOptions(
      produce(props.question, (draftState) => {
        draftState.skills = options;
      })
    );
  };

  const changeTopicInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setFormFields({
      ...formFields,
      topic: event.target.value
    });
  };

  const changeTopicOptions = (
    event: ChangeEvent<unknown>,
    options: TopicOption[]
  ): void => {
    setActiveTopics(options);

    changeOptions(
      produce(props.question, (draftState) => {
        draftState.topics = options.map(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ({ sectionTitle, ...option }) => option
        );
      })
    );
  };

  const renderTopicOption = (option: Topic, value: string): ReactElement => {
    return (
      <Box
        alignItems="center"
        display="flex"
        paddingY={1}
        width={1}
        data-testid="topic-option"
      >
        <Box marginRight={1}>
          <LabelOutlined htmlColor={colours.monochromeMid} />
        </Box>
        <Box>
          <Box marginBottom={1}>
            <Typography>
              <Highlighter
                autoEscape={true}
                searchWords={[value]}
                highlightTag={({ children }) => (
                  <span style={{ fontWeight: 700 }}>{children}</span>
                )}
                textToHighlight={option.preferredNarrowTopicKeyword}
              />{" "}
              {option.narrowAlternativeKeywords.map((keyword, index) => (
                <React.Fragment key={keyword}>
                  {index === 0 && "("}
                  <Highlighter
                    autoEscape={true}
                    highlightTag={({ children }) => (
                      <span style={{ fontWeight: 700 }}>{children}</span>
                    )}
                    searchWords={[value]}
                    textToHighlight={keyword}
                  />
                  {index < option.narrowAlternativeKeywords.length - 1 && ", "}
                  {index === option.narrowAlternativeKeywords.length - 1 && ")"}
                </React.Fragment>
              ))}
            </Typography>
          </Box>
          <Typography style={{ opacity: 0.64 }}>
            <Highlighter
              autoEscape={true}
              highlightTag={({ children }) => (
                <span style={{ textDecoration: "underline" }}>{children}</span>
              )}
              searchWords={[value]}
              textToHighlight={option.broaderTopicKeyword}
            />
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <div data-testid="info">
      <Box marginBottom={2}>
        <Typography component="h2" variant="h5">
          Topics
        </Typography>
      </Box>
      {props.isEditing && (
        <Box marginBottom={5}>
          <Autocomplete
            filterOptions={(topic) => topic}
            filterSelectedOptions
            getOptionLabel={(option) => option.preferredNarrowTopicKeyword}
            getOptionSelected={(option, value) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { sectionTitle, ...optionWithoutSectionTitle } = option;

              return (
                isEqual(option, value) ||
                isEqual(optionWithoutSectionTitle, value)
              );
            }}
            groupBy={(option) => option.sectionTitle}
            multiple
            onChange={changeTopicOptions}
            options={[...activeTopics, ...topics]}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  "data-testid": "topics-input"
                }}
                onChange={changeTopicInput}
                placeholder="Search for a topic"
              />
            )}
            renderOption={(option, { inputValue }) =>
              renderTopicOption(option, inputValue)
            }
            value={activeTopics}
            data-testid="topics-autocomplete"
          />
        </Box>
      )}
      {!props.isEditing && (
        <Box marginBottom={5}>
          {props.question.topics.map((topic, index) => (
            <Box
              clone
              key={topic.preferredNarrowTopicKeyword}
              marginRight={index === props.question.topics.length - 1 ? 0 : 1}
            >
              <Card elevation={0} style={{ display: "inline-block" }}>
                <CardContent style={{ padding: "12px 16px" }}>
                  <Typography data-testid="topic">
                    {topic.preferredNarrowTopicKeyword}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
      )}
      <Box marginBottom={2}>
        <Typography component="h2" variant="h5">
          Skills
        </Typography>
      </Box>
      {props.isEditing && (
        <QuestionInfoAutocomplete
          changeHandler={changeSkills}
          disabled={!skills.length}
          name="skills"
          options={skills}
          value={activeSkills}
        />
      )}
      {!props.isEditing && (
        <Box marginBottom={5}>
          {props.question.skills.map((skill, index) => (
            <Box
              clone
              key={skill}
              marginRight={index === props.question.skills.length - 1 ? 0 : 1}
            >
              <Card elevation={0} style={{ display: "inline-block" }}>
                <CardContent style={{ padding: "12px 16px" }}>
                  <Typography data-testid="skill">{skill}</Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
      )}
      <Box marginBottom={5}>
        <Box marginBottom={2}>
          <Typography component="h2" variant="h5">
            Difficulty{" "}
            <span className={sharedClasses.fadedText}>(optional)</span>
          </Typography>
        </Box>
        {props.isEditing && (
          <RadioGroup
            aria-label="difficulty"
            name="difficulty"
            onChange={changeDifficulty}
            row
            value={formFields.difficulty}
          >
            <FormControlLabel
              value="low"
              control={
                <Radio
                  color="primary"
                  inputProps={
                    {
                      "aria-checked": formFields.difficulty === "low",
                      "data-testid": "difficulty-low-radio"
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any
                  }
                />
              }
              label="Low"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="medium"
              control={
                <Radio
                  color="primary"
                  inputProps={
                    {
                      "aria-checked": formFields.difficulty === "medium",
                      "data-testid": "difficulty-medium-radio"
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any
                  }
                />
              }
              label="Medium"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="high"
              control={
                <Radio
                  color="primary"
                  inputProps={
                    {
                      "aria-checked": formFields.difficulty === "high",
                      "data-testid": "difficulty-high-radio"
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any
                  }
                />
              }
              label="High"
              labelPlacement="bottom"
            />
          </RadioGroup>
        )}
        {!props.isEditing && (
          <>
            {props.question.difficulty && (
              <Card elevation={0} style={{ display: "inline-block" }}>
                <CardContent style={{ padding: "12px 16px" }}>
                  <Typography data-testid="difficulty">
                    {props.question.difficulty}
                  </Typography>
                </CardContent>
              </Card>
            )}
            {!props.question.difficulty && (
              <Typography data-testid="difficulty">Not provided</Typography>
            )}
          </>
        )}
      </Box>
      <Box marginBottom={5}>
        <Box marginBottom={2}>
          <Typography component="h2" variant="h5">
            Additional information{" "}
            <span className={sharedClasses.fadedText}>(optional)</span>
          </Typography>
        </Box>
        {props.isEditing && (
          <TextField
            inputProps={{
              "data-testid": "additional-information-input"
            }}
            multiline={true}
            onChange={changeAdditionalInformation}
            value={formFields.additionalInformation}
          />
        )}
        {!props.isEditing && (
          <>
            {props.question.additionalInformation && (
              <Card elevation={0} style={{ display: "inline-block" }}>
                <CardContent style={{ padding: "12px 16px" }}>
                  <Typography data-testid="additional-information">
                    {props.question.additionalInformation}
                  </Typography>
                </CardContent>
              </Card>
            )}
            {!props.question.additionalInformation && (
              <Typography data-testid="additional-information">
                Not provided
              </Typography>
            )}
          </>
        )}
      </Box>
      <Box marginBottom={2}>
        <Typography
          className={sharedClasses.fadedText}
          component="h2"
          variant="h5"
        >
          Data captured from the question paper
        </Typography>
      </Box>
      {props.question.metadata &&
        Object.values(props.question.metadata).map((datum, index) => (
          <Box
            clone
            key={datum}
            marginRight={
              index === Object.values(props.question.metadata || []).length - 1
                ? 0
                : 1
            }
          >
            <Card elevation={0} style={{ display: "inline-block" }}>
              <CardContent style={{ padding: "12px 16px" }}>
                <Typography data-testid="metadata">{datum}</Typography>
              </CardContent>
            </Card>
          </Box>
        ))}
    </div>
  );
};

export default QuestionInfo;
