// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button, Divider } from "@cambridgeassessment/cambridge-ui";
import { Box, Dialog, IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";

interface Props {
  clickConfirmDelete: () => void;
  isOpen: boolean;
  setIsDeleteProjectDialogueOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setProjectIdToBeDeleted: React.Dispatch<React.SetStateAction<string>>;
}

const DeleteProjectDialogue: FC<Props> = (props): ReactElement => {
  const clickClose = (): void => {
    props.setIsDeleteProjectDialogueOpen(false);
  };

  const clickCancel = (): void => {
    clickClose();
    props.setProjectIdToBeDeleted("");
  };

  const clickDelete = (): void => {
    clickClose();
    props.clickConfirmDelete();
  };

  return (
    <Dialog
      onClose={clickClose}
      aria-labelledby="delete-project-dialogue-heading"
      open={props.isOpen}
      data-testid="delete-project-dialogue"
    >
      <Box padding={4}>
        <Box marginBottom={3}>
          <Box alignItems="center" display="flex" marginBottom={1}>
            <Typography
              component="h2"
              variant="h4"
              data-testid="delete-project-dialogue-heading"
            >
              Do you want to delete this project?
            </Typography>
            <Box clone marginLeft="auto">
              <IconButton
                aria-label="close"
                onClick={clickClose}
                data-testid="close-button"
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Typography>
            This action cannot be undone. Uploaded papers and harvested items
            will be removed.
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="flex-end" marginTop={3}>
          <Box clone marginRight={2}>
            <Button
              color="primary"
              onClick={clickCancel}
              variant="outlined"
              data-testid="cancel-button"
            >
              Cancel
            </Button>
          </Box>
          <Button
            color="destructive"
            disableElevation
            onClick={clickDelete}
            data-testid="delete-button"
            variant="contained"
          >
            Delete this project
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeleteProjectDialogue;
