// Core
import React, {
  ChangeEvent,
  FC,
  ReactElement,
  useEffect,
  useState
} from "react";

// Interfaces
import { Project } from "interfaces";

// Utils
import { useApi, useProject } from "utils/context";
import { sharedStyles } from "utils/theme";
import { useHistory } from "react-router-dom";

// Vendor
import { Box, Paper, Tab, Tabs } from "@material-ui/core";
import { Folder, Home } from "@material-ui/icons";

interface Props {
  children: ReactElement | ReactElement[];
}

const AdminLayout: FC<Props> = (props: Props): ReactElement => {
  const { getProjects, user } = useApi();
  const { fetchProjectsSuccess, projects } = useProject();
  const [getProjectsCalled, setGetProjectsCalled] = useState(false);
  const history = useHistory();
  const sharedClasses = sharedStyles();

  useEffect(() => {
    if (!user || user.role !== "admin") {
      history.push("/");
    }
  }, [history, user]);

  useEffect(() => {
    if (getProjectsCalled || projects.length) {
      return;
    }

    getProjects<Project[]>().then((response) => {
      fetchProjectsSuccess(response.data || []);
      setGetProjectsCalled(true);
    });
  }, [fetchProjectsSuccess, getProjects, getProjectsCalled, projects]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const changeLocationTab = (event: ChangeEvent<{}>, value: string): void => {
    history.push(value);
  };

  return (
    <div data-testid="admin-layout">
      <Box
        clone
        display="flex"
        justifyContent="flex-end"
        className={sharedClasses.bleed}
        marginTop="-47px"
        marginBottom={6}
      >
        <Paper elevation={0} style={{ borderBottom: "1px solid #e3e3e3" }}>
          <Tabs
            indicatorColor="primary"
            onChange={changeLocationTab}
            textColor="primary"
            value={history.location.pathname}
            data-testid="location-tabs"
          >
            <Tab
              label={
                <Box alignItems="center" display="flex">
                  <Box clone marginRight={1}>
                    <Home />
                  </Box>
                  Dashboard
                </Box>
              }
              value="/admin/dashboard"
              data-testid="dashboard-tab"
            />
            <Tab
              label={
                <Box alignItems="center" display="flex">
                  <Box clone marginRight={1}>
                    <Folder />
                  </Box>
                  Projects
                </Box>
              }
              value="/admin/projects"
              data-testid="projects-tab"
            />
          </Tabs>
        </Paper>
      </Box>
      {props.children}
    </div>
  );
};

export default AdminLayout;
