// Core
import React, { FC, ReactElement } from "react";

// Utils

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { useAuth } from "oidc-react";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";

interface LocationState {
  redirectSlug?: string;
}

const Home: FC<
  RouteComponentProps<undefined, StaticContext, LocationState>
> = (): ReactElement => {
  const auth = useAuth();

  const clickLogin = () => {
    auth?.signIn({
      data: { targetUrl: window.location.pathname + window.location.search }
    });
  };

  return (
    <div data-testid="home-page">
      <Box marginBottom={5} textAlign="center">
        <Typography variant="h3" data-testid="page-heading">
          Welcome to the Content Management Tool
        </Typography>
      </Box>
      <Grid container justify="center">
        <Grid item md={6}>
          <Card>
            <CardContent>
              {!auth?.userData ? (
                <Button
                  color="primary"
                  disableElevation
                  onClick={clickLogin}
                  data-testid="login-button"
                >
                  Login
                </Button>
              ) : null}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
