// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { colours, Divider } from "@cambridgeassessment/cambridge-ui";
import { Box, Typography } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

interface Props {
  heading: string;
  message: string;
  type: "error" | "warning";
}

const Message: FC<Props> = (props): ReactElement => {
  const colour = props.type === "error" ? colours.pdfRed : colours.informative;

  return (
    <div data-testid="message">
      <Box alignItems="center" display="flex" marginY={2}>
        <Box clone marginRight={2}>
          <InfoOutlined htmlColor={colour} />
        </Box>
        <Box clone marginRight={2}>
          <Typography
            variant="subtitle1"
            style={{
              color: colour
            }}
          >
            {props.heading}
          </Typography>
        </Box>
        <Typography
          style={{
            color: colour
          }}
        >
          {props.message}
        </Typography>
      </Box>
      <Divider />
    </div>
  );
};

export default Message;
