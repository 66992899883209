// Core
import React, { ChangeEvent, FC, ReactElement } from "react";

// Vendor
import { TextField } from "@cambridgeassessment/cambridge-ui";
import { Box, withStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

interface Props {
  changeHandler: (event: ChangeEvent<unknown>, options: string[]) => void;
  disabled: boolean;
  name: string;
  options: string[];
  value: string[];
}

const CustomTextField = withStyles(() => ({
  root: {
    marginBottom: 0,
    "& > .MuiInputBase-root": {
      height: "auto",
      marginBottom: 0
    },
    "& input": {
      padding: "10px 16px !important"
    }
  }
}))(TextField);

const QuestionInfoAutocomplete: FC<Props> = (props): ReactElement => {
  return (
    <Box marginBottom={5}>
      <Autocomplete
        disabled={props.disabled}
        filterSelectedOptions
        multiple
        onChange={props.changeHandler}
        options={props.options}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            inputProps={{
              ...params.inputProps,
              "data-testid": `${props.name}-input`
            }}
            placeholder="Select..."
          />
        )}
        value={props.value}
        data-testid={`${props.name}-autocomplete`}
      />
    </Box>
  );
};

export default QuestionInfoAutocomplete;
