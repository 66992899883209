// Core
import React, { FC, ReactElement, useEffect } from "react";

// Components
import EditProjectHeader from "components/editProjectHeader/EditProjectHeader";

// Utils
import { useProject } from "utils/context";

// Vendor
import { useHistory } from "react-router-dom";

interface Props {
  children: ReactElement | ReactElement[];
}

const EditProjectLayout: FC<Props> = (props: Props): ReactElement => {
  const { project } = useProject();
  const history = useHistory();

  useEffect(() => {
    if (!Object.keys(project).length) {
      return;
    }

    if (project.status !== "notStarted") {
      history.push("/admin/dashboard");
    }
  }, [history, project]);

  return (
    <div data-testid="edit-project-layout">
      <EditProjectHeader />
      {props.children}
    </div>
  );
};

export default EditProjectLayout;
