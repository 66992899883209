export const generateBase64 = (file: File): Promise<string> => {
  const reader = new FileReader();

  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject();
      }
    };

    reader.onerror = () => {
      reject();
    };
  });
};
