// Core
import React, { FC, ReactElement } from "react";

// Vendor
import { Button } from "@cambridgeassessment/cambridge-ui";
import { Box, Chip, Typography } from "@material-ui/core";
import { Description } from "@material-ui/icons";

interface Props {
  clickApprove: () => void;
  clickReject: () => void;
  clickViewRejectionReasons: () => void;
}

const RejectedHeader: FC<Props> = (props): ReactElement => {
  return (
    <Box
      alignItems="center"
      display="flex"
      width={1}
      data-testid="rejected-header"
    >
      <Box>
        <Box clone marginBottom={3}>
          <Chip color="secondary" label="Rejected item" variant="outlined" />
        </Box>
        <Box marginBottom={3}>
          <Box marginBottom={3}>
            <Typography>
              This item was rejected by the subject expert.
            </Typography>
          </Box>
          <Typography>
            Check if the extracted item matches the original item, and either
            reject or accept the item.
          </Typography>
        </Box>
        <Button
          color="primary"
          onClick={props.clickViewRejectionReasons}
          data-testid="view-rejection-reasons-button"
          startIcon={<Description />}
          variant="text"
        >
          View reasons for rejection
        </Button>
      </Box>
      <Box display="flex" flexDirection="column" marginLeft="auto">
        <Box display="flex" flexDirection="row" marginBottom={2}>
          <Box marginRight={2}>
            <Button
              color="destructive"
              disableElevation
              onClick={props.clickReject}
              variant="contained"
              data-testid="reject-button"
            >
              Reject this item
            </Button>
          </Box>
          <Button
            color="primary"
            disableElevation
            onClick={props.clickApprove}
            variant="outlined"
            data-testid="approve-button"
          >
            Accept this item and add info
          </Button>
        </Box>
        <Box clone textAlign="right">
          <Typography style={{ fontWeight: 600 }}>
            Please note that rejection is irreversible
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default RejectedHeader;
