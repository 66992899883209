// Core
import React, { FC, ReactElement, useEffect, useMemo, useState } from "react";

// Components
import ProjectSummary from "components/projectSummary/ProjectSummary";
import ProjectType from "pages/projects/edit/components/projectType/ProjectType";

// Interfaces
import { EditProjectFormFields, Project } from "interfaces";

// Utils
import { useApi, useProject } from "utils/context";
import { sharedStyles } from "utils/theme";

// Vendor
import { Button, TextField } from "@cambridgeassessment/cambridge-ui";
import DayjsUtils from "@date-io/dayjs";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  Typography
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import dayjs from "dayjs";
import { StaticContext } from "react-router";
import { RouteComponentProps, useHistory } from "react-router-dom";

interface LocationState {
  activeStep?: number;
}

const Edit: FC<RouteComponentProps<undefined, StaticContext, LocationState>> = (
  props
): ReactElement => {
  const { createProject, getProject, getProjects, updateProject } = useApi();
  const { createProjectSuccess, project, updateProjectSuccess } = useProject();
  const [activeApiCalls, setActiveApiCalls] = useState([] as string[]);
  const [activeStep, setActiveStep] = useState(0);
  const [
    areCompletionInstructionsMinLength,
    setAreCompletionInstructionsMinLength
  ] = useState(true);
  const [editingValue, setEditingValue] = useState(false);
  const [formFields, setFormFields] = useState({
    completionInstructions: "",
    projectDeadline: "" as EditProjectFormFields["projectDeadline"],
    projectDeadlineDate: new Date(),
    projectName: "",
    projectType: "" as EditProjectFormFields["projectType"]
  });
  const [isNewProject, setIsNewProject] = useState(true);
  const [isProjectNameDuplicated, setIsProjectNameDuplicated] = useState(false);
  const [isProjectNameMinLength, setIsProjectNameMinLength] = useState(true);
  const [isProjectNameSimilar, setIsProjectNameSimilar] = useState(false);
  const [projects, setProjects] = useState([] as { name: string }[]);
  const history = useHistory();
  const projectTypes = [
    "harvesting-past-papers",
    "harvesting-on-screen",
    "authoring"
  ];
  const summaryItems = useMemo(
    () => [
      {
        clickEdit: () => clickEdit(0),
        data: formFields.projectName,
        heading: "Project name"
      },
      {
        clickEdit: () => clickEdit(1),
        data: formFields.projectType,
        heading: "Project type"
      },
      {
        clickEdit: () => clickEdit(2),
        data: formFields.completionInstructions,
        heading: "Completion instructions"
      },
      {
        clickEdit: () => clickEdit(3),
        data:
          formFields.projectDeadline === "choose"
            ? dayjs(formFields.projectDeadlineDate).format("DD/MM/YYYY")
            : "None",
        heading: "Project timeline"
      }
    ],
    [formFields]
  );
  const sharedClasses = sharedStyles();

  useEffect(() => {
    getProjects<Project[]>({ select: "name" }).then((response) => {
      setProjects(response.data || []);
    });
  }, [getProjects]);

  useEffect(() => {
    if (!formFields.completionInstructions) {
      return;
    }

    setAreCompletionInstructionsMinLength(
      formFields.completionInstructions.length > 2
    );
  }, [formFields.completionInstructions, projects]);

  useEffect(() => {
    if (!formFields.projectName) {
      return;
    }

    setIsProjectNameDuplicated(
      projects.map((item) => item.name).includes(formFields.projectName)
    );
    setIsProjectNameMinLength(formFields.projectName.length > 2);
    setIsProjectNameSimilar(
      projects
        .map((item) => item.name.toLowerCase())
        .includes(formFields.projectName.toLowerCase())
    );
  }, [formFields.projectName, projects]);

  useEffect(() => {
    if (
      Object.keys(project).length &&
      history.location.pathname.includes(project.id)
    ) {
      setFormFields({
        completionInstructions: project.instructions,
        projectDeadline: project.targetEndDate ? "choose" : "none",
        projectDeadlineDate: project.targetEndDate
          ? dayjs(project.targetEndDate.split("T")[0]).toDate()
          : new Date(),
        projectName: project.name,
        projectType: project.type
      });

      setIsNewProject(false);
    } else {
      setIsNewProject(true);
    }
  }, [history.location.pathname, project]);

  useEffect(() => {
    if (!props.location.state || !props.location.state.activeStep) {
      return;
    }

    setActiveStep(props.location.state.activeStep);
  }, [props.location.state]);

  const changeCompletionInstructions = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormFields({
      ...formFields,
      completionInstructions: event.target.value
    });
  };

  const changeProjectDeadline = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value as "choose" | "none";

    setFormFields({
      ...formFields,
      projectDeadline: value
    });
  };

  const changeProjectDeadlineDate = (date: MaterialUiPickersDate) => {
    if (!date) {
      return;
    }

    setFormFields({
      ...formFields,
      projectDeadlineDate: dayjs(date).toDate()
    });
  };

  const changeProjectName = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormFields({
      ...formFields,
      projectName: event.target.value
    });
  };

  const clickComplete = (): void => {
    const body = {
      instructions: formFields.completionInstructions,
      name: formFields.projectName,
      targetEndDate:
        formFields.projectDeadline === "none"
          ? ""
          : dayjs(formFields.projectDeadlineDate).format(
              "YYYY-MM-DDTHH:mm:ssZZ[Z]"
            ),
      type: formFields.projectType
    };

    if (isNewProject) {
      setActiveApiCalls([...activeApiCalls, "createProject"]);

      createProject(body)
        .then((createProjectResponse) => {
          const url = createProjectResponse.headers.get("location") as string;

          getProject<Project>(
            url.slice(url.indexOf("/projects/") + "/projects/".length)
          ).then((response) => {
            createProjectSuccess(response.data || ({} as Project));

            history.push("/admin/projects");
          });
        })
        .catch(() => {
          setActiveApiCalls(
            activeApiCalls.filter((apiCall) => apiCall !== "createProject")
          );

          console.error();
        });
    } else {
      updateProject(project.id, {
        ...project,
        ...body
      })
        .then(() => {
          updateProjectSuccess({
            ...project,
            ...body
          });

          history.push(`/projects/${project.id}/edit`);
        })
        .catch(console.error);
    }
  };

  const clickContinue = (): void => {
    if (isNewProject) {
      setActiveStep(editingValue ? summaryItems.length : activeStep + 1);

      if (editingValue) {
        setEditingValue(false);
      }
    } else {
      clickComplete();
    }
  };

  const clickEdit = (index: number): void => {
    setActiveStep(index);
    setEditingValue(true);
  };

  const clickProjectType = (
    type: "authoring" | "harvesting-on-screen" | "harvesting-past-papers"
  ): void => {
    if (type === formFields.projectType) {
      return;
    }

    setFormFields({
      ...formFields,
      projectType: type
    });
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <div data-testid="edit-project-page">
        <Box marginTop="-47px" marginBottom={6}>
          <Card
            className={`${sharedClasses.bleed} ${sharedClasses.heroHeaderCard}`}
            square
            variant="outlined"
          >
            <CardContent className={sharedClasses.heroHeaderCardContent}>
              <Box marginBottom={5} textAlign="center">
                {isNewProject && (
                  <Box marginBottom={2}>
                    <Button
                      color="primary"
                      onClick={() => history.push("/admin/dashboard")}
                      data-testid="back-button"
                      startIcon={<ChevronLeft />}
                      variant="text"
                    >
                      Back to dashboard
                    </Button>
                  </Box>
                )}
                <Typography variant="h3" data-testid="page-heading">
                  {isNewProject ? "Set up a new" : "Edit"} project
                </Typography>
                {activeStep === summaryItems.length && (
                  <Box marginTop={1}>
                    <Typography
                      className={sharedClasses.fadedText}
                      variant="h4"
                      data-testid="page-subheading"
                    >
                      Confirmation
                    </Typography>
                  </Box>
                )}
              </Box>
              {isNewProject && activeStep < summaryItems.length && (
                <Box>
                  <Grid container justify="center">
                    <Grid item xs={12} md={8}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {summaryItems.map((summaryItem) => (
                          <Step key={summaryItem.heading}>
                            <StepLabel
                              optional={
                                summaryItem.heading === "Project timeline" ? (
                                  <Box fontWeight={700} textAlign="center">
                                    Optional
                                  </Box>
                                ) : undefined
                              }
                            >
                              <Box fontWeight={700}>{summaryItem.heading}</Box>
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
        <Grid container justify="center">
          <Grid item md={6} data-testid={`step-${activeStep + 1}`}>
            {activeStep === 0 && (
              <Card data-testid="project-name-step">
                <CardContent>
                  <Box marginBottom={4}>
                    <Box marginBottom={1}>
                      <Typography variant="h4">
                        Type your project&rsquo;s name
                      </Typography>
                    </Box>
                    <Grid item md={8}>
                      <Typography>
                        Project name should be unique and clear to the Subject
                        Experts and the Approver on the project.
                      </Typography>
                    </Grid>
                  </Box>
                  <TextField
                    helperText={
                      !isProjectNameMinLength
                        ? "Must be at least three characters long"
                        : isProjectNameDuplicated
                        ? "This project name already exists"
                        : isProjectNameSimilar
                        ? "This project name is very similar to an existing one"
                        : undefined
                    }
                    inputProps={{
                      "data-testid": "project-name-input"
                    }}
                    onChange={changeProjectName}
                    validationType={
                      !isProjectNameMinLength
                        ? "error"
                        : isProjectNameDuplicated || isProjectNameSimilar
                        ? "warning"
                        : undefined
                    }
                    value={formFields.projectName}
                  />
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    disableElevation
                    disabled={
                      !formFields.projectName || !isProjectNameMinLength
                    }
                    onClick={clickContinue}
                    data-testid="continue-button"
                  >
                    {isNewProject ? "Continue" : "Save changes"}
                  </Button>
                </CardActions>
              </Card>
            )}
            {activeStep === 1 && (
              <div data-testid="project-type-step">
                <Box marginBottom={3}>
                  <Typography variant="h4">
                    Select your project&rsquo;s type
                  </Typography>
                </Box>
                {projectTypes.map((projectType) => (
                  <ProjectType
                    active={formFields.projectType === projectType}
                    clickProjectType={clickProjectType}
                    projectType={
                      projectType as EditProjectFormFields["projectType"]
                    }
                    key={projectType}
                  />
                ))}
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    color="primary"
                    disableElevation
                    disabled={!formFields.projectType}
                    onClick={clickContinue}
                    data-testid="continue-button"
                  >
                    {isNewProject ? "Continue" : "Save changes"}
                  </Button>
                </Box>
              </div>
            )}
            {activeStep === 2 && (
              <Card data-testid="completion-instructions-step">
                <CardContent>
                  <Box marginBottom={4}>
                    <Box marginBottom={1}>
                      <Typography variant="h4">
                        Type completion instructions
                      </Typography>
                    </Box>
                    <Grid item md={8}>
                      <Typography>
                        This should summarise the subject expert’s task. It
                        should include enough detail for the subject expert to
                        be able to complete the task.
                      </Typography>
                    </Grid>
                  </Box>
                  <TextField
                    helperText={
                      !areCompletionInstructionsMinLength
                        ? "Must be at least three characters long"
                        : undefined
                    }
                    inputProps={{
                      "data-testid": "completion-instructions-input"
                    }}
                    multiline={true}
                    onChange={changeCompletionInstructions}
                    validationType={
                      !areCompletionInstructionsMinLength ? "error" : undefined
                    }
                    value={formFields.completionInstructions}
                  />
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    disableElevation
                    disabled={
                      !formFields.completionInstructions ||
                      !areCompletionInstructionsMinLength
                    }
                    onClick={clickContinue}
                    data-testid="continue-button"
                  >
                    {isNewProject ? "Continue" : "Save changes"}
                  </Button>
                </CardActions>
              </Card>
            )}
            {activeStep === 3 && (
              <Card data-testid="project-deadline-step">
                <CardContent>
                  <Box marginBottom={4}>
                    <Box marginBottom={1}>
                      <Typography variant="h4">
                        Choose a timeline for project completion
                      </Typography>
                    </Box>
                    <Grid item md={8}>
                      <Typography>
                        If applicable please select a date for completion of the
                        project. You can change the date later if required.
                      </Typography>
                    </Grid>
                  </Box>
                  <RadioGroup
                    aria-label="project-deadline"
                    name="project-deadline"
                    onChange={changeProjectDeadline}
                    value={formFields.projectDeadline}
                  >
                    <FormControlLabel
                      value="none"
                      control={
                        <Radio
                          color="primary"
                          inputProps={
                            {
                              "data-testid": "project-deadline-none-radio"
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            } as any
                          }
                        />
                      }
                      label="No timeline"
                    />
                    <FormControlLabel
                      value="choose"
                      control={
                        <Radio
                          color="primary"
                          inputProps={
                            {
                              "data-testid": "project-deadline-choose-radio"
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            } as any
                          }
                        />
                      }
                      label="Choose a deadline date for the project"
                    />
                  </RadioGroup>
                  {formFields.projectDeadline === "choose" && (
                    <KeyboardDatePicker
                      autoOk={true}
                      disableToolbar
                      format="DD/MM/YYYY"
                      id="date-picker-inline"
                      inputProps={{
                        "data-testid": "project-deadline-date-input"
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      margin="normal"
                      onChange={changeProjectDeadlineDate}
                      value={formFields.projectDeadlineDate}
                      variant="inline"
                    />
                  )}
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    disableElevation
                    disabled={!formFields.projectDeadline}
                    onClick={clickContinue}
                    data-testid="finish-button"
                  >
                    {isNewProject ? "Finish" : "Save changes"}
                  </Button>
                </CardActions>
              </Card>
            )}
            {activeStep === summaryItems.length && (
              <ProjectSummary
                clickComplete={clickComplete}
                isCompleteDisabled={activeApiCalls.includes("createProject")}
                items={summaryItems}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default Edit;
