// Core
import React, { FC, ReactElement, useEffect, useRef, useState } from "react";

// Components
import ApprovedHeader from "pages/subjectExpertTask/components/approvedHeader/ApprovedHeader";
import ChoiceInteraction from "components/qti/choiceInteraction/ChoiceInteraction";
import InitialHeader from "pages/subjectExpertTask/components/initialHeader/InitialHeader";
import LoadingDialogue from "components/loadingDialogue/LoadingDialogue";
import Message from "pages/subjectExpertTask/components/message/Message";
import PdfPreview from "components/pdfPreview/PdfPreview";
import QuestionInfo from "components/questionInfo/QuestionInfo";
import RejectDialogue from "components/rejectDialogue/RejectDialogue";
import RejectOrEditDialogue from "pages/subjectExpertTask/components/rejectOrEditDialogue/RejectOrEditDialogue";
import TaskHeader from "components/taskHeader/TaskHeader";

// Interfaces
import { Question, Task } from "interfaces";

// Utils
import { useApi } from "utils/context";

// Vendor
import { Button, colours, Divider } from "@cambridgeassessment/cambridge-ui";
import { Box, Grid, Typography } from "@material-ui/core";
import { Edit, Save } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";
import * as Scroll from "react-scroll";

const SubjectExpertTask: FC = (): ReactElement => {
  const {
    getQuestion,
    getTask,
    rejectQuestion,
    updateQuestion,
    updateQuestionStatus,
    user
  } = useApi();
  const [activeApproval, setActiveApproval] = useState(
    "" as "" | "prompt" | "responses" | "stimulus"
  );
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [hasPrompt, setHasPrompt] = useState(false);
  const [hasResponses, setHasResponses] = useState(false);
  const [hasStimulusItems, setHasStimulusItems] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isRejectDialogueOpen, setIsRejectDialogueOpen] = useState(false);
  const [isRejectOrEditDialogueOpen, setIsRejectOrEditDialogueOpen] = useState(
    false
  );
  const [isRejecting, setIsRejecting] = useState({
    shouldFadeIn: false,
    value: false
  });
  const [isSubmitting, setIsSubmitting] = useState({
    shouldFadeIn: false,
    value: false
  });
  const [metadataChanged, setMetadataChanged] = useState(false);
  const [question, setQuestion] = useState({} as Question);
  const [questionContainerHeight, setQuestionContainerHeight] = useState(0);
  const [task, setTask] = useState({} as Task);
  const history = useHistory();
  const { taskId } = useParams<Record<string, string | undefined>>();
  const { questionKey } = useParams<Record<string, string | undefined>>();
  const extractedItemHeader = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!user || user.role !== "examiner") {
      history.push("/");
    }
  }, [history, user]);

  useEffect(() => {
    if (!taskId) {
      return;
    }

    getTask<Task>(taskId).then((response) => {
      setTask(response.data || ({} as Task));
    }, console.error);
  }, [getTask, taskId]);

  useEffect(() => {
    if (!Object.keys(question).length) {
      return;
    }

    setQuestionContainerHeight(
      window.innerHeight -
        ((extractedItemHeader.current?.offsetTop || 0) +
          (extractedItemHeader.current?.offsetHeight || 0) +
          16 +
          48)
    );
  }, [hasPrompt, hasResponses, hasStimulusItems, question]);

  useEffect(() => {
    if (!Object.keys(question).length || !question.content) {
      return;
    }

    setHasPrompt(!!question.content.prompt.content);
    setHasResponses(
      question.content.responses.items.every((item) => item.content)
    );
    setHasStimulusItems(
      question.content.stimulus.items.some((item) => item.content) ||
        (question.content.stimulus.list !== undefined &&
          question.content.stimulus.list.length > 0) ||
        question.content.responses.table !== undefined
    );

    Scroll.scroller.scrollTo("question-container", {
      containerId: "question-container",
      duration: 1000,
      smooth: true
    });
  }, [question]);

  useEffect(() => {
    if (!Object.keys(task).length || !questionKey) {
      return;
    }

    getQuestion<Question>(questionKey).then((response) => {
      setIsSubmitting({
        shouldFadeIn: false,
        value: false
      });

      setQuestion(response.data || ({} as Question));
    }, console.error);
  }, [getQuestion, questionKey, task]);

  const clickApprove = (): void => {
    setIsEditing(false);

    updateQuestionStatus("approved", question.key)
      .then(() => {
        setQuestion({ ...question, status: "approved" });
      })
      .catch(console.error);
  };

  const clickBack = (): void => {
    const {
      additionalInformation,
      difficulty,
      metadata,
      skills,
      topics
    } = question;

    if (metadataChanged) {
      const body = {
        additionalInformation,
        difficulty,
        metadata,
        skills,
        topics
      };

      updateQuestion(question.key, body)
        .catch(console.error)
        .finally(() => {
          history.push("/examiner/dashboard");
        });

      return;
    }

    history.push("/examiner/dashboard");
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const clickConfirmReject = (body: any): void => {
    setIsRejecting({
      shouldFadeIn: true,
      value: true
    });

    body["ragStatus"] = question.ragStatus;

    rejectQuestion(question.key, body)
      .then(() => {
        setTimeout(() => {
          incrementQuestion(setIsRejecting);
        }, 2000);
      })
      .catch(() => {
        setIsRejecting({ shouldFadeIn: false, value: true });

        console.error();
      });
  };

  const clickDisapprove = (): void => {
    setIsEditing(false);
    setIsRejectOrEditDialogueOpen(true);
  };

  const clickEdit = (): void => {
    setIsEditing(true);
  };

  const clickReject = (): void => {
    setIsEditing(false);
    setIsRejectDialogueOpen(true);
  };

  const clickSave = (): void => {
    setIsEditing(false);
  };

  const clickSubmit = (): void => {
    setIsSubmitting({
      shouldFadeIn: true,
      value: true
    });

    const {
      additionalInformation,
      difficulty,
      metadata,
      skills,
      topics
    } = question;

    const body = {
      additionalInformation,
      difficulty,
      metadata,
      skills,
      topics
    };

    updateQuestion(question.key, body)
      .then(() => {
        updateQuestionStatus("metadata-added", question.key)
          .catch(console.error)
          .finally(() => {
            setTimeout(() => {
              incrementQuestion(setIsSubmitting);
            }, 2000);
          });
      })
      .catch(console.error);
  };

  const incrementQuestion = (
    action: React.Dispatch<
      React.SetStateAction<{
        shouldFadeIn: boolean;
        value: boolean;
      }>
    >
  ): void => {
    if (activeQuestionIndex === task.pendingQuestions.length - 1) {
      history.push("/examiner/dashboard");
    } else {
      action({ shouldFadeIn: false, value: true });

      history.push(
        `/subject-expert/tasks/${taskId}/questions/${
          task.pendingQuestions[activeQuestionIndex + 1]
        }`
      );

      setActiveQuestionIndex(activeQuestionIndex + 1);

      setMetadataChanged(false);
    }
  };

  const renderQuestion = (item: Question): ReactElement => {
    if (!item || !item.content) {
      return <></>;
    }

    if (item.content.qtiInteractionType === "choiceInteraction") {
      return (
        <ChoiceInteraction
          activeApproval={activeApproval}
          content={item.content}
          hasPrompt={hasPrompt}
          hasResponses={hasResponses}
          isEditing={isEditing}
          updateQuestion={updateEditedQuestion}
        />
      );
    } else {
      return <></>;
    }
  };

  const updateEditedQuestion = (body: Record<string, unknown>) => {
    body.metadata = question.metadata;
    body.skills = question.skills;
    body.topics = question.topics;

    return updateQuestion(questionKey as string, body)
      .then(() => {
        getQuestion<Question>(questionKey as string).then((response) => {
          setQuestion(response.data || ({} as Question));
        }, console.error);
      })
      .catch(console.error);
  };

  return (
    <div data-testid="subject-expert-task-page">
      {isRejecting.value && (
        <LoadingDialogue
          body="Item has been rejected"
          heading="Thank you"
          shouldFadeIn={isRejecting.shouldFadeIn}
        />
      )}
      {isSubmitting.value && (
        <LoadingDialogue
          body="Item submitted for approval"
          heading="Thank you"
          shouldFadeIn={isSubmitting.shouldFadeIn}
        />
      )}
      <RejectDialogue
        clickConfirmReject={clickConfirmReject}
        isOpen={isRejectDialogueOpen}
        setIsRejectDialogueOpen={setIsRejectDialogueOpen}
      />
      <RejectOrEditDialogue
        clickEdit={clickEdit}
        clickReject={clickReject}
        isOpen={isRejectOrEditDialogueOpen}
        setIsRejectOrEditDialogueOpen={setIsRejectOrEditDialogueOpen}
      />
      {Object.keys(task).length > 0 && (
        <>
          <TaskHeader
            activeQuestionIndex={activeQuestionIndex}
            clickBack={clickBack}
            task={task}
          />
          <Box alignItems="start" display="flex" marginBottom={4}>
            {question.status === "initial" && (
              <InitialHeader
                clickApprove={clickApprove}
                clickDisapprove={clickDisapprove}
                isYesDisabled={
                  (activeApproval === "prompt" && !hasPrompt) ||
                  (activeApproval === "responses" && !hasResponses)
                }
                questionKey={question.key}
                setActiveApproval={setActiveApproval}
              />
            )}
            {question.status === "approved" && (
              <ApprovedHeader
                clickSubmit={clickSubmit}
                isSubmitDisabled={
                  isSubmitting.value ||
                  !question.skills.length ||
                  !question.topics.length
                }
              />
            )}
          </Box>
          <Divider />
          {activeApproval === "stimulus" && !hasStimulusItems && (
            <Message
              heading="No stimulus"
              message="Add a stimulus if there is one in the original item."
              type="warning"
            />
          )}
          {activeApproval === "prompt" && !hasPrompt && (
            <Message
              heading="No prompt"
              message="We have not detected a prompt. Add the prompt to submit this item."
              type="error"
            />
          )}
          {activeApproval === "responses" && !hasResponses && (
            <Message
              heading="Incorrect number of responses"
              message="Add missing responses to submit this item."
              type="error"
            />
          )}
          <Box marginTop={5}>
            <Grid container spacing={10}>
              <Grid item xs={6}>
                <div ref={extractedItemHeader}>
                  <Box
                    alignItems="center"
                    bgcolor={colours.backgroundGrey}
                    display="flex"
                    marginBottom={2}
                    zIndex={1}
                  >
                    <Typography component="h2" variant="h5">
                      Extracted item
                    </Typography>
                    {question.status === "initial" && (
                      <Box marginLeft="auto">
                        {isEditing && (
                          <Button
                            color="primary"
                            onClick={clickSave}
                            data-testid="save-button"
                            startIcon={<Save />}
                            variant="text"
                          >
                            Save changes
                          </Button>
                        )}
                        {!isEditing && (
                          <Button
                            color="primary"
                            onClick={clickEdit}
                            data-testid="edit-button"
                            startIcon={<Edit />}
                            variant="text"
                          >
                            Edit item
                          </Button>
                        )}
                      </Box>
                    )}
                  </Box>
                </div>
                <Scroll.Element name="question-container">
                  <div
                    className="question-container"
                    id="question-container"
                    style={{
                      height:
                        question.status === "initial"
                          ? questionContainerHeight
                          : "auto",
                      overflow: "auto",
                      paddingRight: question.status === "initial" ? "5px" : 0
                    }}
                  >
                    {renderQuestion(question)}
                  </div>
                </Scroll.Element>
              </Grid>
              <Grid item xs={6}>
                {question.status === "initial" && (
                  <>
                    <Box marginBottom={2}>
                      <Typography component="h2" variant="h5">
                        Original item
                      </Typography>
                    </Box>
                    <PdfPreview url={question.pdfUrl} />
                  </>
                )}
                {question.status === "approved" && (
                  <QuestionInfo
                    isEditing={true}
                    question={question}
                    setMetadataChanged={setMetadataChanged}
                    setQuestion={setQuestion}
                    syllabusCode={task.project.syllabusCode}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </div>
  );
};

export default SubjectExpertTask;
