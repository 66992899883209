// Core
import React, { FC, ReactElement, useCallback, useMemo } from "react";

// Components
import Dropzone from "components/dropzone/Dropzone";
import Tooltip from "components/tooltip/Tooltip";

// Interfaces
import { Job } from "interfaces";

// Utils
import { useApi, useProject } from "utils/context";
import { validCIFilenameRegEx, validOCRFilenameRegEx } from "utils/utils";

// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { Publish } from "@material-ui/icons";
import { FileRejection } from "react-dropzone";

interface Props {
  setActiveStep: React.Dispatch<React.SetStateAction<"initial" | "summary">>;
  setInvalidFiles: React.Dispatch<
    React.SetStateAction<{
      existing: File[];
      invalid: File[];
      rejected: File[];
    }>
  >;
  setValidFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const Initial: FC<Props> = (props): ReactElement => {
  const { setActiveStep, setInvalidFiles, setValidFiles } = props;
  const { getJobs } = useApi();
  const { project } = useProject();

  const processReceivedFiles = useCallback(
    function processReceivedFiles(
      acceptedFiles: File[],
      fileRejections: FileRejection[]
    ) {
      if (!acceptedFiles.length && !fileRejections.length) {
        return;
      }

      setActiveStep("summary");

      if (acceptedFiles.length) {
        Promise.all(
          acceptedFiles.map((file) =>
            getJobs<Job>({
              index: file.name
                .replace("_ms", "")
                .replace("_qp", "")
                .replace(".pdf", "")
            })
          )
        ).then((response) => {
          const existing = [
            ...new Set(
              response
                .map((res) => res.data || [])
                .flat()
                .flatMap((res) => [res.markScheme, res.questionPaper])
            )
          ];

          setInvalidFiles({
            existing: acceptedFiles.filter((file) =>
              existing.includes(file.name)
            ),
            invalid: acceptedFiles.filter(
              (file) =>
                (!file.name.match(validCIFilenameRegEx) &&
                  !file.name.match(validOCRFilenameRegEx)) ||
                !file.name.includes(project.syllabusCode)
            ),
            rejected: fileRejections.map((rejection) => rejection.file)
          });

          setValidFiles(
            acceptedFiles.filter(
              (file) =>
                !existing.includes(file.name) &&
                (file.name.match(validCIFilenameRegEx) ||
                  file.name.match(validOCRFilenameRegEx)) &&
                file.name.includes(project.syllabusCode)
            )
          );
        });
      } else {
        setTimeout(() => {
          setInvalidFiles({
            existing: [],
            invalid: [],
            rejected: fileRejections.map((rejection) => rejection.file)
          });
        }, 1);
      }
    },
    [
      getJobs,
      project.syllabusCode,
      setActiveStep,
      setInvalidFiles,
      setValidFiles
    ]
  );

  const processReceivedFilesMemoised = useMemo(() => {
    return (acceptedFiles: File[], rejectedFiles: FileRejection[]): void => {
      processReceivedFiles(acceptedFiles, rejectedFiles);
    };
  }, [processReceivedFiles]);

  return (
    <div data-testid="upload-initial">
      <Box display="flex" marginBottom={4}>
        <Box>
          <Box display="flex" marginBottom={1}>
            <Typography component="h2" variant="h4" data-testid="page-heading">
              Upload question papers and mark schemes for {project.syllabusCode}
            </Typography>
            <Tooltip title="To upload papers for another syllabus please go back to the previous step and change your code." />
          </Box>
          <Typography data-testid="page-introduction">
            Every question paper must have a corresponding mark scheme
          </Typography>
        </Box>
      </Box>
      <Card>
        <CardContent>
          <Box display="flex" minHeight={160}>
            <Dropzone
              accept="application/pdf"
              isUploading={false}
              receivedFilesCallback={processReceivedFilesMemoised}
            >
              <Box paddingY={4} textAlign="center">
                <Box marginBottom={2}>
                  <Publish
                    htmlColor={colours.monochromeLight}
                    fontSize="large"
                  />
                </Box>
                <Box marginBottom={2}>
                  <Typography
                    component="p"
                    display="inline"
                    variant="subtitle1"
                  >
                    Drag and drop files here to upload or{" "}
                  </Typography>
                  <Typography
                    color="primary"
                    component="p"
                    display="inline"
                    variant="subtitle1"
                  >
                    select from your computer
                  </Typography>
                </Box>
                <Typography>Supported file types: PDF (.pdf)</Typography>
              </Box>
            </Dropzone>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default Initial;
