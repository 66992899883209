// Core
import React, { FC, ReactElement, useEffect } from "react";

// Utils
import { useApi } from "utils/context";

// Vendor
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";

interface LocationState {
  redirectSlug: string;
}

const Authorise: FC<
  RouteComponentProps<undefined, StaticContext, LocationState>
> = (props): ReactElement => {
  const { token } = useApi();

  useEffect((): void => {
    props.history.push(
      token
        ? props.location.state.redirectSlug
        : {
            pathname: "/",
            state: { redirectSlug: props.location.state.redirectSlug }
          }
    );
  }, [props.history, props.location.state.redirectSlug, token]);

  return <div data-testid="authorise-page"></div>;
};

export default Authorise;
