// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Components
import ApprovedHeader from "pages/approverTask/components/approvedHeader/ApprovedHeader";
import ChoiceInteraction from "components/qti/choiceInteraction/ChoiceInteraction";
import LoadingDialogue from "components/loadingDialogue/LoadingDialogue";
import MetadataAddedHeader from "pages/approverTask/components/metadataAddedHeader/MetadataAddedHeader";
import PdfPreview from "components/pdfPreview/PdfPreview";
import QuestionInfo from "components/questionInfo/QuestionInfo";
import RejectDialogue from "components/rejectDialogue/RejectDialogue";
import RejectedHeader from "pages/approverTask/components/rejectedHeader/RejectedHeader";
import RejectionReasonsDialogue from "pages/approverTask/components/rejectionReasonsDialogue/RejectionReasonsDialogue";
import TaskHeader from "components/taskHeader/TaskHeader";

// Interfaces
import { Question, Task } from "interfaces";

// Utils
import { useApi } from "utils/context";

// Vendor
import { Button, colours, Divider } from "@cambridgeassessment/cambridge-ui";
import { Box, Grid, Typography } from "@material-ui/core";
import { Edit, ErrorOutline, Save } from "@material-ui/icons";
import { useHistory, useParams } from "react-router-dom";

const ApproverTask: FC = (): ReactElement => {
  const {
    bankQuestion,
    getQuestion,
    getTask,
    rejectQuestion,
    updateQuestion,
    updateQuestionStatus,
    user
  } = useApi();
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isBanking, setIsBanking] = useState({
    shouldFadeIn: false,
    value: false
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingInfo, setIsEditingInfo] = useState(false);
  const [isRejectDialogueOpen, setIsRejectDialogueOpen] = useState(false);
  const [isRejecting, setIsRejecting] = useState({
    shouldFadeIn: false,
    value: false
  });
  const [
    isRejectionReasonsDialogueOpen,
    setIsRejectionReasonsDialogueOpen
  ] = useState(false);
  const [metadataChanged, setMetadataChanged] = useState(false);
  const [question, setQuestion] = useState({} as Question);
  const [task, setTask] = useState({} as Task);
  const history = useHistory();
  const { questionKey } = useParams<Record<string, string | undefined>>();
  const { taskId } = useParams<Record<string, string | undefined>>();

  useEffect(() => {
    if (!user || user.role !== "examiner") {
      history.push("/");
    }
  }, [history, user]);

  useEffect(() => {
    if (!taskId) {
      return;
    }

    getTask<Task>(taskId).then((response) => {
      setTask(response.data || ({} as Task));
    }, console.error);
  }, [getTask, taskId]);

  useEffect(() => {
    if (!Object.keys(task).length || !questionKey) {
      return;
    }

    getQuestion<Question>(questionKey).then((response) => {
      setIsAccepted(false);

      setIsEditingInfo(response.data?.status === "approved" ? true : false);

      setIsBanking({
        shouldFadeIn: false,
        value: false
      });

      setQuestion(response.data || ({} as Question));
    }, console.error);
  }, [getQuestion, questionKey, task]);

  const clickAccept = (): void => {
    setIsAccepted(true);
  };

  const clickApprove = (): void => {
    updateQuestionStatus("approved", question.key)
      .then(() => {
        setIsEditingInfo(true);
        setQuestion({ ...question, status: "approved" });
      })
      .catch(console.error);
  };

  const clickBack = (): void => {
    const {
      additionalInformation,
      difficulty,
      metadata,
      skills,
      topics
    } = question;

    if (metadataChanged) {
      const body = {
        additionalInformation,
        difficulty,
        metadata,
        skills,
        topics
      };

      updateQuestion(question.key, body)
        .catch(console.error)
        .finally(() => {
          history.push("/examiner/dashboard");
        });

      return;
    }

    history.push("/examiner/dashboard");
  };

  const clickBank = (): void => {
    setIsBanking({
      shouldFadeIn: true,
      value: true
    });

    bankQuestion(question.key)
      .then(() => {
        setTimeout(() => {
          incrementQuestion(setIsBanking);
        }, 2000);
      })
      .catch(() => {
        setIsBanking({ shouldFadeIn: false, value: true });

        console.error();
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const clickConfirmReject = (body: any): void => {
    setIsRejecting({
      shouldFadeIn: true,
      value: true
    });

    body["ragStatus"] = question.ragStatus;

    rejectQuestion(question.key, body)
      .then(() => {
        setTimeout(() => {
          incrementQuestion(setIsRejecting);
        }, 2000);
      })
      .catch(() => {
        setIsRejecting({ shouldFadeIn: false, value: true });

        console.error();
      });
  };

  const clickEdit = (): void => {
    setIsEditing(true);
  };

  const clickEditInfo = (): void => {
    setIsEditingInfo(true);
  };

  const clickReject = (): void => {
    setIsRejectDialogueOpen(true);
  };

  const clickSave = (): void => {
    setIsEditing(false);
  };

  const clickSaveInfo = (bank: boolean): void => {
    const {
      additionalInformation,
      difficulty,
      metadata,
      skills,
      topics
    } = question;

    const body = {
      additionalInformation,
      difficulty,
      metadata,
      skills,
      topics
    };

    updateQuestion(question.key, body)
      .then(() => {
        setIsEditingInfo(false);

        if (bank) {
          updateQuestionStatus("metadata-added", question.key)
            .then(() => {
              clickBank();
            })
            .catch(console.error);
        }
      })
      .catch(console.error);
  };

  const clickViewRejectionReasons = (): void => {
    setIsRejectionReasonsDialogueOpen(true);
  };

  const incrementQuestion = (
    action: React.Dispatch<
      React.SetStateAction<{
        shouldFadeIn: boolean;
        value: boolean;
      }>
    >
  ): void => {
    if (activeQuestionIndex === task.pendingQuestions.length - 1) {
      history.push("/examiner/dashboard");
    } else {
      action({ shouldFadeIn: false, value: true });

      history.push(
        `/approver/tasks/${taskId}/questions/${
          task.pendingQuestions[activeQuestionIndex + 1]
        }`
      );

      setActiveQuestionIndex(activeQuestionIndex + 1);

      setMetadataChanged(false);
    }
  };

  const renderQuestion = (item: Question): ReactElement => {
    if (!item || !item.content) {
      return <></>;
    }

    if (item.content.qtiInteractionType === "choiceInteraction") {
      return (
        <ChoiceInteraction
          content={item.content}
          isEditing={isEditing}
          updateQuestion={updateEditedQuestion}
        />
      );
    } else {
      return <></>;
    }
  };

  const updateEditedQuestion = (body: Record<string, unknown>) => {
    body.metadata = question.metadata;
    body.skills = question.skills;
    body.topics = question.topics;

    return updateQuestion(questionKey as string, body)
      .then(() => {
        getQuestion<Question>(questionKey as string).then((response) => {
          setQuestion(response.data || ({} as Question));
        }, console.error);
      })
      .catch(console.error);
  };

  return (
    <div data-testid="approver-task-page">
      {isRejecting.value && (
        <LoadingDialogue
          body="Item has been rejected"
          heading="Thank you"
          shouldFadeIn={isRejecting.shouldFadeIn}
        />
      )}
      {isBanking.value && (
        <LoadingDialogue
          body="Item is being banked"
          heading="Thank you"
          shouldFadeIn={isBanking.shouldFadeIn}
        />
      )}
      <RejectDialogue
        clickConfirmReject={clickConfirmReject}
        isOpen={isRejectDialogueOpen}
        setIsRejectDialogueOpen={setIsRejectDialogueOpen}
      />
      <RejectionReasonsDialogue
        isOpen={isRejectionReasonsDialogueOpen}
        rejectionExplanation={question.rejectionExplanation}
        rejectionReasons={(question.rejectionReasons as string[]) || []}
        setIsOpen={setIsRejectionReasonsDialogueOpen}
      />
      {Object.keys(task).length > 0 && (
        <>
          <TaskHeader
            activeQuestionIndex={activeQuestionIndex}
            clickBack={clickBack}
            task={task}
          />
          <Box alignItems="start" display="flex" marginBottom={5}>
            {question.status === "metadataAdded" && (
              <MetadataAddedHeader
                clickAccept={clickAccept}
                clickBank={clickBank}
                clickReject={clickReject}
                clickSaveInfo={() => clickSaveInfo(false)}
                isAccepted={isAccepted}
                isEditingInfo={isEditingInfo}
                setIsEditingInfo={setIsEditingInfo}
              />
            )}
            {question.status === "rejectedBySubjectExpert" && (
              <RejectedHeader
                clickApprove={clickApprove}
                clickReject={() => {
                  const body = {
                    rejectionReasons: question.rejectionReasons
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  } as any;

                  if (question.rejectionExplanation) {
                    body["rejectionExplanation"] =
                      question.rejectionExplanation;
                  }

                  clickConfirmReject(body);
                }}
                clickViewRejectionReasons={clickViewRejectionReasons}
              />
            )}
            {question.status === "approved" && (
              <ApprovedHeader
                clickBank={() => clickSaveInfo(true)}
                clickReject={clickReject}
                isBankDisabled={
                  isBanking.value ||
                  !question.skills.length ||
                  !question.topics.length
                }
              />
            )}
          </Box>
          <Divider />
          <Box marginTop={5}>
            <Grid container spacing={10}>
              <Grid item xs={6}>
                <Box
                  alignItems="center"
                  bgcolor={colours.backgroundGrey}
                  display="flex"
                  marginBottom={2}
                  position="sticky"
                  top={0}
                  zIndex={1}
                >
                  <Typography component="h2" variant="h5">
                    Extracted item
                  </Typography>
                  {(question.status === "approved" ||
                    (question.status === "metadataAdded" && !isAccepted)) && (
                    <Box marginLeft="auto">
                      {isEditing && (
                        <Button
                          color="primary"
                          onClick={clickSave}
                          data-testid="save-button"
                          startIcon={<Save />}
                          variant="text"
                        >
                          Save changes
                        </Button>
                      )}
                      {!isEditing && (
                        <Button
                          color="primary"
                          onClick={clickEdit}
                          data-testid="edit-button"
                          startIcon={<Edit />}
                          variant="text"
                        >
                          Edit item
                        </Button>
                      )}
                    </Box>
                  )}
                </Box>
                {question.isEdited && (
                  <Box alignItems="center" display="flex" marginBottom={2}>
                    <Box clone marginRight={1}>
                      <ErrorOutline
                        fontSize="small"
                        htmlColor={colours.pdfRed}
                      />
                    </Box>
                    <Typography
                      color="error"
                      display="inline"
                      data-testid="edited-message"
                    >
                      This item was edited
                    </Typography>
                  </Box>
                )}
                {renderQuestion(question)}
              </Grid>
              <Grid item xs={6}>
                {((question.status === "metadataAdded" && !isAccepted) ||
                  question.status === "rejectedBySubjectExpert") && (
                  <>
                    <Box marginBottom={2}>
                      <Typography component="h2" variant="h5">
                        Original item
                      </Typography>
                    </Box>
                    <PdfPreview url={question.pdfUrl} />
                  </>
                )}
                {question.status === "metadataAdded" &&
                  isAccepted &&
                  !isEditingInfo && (
                    <Box marginBottom={4}>
                      <Button
                        color="primary"
                        onClick={clickEditInfo}
                        data-testid="edit-info-button"
                        startIcon={<Edit />}
                        variant="text"
                      >
                        Amend item info
                      </Button>
                    </Box>
                  )}
                {((question.status === "metadataAdded" && isAccepted) ||
                  question.status === "approved") && (
                  <QuestionInfo
                    isEditing={isEditingInfo}
                    question={question}
                    setMetadataChanged={setMetadataChanged}
                    setQuestion={setQuestion}
                    syllabusCode={task.project.syllabusCode}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </div>
  );
};

export default ApproverTask;
