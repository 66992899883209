// Core
import React from "react";

// Components
import App from "./App";

// Stylesheets
import "./assets/stylesheets/index.scss";

// Utils
import { ApiContext, ProjectContext, SkillContext } from "utils/context";

// Vendor
import ApplicationEnvProvider from "application.env/react";
import { enableAllPlugins } from "immer";
import ReactDOM from "react-dom";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ApplicationEnv {
    interface Env {
      API_DOMAIN: string;
      AUTH_CLIENT_ID: string;
      AUTH_DOMAIN: string;
    }
  }
}

enableAllPlugins();

ReactDOM.render(
  <React.StrictMode>
    <ApplicationEnvProvider
      path={
        !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? "/local.application.env"
          : "/application.env"
      }
    >
      <ApiContext>
        <ProjectContext>
          <SkillContext>
            <App />
          </SkillContext>
        </ProjectContext>
      </ApiContext>
    </ApplicationEnvProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
