// Core
import React, { FC, ReactElement } from "react";

// Components
import Editor from "components/editor/Editor";

// Interfaces
import { ChoiceInteractionResponse } from "interfaces";

// Vendor
import { colours } from "@cambridgeassessment/cambridge-ui";
import { Box, makeStyles, Typography } from "@material-ui/core";
import MathJax from "react-mathjax-preview";

interface Props {
  index: number;
  isApproving: boolean;
  isEditing: boolean;
  onChange: (value: string) => void;
  response: ChoiceInteractionResponse;
}

const SimpleChoice: FC<Props> = (props): ReactElement => {
  const useStyles = makeStyles({
    answerIdentifier: {
      fontWeight: "bold",
      marginRight: 10
    },
    container: {
      borderTop: `1px solid ${colours.monochromeLight}`,
      marginLeft: "-32px",
      marginRight: "-32px",
      padding: "20px 32px 20px 32px",
      "&:first-child": {
        borderTop: props.isApproving
          ? "0 none"
          : `1px solid ${colours.monochromeLight}`
      }
    },
    correctAnswer: {
      backgroundColor: colours.monochromeLightest
    }
  });
  const classes = useStyles();

  return (
    <Box
      className={`${classes.container} ${
        props.response.correct ? classes.correctAnswer : ""
      }`}
      data-testid="simple-choice"
    >
      <Box alignItems="center" display="flex">
        <Typography
          className={classes.answerIdentifier}
          data-testid="identifier"
        >
          {props.response.label}
        </Typography>
        {!props.isEditing && (
          <Typography data-testid={`response-${props.index}-content`}>
            <MathJax
              math={props.response.content}
              sanitizeOptions={{
                ADD_TAGS: ["b", "i", "sub", "sup", "u"]
              }}
              wrapperTag="span"
            />
          </Typography>
        )}
        {props.isEditing && (
          <Editor
            onEditorChange={props.onChange}
            testId={`response-${props.index}-editor`}
            value={props.response.content}
          />
        )}
        {!props.isEditing && props.response.correct && (
          <Box marginLeft="auto">
            <Typography
              style={{
                color: colours.greenDark,
                fontSize: "14px",
                fontWeight: 600,
                textDecoration: "underline"
              }}
            >
              Correct answer
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SimpleChoice;
