// Core
import React, { FC, ReactElement, useEffect, useState } from "react";

// Vendor
import { Button, colours } from "@cambridgeassessment/cambridge-ui";
import { Box, darken, Grid, Typography, withStyles } from "@material-ui/core";

interface Props {
  clickApprove: () => void;
  clickDisapprove: () => void;
  isYesDisabled: boolean;
  questionKey: string;
  setActiveApproval: React.Dispatch<
    React.SetStateAction<"" | "prompt" | "responses" | "stimulus">
  >;
}

const YesButton = withStyles(() => ({
  root: {
    backgroundColor: colours.greenDarkest,
    "&:hover": {
      backgroundColor: darken(colours.greenDarkest, 0.2)
    }
  }
}))(Button);

const InitialHeader: FC<Props> = (props): ReactElement => {
  const [activeStep, setActiveStep] = useState(1);
  const { setActiveApproval } = props;

  useEffect(() => {
    setActiveApproval(
      activeStep === 1 ? "stimulus" : activeStep === 2 ? "prompt" : "responses"
    );
  }, [activeStep, setActiveApproval]);

  useEffect(() => {
    setActiveStep(1);
  }, [props.questionKey]);

  const clickYes = (): void => {
    if (activeStep === 3) {
      props.clickApprove();
      setActiveApproval("");

      return;
    }

    setActiveStep(activeStep + 1);
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      width={1}
      data-testid="initial-header"
    >
      <Box data-testid={`step-${activeStep}`}>
        <Box marginBottom={1}>
          <Typography variant="h4" data-testid="instructions">
            ({activeStep}/4) Is the content in the{" "}
            <span style={{ textDecoration: "underline" }}>
              {activeStep === 1 && <>stimulus</>}
              {activeStep === 2 && <>prompt</>}
              {activeStep === 3 && <>responses</>}
            </span>{" "}
            correct?
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={11} md={7}>
            <Typography>
              The text and images should match the original. If they do not, you
              can change them using the ‘edit item’ button.{" "}
              {activeStep === 1 && <>Not all questions have a stimulus.</>}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" marginLeft="auto">
        <Box marginRight={2}>
          <Button
            color="primary"
            disableElevation
            onClick={props.clickDisapprove}
            variant="outlined"
            data-testid="no-button"
          >
            No
          </Button>
        </Box>
        <YesButton
          color="primary"
          disableElevation
          disabled={props.isYesDisabled}
          onClick={clickYes}
          data-testid="yes-button"
        >
          Yes
        </YesButton>
      </Box>
    </Box>
  );
};

export default InitialHeader;
